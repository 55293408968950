.tvuser {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
	overflow: hidden;
}

.tvuser__user-data,
.tvuser__operator-data,
.tvuser__consents-data {
	margin-bottom: 2em !important;
}

.tvuser__user-data .row,
.tvuser__operator-data .row {
	margin: 0 2em !important;
	padding: .4em 0 !important;
	font-size: 1.2em;
}

.tvuser__consents-data__content {
    margin: 0 2.5em;
}

.tvuser__user-data .row:not(:last-of-type),
.tvuser__operator-data .row:not(:last-of-type),
.tvuser__consents-data .row:not(:last-of-type) {
	border-bottom: .15em solid var(--background-body);
}

.tvuser__user-data .row > div:first-of-type,
.tvuser__operator-data .row > div:first-of-type {
	text-align: right;
	padding: .3em 1em !important;
}

.tvuser__user-data .row > div:last-of-type,
.tvuser__operator-data .row > div:last-of-type,
.tvuser__consents-data .row > div:last-of-type {
	text-align: left;
	padding: .3em 1em !important;
}

.tvuser h1 {
	height: 3.24em;
	background: var(--background-title-light);
	color: var(--background-text-region-dark);
	line-height: 3.24em;
	font-size: 1.2em;
	text-align: left;
	margin: 0;
	padding: 0 2em;
	position: relative;
}

.tvuser a {
	color: var(--background-title-light);
	text-decoration: none;
}

.tvuser a img {
	fill: var(--background-title-light);
}

.tvuser a:hover {
	color: var(--highlight);
}

.tvuser a:hover img {
	fill: var(--highlight);
}

.tvuser__user-data__email-buttons {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 14em;
	height: 3em;
	margin-top: -.5em;
}

.tvuser__user-data__email-buttons button,
.tvuser__operator-data__content__jpin-entry__inputs input[type="submit"],
.tvuser__security button {
	width: 6em;
	float: right;
	color: var(--text-generic);
	border: none;
	padding: 0;
	height: 100%;
	border-bottom: .3em solid var(--transparent-dark-20);
	text-transform: uppercase;
	line-height: 2.5em;
	font-size: 1.1em;
}

.tvuser__user-data__email-buttons button:not(:disabled):hover,
.tvuser__operator-data__content__jpin-entry__inputs input[type="submit"]:not(:disabled):hover,
.tvuser__security button:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.tvuser__user-data__email-buttons button:disabled,
.tvuser__operator-data__content__jpin-entry__inputs input[type="submit"]:disabled {
	background: var(--background-button-resolve-no-transparency);
	color: var(--text-disabled-no-transparency);
}

.tvuser__user-data input[type="text"] {
	margin: -.8em auto !important;
	color: var(--background-text-region-dark);
	border: none;
	height: 3em;
	padding: 0 1em;
	width: 100%;
	padding-right: 14em;
	position: absolute;
	left: 0;
	border-bottom: .3em solid var(--transparent-dark-20);
	box-shadow: none;
}

.tvuser__user-data input[type="text"].ng-invalid:not(:focus) {
	border-bottom-color: var(--border-input-invalid);
	background: var(--background-input-invalid);
}

.tvuser__operator-data__content__jpin-entry {
	position: absolute;
    top: 3.9em;
    left: 0;
    background: var(--background-text-region-dark);
    bottom: 0;
    right: 0;
    z-index: 40;
    padding: 4em;
}

.tvuser__operator-data__content__jpin-entry form {
	max-width: 40em;
	margin: 0 auto;
}

.tvuser__operator-data__content__jpin-entry__header {
	font-size: 1.4em !important;
	text-align: center;
	white-space: normal;
}

.tvuser .tvuser__operator-data__content__jpin-entry__header {
	margin: 0 !important;
}

.tvuser__operator-data__content__jpin-entry__inputs input[type="submit"] {
	display: inline-block;
	float: none;
	position: absolute;
	right: 0;
	top: .36em;
	bottom: .38em;
	width: 8em;
	height: auto;
}

.tvuser__operator-data__content__jpin-entry__inputs:nth-of-type(n) {
	position: relative;
	margin: .5em 0 !important;
}

.tvuser__operator-data__content__jpin-entry input[type="password"] {
	font-size: 1.4em;
	color: var(--background-text-region-dark);
	border: none;
	padding: .3em .6em;
	width: 100%;
	margin: 0 auto !important;
	display: inline-block;
	border-bottom: .25em solid var(--transparent-dark-20);
	text-align: left;
	padding-right: 10em;
}

.tvuser .limit-max-width {
	max-width: 72%;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tvuser .viewport-large {
	display: block;
}

.tvuser .viewport-small {
	display: none;
}

.tvuser .viewport-small img {
	width: 1.35em;
	height: 1.45em;
	margin-top: -.1em;
}

.tvuser__operator-data__content__jpin-entry__header,
.tvuser__operator-data__content__jpin-entry__inputs {
	border-bottom: none !important;
}

.tvuser__operator-data__content__jpin-entry__inputs__return {
	font-size: 1.1em;
	text-align: center !important;
}

.tvuser__security {
	border-top: .2em solid var(--background-body);
	margin: -1.7em 2.5em 0 !important;
}

.tvuser__security button {
	width: 20em;
	font-size: 1.2em;
	margin: 1em 0 1.2em;
}

@media (max-width: 991px) {
	.tvuser__user-data__email-buttons.open {
		position: relative;
		margin-right: -1em;
		margin-top: 1.45em;
		width: auto;
		top: .75em;
	}

	.tvuser__user-data__email-buttons.open button:not(:only-of-type) {
		width: 50%;
	}

	.tvuser__user-data__email-buttons.open button:only-of-type {
		width: 100%;
	}

	.tvuser_user-data__email-buttons__edit {
		width: 2.8em !important;
	}

	.tvuser__user-data input[type="text"] {
		width: 100%;
		max-width: 100%;
		padding-right: 1em;
	}

	.tvuser .viewport-large {
		display: none;
	}

	.tvuser .viewport-small {
		display: block;
	}

	.tvuser__user-data .row > div:nth-of-type(n),
	.tvuser__operator-data .row > div:nth-of-type(n) {
		padding-left: 0 !important;
	}

	.tvuser__operator-data__content__jpin-entry__inputs input[type="submit"] {
		width: 3em;
	}

	.tvuser__operator-data__content__jpin-entry input[type="password"] {
		padding-right: 4em;
	}

	.tvuser__security button {
		width: 100%;
	}

}

.consents__items {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 4.3em;
	overflow: hidden;
}

.consents__items__item {
	min-height: 3.7em;
	padding: .6em 0 !important;
	color: var(--text-generic);
	position: relative;
	font-size: 1.2em;
	text-align: center;
	width: 100%;
	cursor: default;
}

.consents__item__fake-select__container {
	position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}

.consents__item__fake-select span {
	position: relative;
	z-index: 150;
	width: 100%;
    height: 100%;
	border: none;
	padding: 0 3em 0 2em;
	box-shadow: none;
	display: flex;
    justify-content: center;
	align-items: center;
	/* potrzebne dla :hover żeby nie chodziło */
	border-bottom: .3em solid transparent;
	font-size: 1.1em;
}

.consents__item__fake-select span:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 .6em .6em .6em;
	border-color: transparent transparent var(--text-generic) transparent;
	transition: .3s transform;
	position: absolute;
	top: 50%;
	margin-top: -.25em;
	right: 3em;
	transform: scaleY(-1);
}

.consents__items__item.options-visible .consents__item__fake-select span:after {
	transform: scaleY(1);
}

.consents__item__fake-select {
	height: 100%;
	overflow: hidden;
	position: relative;
}

.consents__item:not(.disabled) .consents__item__fake-select:hover span,
.consents__items__item.options-visible .consents__item__fake-select span {
	background: var(--highlight) !important;
	color: var(--background-text-region-dark);
}

.consents__items__item.options-visible .consents__item__fake-select {
	overflow: visible;
	z-index: 200;
}

.consents__item__fake-select__options {
	position: relative;
	z-index: 50;
	background: var(--background-tooltip);
	color: var(--background-text-region-dark);
}

.consents__item__fake-select__options div {
	height: 3.2m;
	line-height: 3.2em;
	font-size: 1.1em;
}

.consents__item__fake-select__options div:hover {
	background: var(--highlight) !important;
	border-bottom: .1em solid transparent;
	cursor: pointer;

}

.consents__items__item input.ng-invalid:not(:focus) {
	border-bottom-color: var(--border-input-invalid);
	background: var(--background-input-invalid);
}

.consents__items__item input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.consents__items__item input[type=number] {
	-moz-appearance: textfield;
}

.consents__items__item__name,
.consents__items__item__description {
	text-overflow: ellipsis;
	padding: 0.3em 1.5em !important;
	text-align: left;
	white-space: normal;
	overflow: hidden;
	line-height: 1.3em;
	align-items: center;
	display: flex;
	align-content: center;
}

.consents__items__item__description {
	font-size: .9em;
	margin-top: .5em;
	padding: 0.3em 1.7em !important;
	opacity: .8;
	line-height: 1.3em;
}

.consents__items__item__slider {
	
	padding-right: 1em;
}

.consents__items__item__slider__enabled,
.consents__items__item__slider__disabled,
.consents__items__item__slider__unavailable {
	font-size: 1.1em;
	text-align: center;
	color: var(--text-generic);
}

.consents__items__item__slider__enabled {
	display: none;
}

.enabled .consents__items__item__slider__enabled {
	display: flex;
}

.enabled .consents__items__item__slider__disabled {
	display: none;
}

.consents__items__item__slider__graphics {
	width: 3.5em;
	height: 2.1em;
	transform: translateY(-50%);
	border-radius: 1.1em;
	border: .2em solid var(--background-reject);
}

.consents__items__item__slider__graphics.unavailable {
	border: .2em solid var(--border-neutral);
}

.consents__items__item__slider__graphics:after {
	display: block;
	content: '';
	position: absolute;
	top: 50%;
	left: .25em;
	width: 1.4em;
	height: 1.4em;
	margin-top: -.73em;
	border-radius: 1.5em;
	background: var(--background-reject);
	transition: .3s left;
}

.consents__items__item__slider__graphics.unavailable:after {
	background: transparent;
	border: 0.15em solid var(--border-neutral);
	top: 0.9em;
	left: 0.2em;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.consents__items__item__slider__graphics:after {
		top: 52%;
		left: .2em;
	}
}

.enabled .consents__items__item__slider__graphics {
	border-color: var(--background-resolve);
}

.enabled .consents__items__item__slider__graphics:after {
	left: 1.6em;
	background: var(--background-resolve)
}

.consents__items__item:not(.no-hover-effect):not(.disabled),
.consents__items__item:not(.disabled) .consents__item__fake-select span {
	cursor: pointer;
}

@media (max-width: 767px) {


	.consents__item__fake-select span {
		padding: 0 2.5em 0 1em;
		line-height: 130%;
	}

	.consents__item__fake-select span:after {
		right: 1.2em;
	}

	.consents__items__item__name {
		padding: 0 .9em !important;
	}

	.consents__items__item__description {
		padding: 0.3em 1em !important;
	}
}

.flex-slider {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}
