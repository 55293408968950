body {
	background: var(--background-body);
	font-family: "Open Sans";
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	image-rendering: crisp-edges;
	-webkit-font-smoothing: subpixel-antialiased;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
@media only screen and (-moz-min-device-pixel-ratio: 2),
@media only screen and (-o-min-device-pixel-ratio: 2),
@media only screen and (min-device-pixel-ratio: 2),
@media only screen and (min-resolution: 2dppx) {
	body {
		font-size: 1.15em;
	}
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
@media only screen and (-moz-min-device-pixel-ratio: 3),
@media only screen and (-o-min-device-pixel-ratio: 3),
@media only screen and (min-device-pixel-ratio: 3),
@media only screen and (min-resolution: 3dppx) {
	body {
		font-size: 1.1em;
	}
}

html {
	overflow: hidden;
}

strong {
	font-weight: 600;
}

a {
	outline: none;
}

.container-fluid {
	margin: 0 !important;
	padding: 0 !important;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
}

*[class^="col-"], .row {
	margin: 0 !important;
	padding: 0 !important;
}

*::-moz-focus-inner {
	border: none !important;
}

button {
	outline: none !important;
	padding: 0 !important;
}

.column__nav {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	background: var(--background-body);
	width: 22em;
}

.column__content {
	position: absolute;
	left: 22em;
	top: 8em;
	bottom: 0;
	overflow: hidden;
	width: auto !important;
	right: 0;
}

@media (max-width: 991px) {
	.column__nav {
		display: none;
		z-index: 300;
		width: 100%;
		overflow-x: hidden;
	}

	.column__nav.mobile-visible {
		display: block !important;
	}

	.column__content {
		left: 0;
	}
}

.yes {
	background: var(--background-resolve);
}

.no {
	background: var(--background-reject);
}

.flex-container-half {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
}

.flex-container-half * {
	text-overflow: ellipsis;
	overflow: hidden;
	order: 0;
	flex: 1 1 100%;
	align-self: stretch;
}

.flex-container-half *:nth-child(1):not(:only-of-type) {
	margin-right: .15em;
}

.flex-container-half *:nth-child(2) {
	margin-left: .15em;
}

img[src$="no-logo.svg"] {
	width: 3.8em !important;
}

.chart {
	position: relative;
}

.chartist-tooltip {
	position: absolute;
	background: var(--background-tooltip-recording);
	padding: .1em .4em;
	color: var(--background-logo);
	text-shadow: -.05em -.05em 0 var(--shadow-tooltip);
	box-shadow: none;
	font-size: 1.1em;
	white-space: nowrap;
	opacity: 0;
	transition: opacity 0.2s;
	z-index: 1000;
	font-weight: normal;
	min-width: 0;
}

.chartist-tooltip:before {
	display: none;
}

.chartist-tooltip:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 99%;
	border-top: .15em solid transparent;
	border-bottom: .15em solid transparent;
	border-left: .25em solid var(--shadow-tooltip);
	width: auto;
	height: auto;
}

.chartist-tooltip.tooltip-show {
	opacity: 1;
}

.ct-grid {
	fill: none;
}

.disable-selection {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

*[disabled] {
	pointer-events: none;
}

.jambox-chevron {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: .7rem;
	background: var(--background-text-region-dark);
}

.jambox-chevron:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0;
	height: 100%;
	border-style: solid;
	border-width: .7rem 0 .7rem .7rem;
	border-color: transparent transparent transparent var(--background-various-light);
}

.jambox-chevron.jc-left {
	right: auto;
	left: 0;
}

.jambox-chevron.jc-transparent {
	background: transparent;
}

.jambox-chevron.jc-outer {
	right: -.7rem;
}

.jambox-chevron.jc-left.jc-outer {
	right: auto;
	left: -.7rem;
}

.jambox-chevron.jc-left:after {
	border-width: .7rem .7rem .7rem 0;
	border-color: transparent var(--background-various-light) transparent transparent;
}

.ss-wrapper,
.ss-scroll,
.ss-content {
	z-index: unset;
}

.ss-scroll {
	background: var(--text-generic);
	border-radius: 5em 0 0 5em;
	opacity: .4 !important;
	width: 1em;
	z-index: 100;
}

.ss-scroll:hover,
.ss-scroll.ss-grabbed {
	opacity: 1 !important;
}

.ss-content {
	overflow-y: scroll;
}