.search {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
}

.search input:disabled,
.search button:disabled {
	cursor: not-allowed;
}

.recording__list {
	padding: 0;
}

.search__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
}

.search__header div {
	cursor: pointer;
}

.search__header div.selected {
	font-weight: bold;
}

.search__header div:not(.jambox-chevron):hover {
	background: var(--highlight);
}

.search__header div:hover .jambox-chevron:after {
	border-color: transparent transparent transparent var(--highligh-border);
}

/* ------------------------------------------------- */

.search__text,
.search__category {
	height: 100%;
}

.search__text__input form {
	padding: 2em;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background: var(--background-category-list);
	border-bottom: .25em solid var(--border-nav-item);
	display: flex;
}

.search__text__input form input[type="text"],
.search__text__input__action,
.search__result__category__header {
	flex-direction: row;
	height: 3.24em;
	padding: 0 1em;
	font-size: 1.2em;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	outline: none;
}

.search__text__input__action {
	flex: 0 0 8em;
	text-transform: uppercase;
	position: relative;
}

.search__text__input__action__icon {
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: url(../images/search.svg) no-repeat center;
	background-size: 60% 60%;
}

.search__text__input__action:not(:disabled):hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.search__text__input__action span {
	font-size: 1.25em;
}

.search__text__input form input[type="text"] {
	flex: 1 1 auto;
	min-width: 0;
	color: var(--background-text-region-dark);
	box-shadow: none;
}

/* ------------------------------------------------- */

.search__result {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
}

.search__result__text  {
	top: 8.45em;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { /* chrome hack */
	.search__result__text {
		top: 8.15em;
	}
}

/* ------------------------------------------------- */

.search__text__inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	margin: 0 !important;
	padding: 0 !important;
}

.search__result__placeholder {
	margin: 5em auto 2em;
	font-size: 1.3em;
	text-align: center;
	z-index: 5;
	color: var(--text-generic);
	max-width: 40em;
	padding: 0 4em;
}

.search__result__placeholder button {
	display: block;
	border: none;
	color: var(--text-generic);
	background: var(--background-various-light);
	text-transform: uppercase;
	padding: .6em 1.3em !important;
	margin: 1.5em auto 1em;
	text-align: center;
	border-bottom: .3em solid var(--transparent-dark-20);
}

.search__result__placeholder button:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.search__text__suggestions {
	margin: 0;
	padding: 0;
	position: absolute;
	right: 2em;
	left: 2em;
	top: 5.9em;
	list-style: none;
	background: var(--background-tooltip);
	z-index: 40;
	max-height: 20em;
	overflow-y: scroll;
	color: var(--background-text-region-dark);
}

.search__text__suggestions li {
	width: 100%;
	display: block;
	color: var(--background-text-region-dark);
	position: relative;
	height: 3.15em;
	overflow: hidden;
	font-size: 1.2em;
	padding: .85em .9em;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	margin-top: -.15em;
}

.search__text__suggestions li:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.search__text__suggestions li:not(:last-of-type):after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-bottom: .14em solid var(--border-list-item);
}

.search__text__suggestions li:not(:last-of-type):hover:after {
	border-bottom-color: var(--highlight);
}

.search__result__results ul,
.search__result__results li {
	list-style: none;
	padding: 0;
	margin: 0;
}

.search__result__results li {
	padding: 1.3em 2em 1.6em;
	font-size: 1.1em;
	position: relative;
	color: var(--text-generic);
	cursor: pointer;
	overflow: hidden;
}

.search__result__results li:not(:first-of-type) {
	margin-top: -.15em;
}

.search__result__results li:hover:before {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: .3em solid var(--highlight);
	content: '';
	display: block;
	border-radius: .15em;
	z-index: 20;
}

.search__result__results li.is-more-vod {
	background: var(--background-various-light);
	color: var(--text-generic);
	font-size: 1.3em;
	text-transform: uppercase;
	padding: .7em 1.5em;
	margin: -.15em 0 0;
	text-align: center;
	border-bottom: .3em solid var(--transparent-dark-20);
}

.search__result__results li.is-more-vod:hover {
	background: var(--highlight);
	color: var(--text-neutral);
}

.search__result__results li.is-more-vod:before {
	display: none;
}

.search__result__results li.is-past:not(:hover) > div {
	opacity: .5;
}

.search__result__results li.is-past:after {
	content: 'Zakończony';
	position: absolute;
	bottom: 5.7em;
	right: -1.4em;
	width: 10em;
	height: 2em;
	line-height: 1.8em;
	background: var(--background-reject);
	transform: rotate(-45deg);
	text-align: center;
	transform-origin: bottom right;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { /* chrome hack */
	.search__result__results li.is-past:after {
		line-height: 1.8em;
	}
}

.search__result__results li:not(:hover):before {
	content: '';
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	background: var(--border-nav-item);
	height: .19em;
}

.search__result__title {
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 1.35em;
	white-space: nowrap;
	display: flex;
}

.search__result__title__can-record,
.search__result__title__can-play,
.search__result__title__age-rating,
.search__result__title__is-vod {
	flex: 0 0 auto !important;
	width: 1em;
	height: 1em;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 1em;
	margin-right: .4em;
	align-self: center;
}

.search__result__title__can-record {
	background-color: var(--background-reject);
	background-image: url('../images/feature-record.svg');
}

.search__result__title__can-play {
	background-color: var(--background-ott);
	background-image: url('../images/feature-ott.svg');
}

.search__result__title__age-rating {
	border-radius: 0;
	display: inline-block;
	position: relative;
	margin: 0 .2em;
	top: .1em;
}

.search__result__title__is-vod {
	font-size: .7em;
	padding: .08em .3em .12em;
	background: var(--text-generic);
	color: var(--text-neutral);
	border-radius: .1em;
	font-weight: bold;
	width: auto;
	height: auto;
}

.search__result__title__header .search__result__title__age-rating {
	margin-right: -.1em;
}

.search__result__title__age-rating.age-rating-0 {
	display: none;
}

.search__result__title__age-rating.age-rating-1 {
	background-image: url('../images/rating/1.svg');
}

.search__result__title__age-rating.age-rating-7 {
	background-image: url('../images/rating/7.svg');
}

.search__result__title__age-rating.age-rating-12 {
	background-image: url('../images/rating/12.svg');
}

.search__result__title__age-rating.age-rating-16 {
	background-image: url('../images/rating/16.svg');
}

.search__result__title__age-rating.age-rating-18 {
	background-image: url('../images/rating/18.svg');
}

.search__result__title div {
	flex: 1 1 auto;
}

.search__result__title__header {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.search__result__title__header:not(:only-of-type) {
	padding-right: 2.5em;
}

.search__result__title__duration {
	font-size: .8em;
	padding: .1em .4em;
	display: inline-block;
	opacity: .6;
	position: relative;
	top: -.1em;
}

.search__result__image {
	display: flex;
	margin: .3em 0;
}

.search__result__image__image {
	width: 80px; /* fuj fuj fuj */
	height: 53px; /* fuj fuj fuj */
	background: var(--background-logo);
	display: flex;
}

.search__result__image img {
	transform: scale(.9, .9);
	margin: 0 auto;
}

.search__result__image__vod img {
	transform: scale(1.4, 1.4);
	margin: 0 auto;
	max-width: 100%;
	max-height: 100%;
	image-rendering: auto;
}

.search__result__image__date-line {
	max-width: calc(100% - 80px);
	white-space: nowrap;
	overflow: hidden;
	padding-right: .5em;
}

.search__result__image__production-line,
.search__result__image__date-line__box {
	margin-left: .6em;
}

.search__result__image__date-line__box {
	display: inline-block;
	background: var(--background-title-light);
	color: var(--background-text-region-dark);
	font-size: 1.1em;
	padding: .05em .3em;
	position: relative;
}

.search__result__image__date-line__box .jambox-chevron:after {
	border-width: .6rem 0 .6rem .5rem;
}
.search__result__image__date-line__box .jambox-chevron {
	width: .5rem;
	right: -.5rem;
}

.search__result__image__date-line__box.today .jambox-chevron:after {
	border-color: transparent transparent transparent var(--background-today);
}

.search__result__image__date-line__box.tomorrow .jambox-chevron:after {
	border-color: transparent transparent transparent var(--highligh-border);
}

.search__result__image__date-line__box.running .jambox-chevron:after {
	border-color: transparent transparent transparent var(--border-input-invalid);
}

.search__result__image__date-line__box.special-vod .jambox-chevron:after {
	border-color: transparent transparent transparent var(--shadow-search-flag-vod);
}

.search__result__results li.is-past .search__result__image__date-line__box .jambox-chevron:after {
	border-color: transparent transparent transparent var(--transparent-light-30);
}

.search__result__image__date-line__box.today {
	background: var(--background-resolve);
}

.search__result__image__date-line__box.tomorrow {
	background: var(--highlight);
}

.search__result__image__date-line__box.running {
	background: var(--background-reject);
	color: var(--text-generic);
}

.search__result__image__date-line__box.special-vod {
	background: var(--border-light);
}

.search__result__results li.is-past .search__result__image__date-line__box {
	background: rvar(--transparent-light-60);
}

.search__result__image__production-line {
	opacity: .85;
	padding: .3em .1em 0;
}

.search__result__description {
	opacity: .85;
}

.search__result__title__awards {
	text-align: left;
	background: url(../images/star.svg) no-repeat left;
	background-size: 1em;
	background-position: left 40%;
	flex: 0 0 auto !important;
	padding-left: 1.1em;
	color: var(--highlight);
}

/* ------------------------------------------------- */

.search .epg__details__pane {
	position: absolute;
	top: 0;
}

.search .epg__details__pane__close {
	right: 0;
	margin: 0;
	bottom: 0;
}

.search .epg__details__pane__close button {
	margin-bottom: 0;
}

/* ------------------------------------------------- */

.search__category__categories {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	padding: 0;
	width: 17em;
	overflow: hidden;
}

.search__category__categories ul,
.search__category__categories li {
	padding: 0;
	margin: 0;
	list-style: none;
}

.search__category__categories ul {
	position: absolute;
	top: 4em;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	background: var(--background-category-list);
}

.search__category__categories li {
	font-size: 1.15em;
	padding: .8em 2em .85em 2em;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.search__category__categories li:not(:first-of-type) {
	margin-top: -.15em;
}

.search__category__categories li:not(:last-of-type):after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-bottom: .14em solid var(--border-nav-item);
}

.search__category__categories li:hover {
	background: var(--background-category-hover);
}

.search__category__categories li:not(:last-of-type):hover:after {
	border-bottom-color: var(--background-category-hover);
}

.search__category__categories li.selected {
	color: var(--background-category-selected);
}

.search__category__categories li.selected:before {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	top: 50%;
	margin-top: -.7em;
	right: .8em;
	border-style: solid;
	border-width: .7em 0 .7em .7em;
	border-color: transparent transparent transparent var(--highlight);
}

.search__category__categories span {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.search__category__categories__filter {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 4em;
}

.search__category__categories__filter input {
	padding: 1em;
	height: 100%;
	width: 100%;
	color: var(--background-text-region-dark);
	font-size: 1.2em;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	padding-right: 4em;
}

.search__category__categories__filter button {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 4em;
	border: none;
	outline: none;
}

.search__category__categories__filter__button-reset {
	right: 0;
	background: transparent url('../images/cross-black.svg') no-repeat 50% 45%;
	background-size: 40%;
}

.search__category__categories__filter__button-back {
	left: 0;
	background: transparent url('../images/left-arrow.svg') no-repeat 50% 44%;
	background-size: 48%;
	display: none;
}

.search__category__categories__filter button:disabled {
	display: none;
}

.search__result__category .search__text__inner {
	left: 17em;
}

.search__result__category__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 1.2em;
	height: 3.34em;
	line-height: 3.14em;
	display: none;
	background: var(--background-logo);
	color: var(--background-text-region-dark);
	cursor: pointer;
}

.search .epg__details__pane__rating {
	font-size: 1.2em;
	padding-bottom: 1em;
}

.search .epg__details__pane__rating .search__result__title__age-rating {
	width: 1.4em;
	height: 1.4em;
	margin: 0 .3em -.2em 0;
}

.search .epg__details__pane__synopsis__inner p:empty {
	display: none;
}

/* ------------------------------------------------- */


.search__text__vod-epg-switch {
	flex: 0 0 7.2em;
	text-align: center;
	background: var(--background-logo);
	font-size: 1.2em;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	color: var(--text-neutral);
	display: flex;
	justify-content: center;
	align-items: center;
}

.search__text__vod-epg-switch div {
	padding: .1em .4em;
	background: var(--border-light);
	flex: 0 0 auto;
	position: relative;
}

@-moz-document url-prefix() {
	.search__text__vod-epg-switch div {
		float: left;
		width: 3.1em;
	}
}

.search__text__vod-epg-switch__epg {
	border-radius: .15em 0 0 .15em;
}

.search__text__vod-epg-switch__vod {
	border-radius: 0 .15em .15em 0;
}

.search__text__vod-epg-switch.selected-epg .search__text__vod-epg-switch__epg {
	background: var(--text-neutral);
	color: var(--text-generic);
	padding-right: .7em;
}

.search__text__vod-epg-switch.selected-vod .search__text__vod-epg-switch__vod {
	background: var(--text-neutral);
	color: var(--text-generic);
	padding-left: .7em;
}

.search__text__vod-epg-switch.selected-epg .search__text__vod-epg-switch__epg:after,
.search__text__vod-epg-switch.selected-vod .search__text__vod-epg-switch__vod:after {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	position: absolute;
	top: 0;
}

.search__text__vod-epg-switch.selected-epg .search__text__vod-epg-switch__epg:after {
	border-width: 0 0 1.6em .4em;
	border-color: transparent transparent var(--border-light) transparent;
	right: 0;
}

.search__text__vod-epg-switch.selected-vod .search__text__vod-epg-switch__vod:after {
	border-width: 1.6em .4em 0 0;
	border-color: var(--border-light) transparent transparent transparent;
	left: 0;
}

.search__text__inner .ss-scroll {
	z-index: 15;
}

.search__content__image {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	width: 172px;
	height: 270px;
}

.search__content__description {
	max-width: 415px;
	margin-left: 25px;
}

.search__content__wrapper {
	display: flex;
	justify-content: flex-start;
	overflow: hidden;
}

@-moz-document url-prefix() {
	.search__text__vod-epg-switch.selected-epg .search__text__vod-epg-switch__epg:after {
		border-width: 0 0 1.65em .4em;
	}

	.search__text__vod-epg-switch.selected-vod .search__text__vod-epg-switch__vod:after {
		border-width: 1.65em .4em 0 0;
	}
}

/* ------------------------------------------------- */

@media (max-width: 1400px) {
	.search .epg__details__pane.search__col-md-override {
		width: auto;
		left: 17em;
	}

	.search__content__image {
		width: 320px;
		height: 530px;
	}
	
	.search__content__description {
		width: 320px;
		margin-top: 20px;
		margin-left: 0;
	}
	
	.search__content__wrapper {
		flex-direction: column;
		align-items: center;
	}
}

@media (max-width: 1199px) {
	.search.side-pane-open .search__text__input__action {
		flex: 0 0 3.3em;
	}

	.search.side-pane-open .search__text__input__action__icon {
		display: block;
	}

	.search.side-pane-open .search__text__input__action__text {
		display: none;
	}
}

@media (max-width: 1199px) and (min-width: 991px) {
	.search.side-pane-open .search__text__vod-epg-switch.search-focused {
		flex-basis: 3.6em;
	}

	.search.side-pane-open .search__text__vod-epg-switch.search-focused.selected-vod .search__text__vod-epg-switch__epg,
	.search.side-pane-open .search__text__vod-epg-switch.search-focused.selected-epg .search__text__vod-epg-switch__vod,
	.search.side-pane-open .search__text__vod-epg-switch.search-focused div:after {
		display: none;
	}

	.search.side-pane-open .search__text__vod-epg-switch.search-focused div {
		border-radius: .15em;
		padding: .1em .4em !important;
	}
}

@media (max-width: 991px) {
	.search__text__input__action {
		flex: 0 0 3.3em;
	}

	.search__text__input__action__icon,
	.search__result__category__header,
	.search__category__categories__filter__button-back {
		display: block;
	}

	.search__text__input__action__text,
	.search__category__categories {
		display: none;
	}

	.search__result__category  {
		top: 4em;
	}

	.search__result__category .search__text__inner,
	.search .epg__details__pane.search__col-md-override {
		left: 0;
	}

	.search__category__categories.search__category__mobile__visible {
		display: block;
		width: 100%;
	}

	.search__category__categories li,
	.search__category__categories__filter input {
		text-align: center;
	}

	.search__category__categories__filter input {
		/* dodajemy padding żeby było wycentrowane, po prawej jest button reset */
		padding-left: 4em;
	}

	.search__category__categories li.selected {
		background: var(--highlight);
		color: var(--background-text-region-dark);
	}

	.search__category__categories li.selected:after {
		border-bottom-color: var(--highlight);
	}
}