.nav a {
	outline: none;
}

.nav__branding {
	position: absolute;
	height: 8em;
	width: 100%;
	color: var(--text-generic);
	padding: 1.4em 2em !important;
	right: 0;
	background: var(--background-button-generic-focused);
	cursor: pointer;
	z-index: 200;
}

.nav__branding img {
	width: 75%;
	margin: 1.45em 2em 0;
}

.nav__branding span {
	display: block;
	font-size: 1.2em;
	color: var(--text-intro);
	padding: 0 1.7em;
}

.nav__user {
	background: var(--background-button-generic);
	position: absolute;
	height: 8em;
	width: 100%;
	color: var(--text-generic);
	z-index: 50;
	cursor: pointer;
	width: 25em;
	right: 0;
	top: 0;
}

.nav__user:after {
	content: '';
	position: absolute;
	top: 50%;
	right: 2.5em;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: .7em .7em 0 .7em;
	border-color: var(--background-title-light) transparent transparent transparent;
	margin-top: -.5em;
	transition: .3s transform;
}

.nav__user.menu-open:after {
	transform: scaleY(-1);
}

.nav__user > div {
	padding: 1.3em 2em 1.6em !important;
	position: absolute;
	right: 0;
	width: 25em;
}

.nav__user > div:hover,
.nav__user.menu-open > div {
	background: var(--background-button-generic-focused);
}

.nav__user .row {
	font-size: 1em;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	color: var(--background-title-light);
}

.nav__user__row-may-overflow {
	max-width: 17em;
}

.nav__user .row:first-of-type {
	font-size: 1.3em;
	color: var(--text-generic);
	padding-bottom: .3em !important;
}

.nav__container {
	position: absolute;
	top: 0;
	right: -1.3em;
	bottom: 0;
	left: 0;
	overflow-y: scroll;
	overflow-x: hidden;
}

.nav__container::-webkit-scrollbar {
	display: none;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.nav__container {
		right: -1em;
	}
}

@-moz-document url-prefix() {
	@supports (animation: calc(0s)) {
		.nav__container {
			right: -1.2em;
		}
	}
}

.nav__container li {
	list-style: none;
}

.nav__tiles,
.nav_tiles_background {
	position: absolute;
	top: 8em;
	left: 0;
	background: var(--background-text-region-dark);
}

.nav_tiles_background {
	bottom: 0;
	width: 5em;
	position: fixed;
}

.nav__tiles,
.nav__tiles li {
	z-index: 20;
	width: 5em;
}
.nav__version {
	background: var(--background-text-region-dark);
	color: var(--text-disabled-no-transparency);
	width: inherit;
	text-align: center;
	position: fixed;
	font-size: .75em;
	bottom: 0;
	margin: 20px 0 0 0;
	z-index: 10;
}

.nav__tiles span {
	position: absolute;
	top: 0;
	left: 4em;
	font-size: 1.3em;
	width: 13.1em;
	display: block;
	padding: 1.2em 1em;
	z-index: 40;
	margin-left: -.2em; /* wtf */
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.nav__tiles span {
		padding: 1.24em 1em;
	}
}

@-moz-document url-prefix() {
	@supports (animation: calc(0s)) {
		.nav__tiles span {
			padding: 1.2em 1em;
		}
	}
}

.nav__tiles a {
	width: 5em;
	height: 5em;
	display: block;
	position: relative;
	cursor: pointer;
}

.nav__tiles li.disabled {
	opacity: 0.2;
}

.nav__tiles a img {
	position: absolute;
	top: 1.25em;
	left: 1.25em;
	width: 2.5em;
	height: 2.5em;
	line-height: 1.6em;
	text-align: center;
}

.nav__tiles a.selected {
	background: var(--background-body);
	-webkit-box-shadow: inset .4em 0 0 0 var(--highlight);
	-moz-box-shadow: inset .4em 0 0 0 var(--highlight);
	box-shadow: inset .4em 0 0 0 var(--highlight);
}

.nav__menu {
	position: absolute;
	top: 8em;
	left: 5em;
	right: 0;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.nav__menu {
		right: 1em;
	}
}

@-moz-document url-prefix() {
	@supports (animation: calc(0s)) {
		.nav__menu {
			right: 0;
		}
	}
}

.nav__menu ul {
	position: relative;
}

.nav__menu li {
	position: relative;
}

@-moz-document url-prefix() {
	@supports (animation: calc(0s)) {
		.nav__menu li {
			line-height: 1.6em;
		}
	}
}

.nav__menu li:not(:first-of-type) {
	margin-top: -.15em;
}

.nav__menu a {
	font-size: 1.2em;
	padding: .9em 1em;
	display: block;
	cursor: pointer;
}

.nav__menu a:hover,
.nav__user-menu a:hover {
	text-decoration: none;
}

.nav__menu li:not(.selected):after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-bottom: .15em solid var(--border-nav-item);
}

.nav__menu li:last-of-type:after {
	bottom: -.15em;
}

.nav__menu li:first-of-type:before {
	content: '';
	display: block;
	position: absolute;
	top: -.15em;
	left: -4.6em;
	right: 0;
	border-bottom: .15em solid var(--border-nav-item);
}

.nav__menu li:not(.selected):hover,
.nav__tiles a:hover,
.nav__tiles li:hover span {
	background: var(--border-nav-item);
}

.nav__menu li.disabled a {
	opacity: .2;
}

.nav__menu li.disabled:hover,
.nav__menu li.disabled:hover a,
.nav__tiles li.disabled:hover a,
.nav__tiles li.disabled:hover span {
	background: transparent;
	cursor: not-allowed;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.nav__menu a {
		padding-top: .96em;
	}
}

@-moz-document url-prefix() {
	@supports (animation: calc(0s)) {
		.nav__menu a {
			padding-top: 1em;
		}
	}
}

.nav__menu li.selected a {
	position: relative;
	color: var(--background-text-region-dark);
	text-decoration: none;
	background: url('../images/chevron-bar.svg') no-repeat right;
	background-size: auto 3.25em;
	z-index: 90;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.nav__menu li.selected a {
		background-size: auto 3.276em;
	}
}

@-moz-document url-prefix() {
	@supports (animation: calc(0s)) {
		.nav__menu li.selected a {
			background-size: auto 3.25em;
		}
	}
}

.nav__menu li.selected a {
	padding: .75em 1em 1.05em;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.nav__menu li.selected a {
		padding: .75em 1em 1.11em;
	}
}

.nav__tiles a,
.nav__menu a {
	color: var(--text-generic);
}

.nav__user-menu {
	position: absolute;
	right: 0;
	color: var(--text-generic);
	white-space: nowrap;
	width: 25em;
	top: 8em;
	background: var(--background-tooltip);
	z-index: 300;
	list-style: none;
}

.nav__user-menu img {
	width: 1.3em;
	height: 1.3em;
	position: absolute;
	top: 50%;
	left: 1.1em;
	transform: translateY(-50%);
	opacity: .5;
}

.nav__user-menu a {
	width: 100%;
	display: block;
	position: relative;
	color: var(--background-text-region-dark);
	height: 3.3em;
	overflow: hidden;
	font-size: 1.2em;
	padding: .9em;
}

.nav__user-menu span {
	margin-left: 2.5em;
}

.nav__user-menu li {
	position: relative;
}

.nav__user-menu li:not(:first-of-type) {
	margin-top: -.15em !important;
}

.nav__user-menu li:not(:hover):after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-bottom: .15em solid var(--border-list-item);
}

.nav__user-menu a:hover {
	background: var(--highlight);
}

.nav__user-menu a:hover img {
	opacity: 1;
}

.nav__menu__header {
	height: 5em;
}

.nav__burger {
	background: url('../images/menu.svg') no-repeat;
	background-size: contain;
	width: 6.4em;
	height: 6.4em;
	position: absolute;
	top: .8em;
	left: .7em;
	z-index: 500;
	cursor: pointer;
	border: 2em solid transparent;
	display: none;
}

.nav .auth-required a:after {
	content: '';
	display: inline-block;
	background: url(../images/login.svg) no-repeat;
	background-size: 1em 1em;
	width: 1em;
	height: 1em;
	opacity: .5;
	margin-left: .5em;
	position: relative;
	top: .1em;
}

@media (max-width: 991px) {
	.nav__burger {
		display: block;
	}

	.nav__branding {
		padding-left: 6.2em !important;
		padding-right: 0 !important;
	}

	.nav__branding img {
		width: 14em;
	}

	.nav__user {
		display: none;
	}

	.nav__tiles span,
	.nav__container {
		width: 1000em;
	}

	/* hack na hack */
	.nav__menu li.selected a {
		background-position: -1em 0;
	}

	@media screen and (-webkit-min-device-pixel-ratio:0) {
		.nav__menu li.selected a {
			background-size: auto 3.2em;
		}
	}
}