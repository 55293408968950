.welcome {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
}

.welcome__items {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-wrap: wrap;
	overflow-y: scroll;
	overflow-x: hidden;
}

.welcome__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	margin: 0;
	color: var(--background-text-region-dark);
}

.welcome__item {
	position: relative;
	background-position: center;
	background-size: cover;
	image-rendering: auto;
	width: 683px; /* fuj fuj fuj */
	height: 350px; /* fuj fuj fuj */
	color: var(--text-generic) !important;
	text-decoration: none;
}

.welcome__item__description,
.welcome__item__description span {
	transition: .4s background;
}

.welcome__item__description {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--transparent-mid-80);
	padding: 1em;
}

.welcome__item__description h1 {
	font-size: 1.5em;
	padding: 0;
	margin: 0 0 .4em;
}

.welcome__item__description h2 {
	font-size: 1.1em;
	padding: 0;
	margin: 0;
	line-height: 1.3em;
}

.welcome__item__description span {
	position: absolute;
	top: 0;
	right: -.05em; /* firefox bug */
	background: var(--transparent-mid-80);
	font-size: 1.2em;
	padding: .3em .5em;
	transform: translateY(-100%);
}

.welcome__item:hover .welcome__item__description,
.welcome__item:hover .welcome__item__description span {
	background: var(--background-text-region-dark);
	transition: .2s background;
}

.welcome__item:hover:after {
	position: absolute;
	top: -.05em;
	left: -.05em;
	right: -.05em;
	bottom: -.05em;
	border: .3em solid var(--highlight);
	content: '';
	display: block;
	border-radius: .15em;
	z-index: 100;
}

@media (max-width: 3415px) {
	.welcome__item {
		flex: 0 0 20%;
	}
}

@media (max-width: 2732px) {
	.welcome__item {
		flex: 0 0 25%;
	}
}

@media (max-width: 2049px) {
	.welcome__item {
		flex: 0 0 33.333333333%;;
	}
}

@media (max-width: 1366px) {
	.welcome__item {
		flex: 0 0 50%;
	}
}

@media (max-width: 683px) {
	.welcome__item {
		flex: 0 0 100%;
	}
}