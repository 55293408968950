.sms-verification {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--background-text-region-dark);
    color: var(--text-generic);
    z-index: 20;
}

.sms-verification__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 20em;
    background: var(--background-text-region-dark);
    padding: 2em;
    border: .3em solid var(--highlight);
    border-radius: .3em;
    text-align: center;
}

.sms-verification__header {
    font-size: 1.4em;
    color: var(--text-generic);
    margin-bottom: 1em;
}

.sms-verification__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.sms-verification__input-wrapper input[type="password"] {
    font-size: 1.4em;
    color: var(--background-text-region-dark);
    border: none;
    padding: .6em 1em;
    flex-grow: 1;
    text-align: center;
    border-bottom: .3em solid var(--transparent-dark-20);
}

.sms-verification__input-wrapper button {
    font-size: 1.2em;
    padding: .6em 1em;
    border: none;
    background: var(--highlight);
    color: var(--background-text-region-dark);
    cursor: pointer;
}

.sms-verification__input-wrapper button:disabled {
    opacity: .5;
    cursor: not-allowed;
}

.sms-verification__actions {
    margin-top: 1.5em;
    font-size: 1em;
}

.sms-verification__actions a {
    color: var(--highlight);
    cursor: pointer;
    text-decoration: none;
}

.sms-verification__actions a:hover {
    text-decoration: underline;
}
