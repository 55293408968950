.message {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
}

.message__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.23em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.23em;
	text-align: center;
	margin: 0;
	color: var(--background-text-region-dark);
}

.message__list {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0;
}

.message__list__inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	padding: 0;
}

.message__row__meta {
	height: 5em;
	line-height: 2.8em;
	border-bottom: .15em solid var(--border-neutral);
	margin-left: 2em;
	font-size: 1.2em;
	position: relative;
	cursor: pointer;
}

.message__row__meta > div {
	padding: 1em !important;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.message__row__meta__date {
	text-align: center;
}

.message__row__meta__title span {
	padding-left: 4em;
}

.message__row__meta__title__spot {
	position: absolute;
	top: 50%;
	left: 1em;
	transform: translateY(-50%);
	width: 3em;
	height: 3em;
	border-radius: 3em;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 60%;
}

.message__row__single {
	background: var(--background-body);
	position: absolute;
	top: 3.84em;
	right: 0;
	bottom: 0;
	border-left: .2em solid var(--border-neutral);
	padding: 1em 2em !important;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	z-index: 150;
}

.message__row__single__meta {
	flex: 0 1 auto;
}

.message__row__single h1 {
	position: relative;
	font-size: 2em;
	z-index: 120;
	margin-top: .35em;
}

.message__row__single h2 {
	position: relative;
	font-size: 1.5em;
	border-bottom: .15em solid var(--border-neutral);
	margin: 0 -1.5em 1em;
	padding: 0 1.5em 1em;
	z-index: 120;
}

.message__row__contents {
	overflow-y: scroll;
	overflow-x: hidden;
	flex: 1 1 auto;
	margin-bottom: 5em;
}

.message__row__meta:hover:after {
	position: absolute;
	top: -.2em;
	left: 0;
	right: 0;
	bottom: -.2em;
	border: .3em solid var(--highlight);
	content: '';
	display: block;
	border-radius: .15em;
	z-index: 100;
}

.message__row__meta:first-of-type:hover:after {
	top: 0;
}

.message__read,
.message__unread {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--text-generic);
	height: 3em;
	line-height: 3em;
	font-size: 1.5em;
	text-align: center;
	text-transform: uppercase;
}

.message__unread,
.message__row__meta__title__spot {
	background-color: var(--background-reject);
}

.message__read,
.message__row__meta.read .message__row__meta__title__spot {
	background-color: var(--background-resolve);
}

.message__row__meta__title__spot {
	background-image: url('../images/message-unread.svg');
}

.message__row__meta.read .message__row__meta__title__spot {
	background-image: url('../images/message-read.svg');
	background-position: 50% 40%;
}

.message__row__single__close {
	margin: 0;
	left: -1em;
	padding-left: 1em;
	margin-right: -1em;
	background: var(--background-body);
	z-index: 55;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 1em;
	padding: 0;
	height: 4em;
	flex: 0 1 auto;
}

.message__row__single__close button {
	width: 100%;
	height: 100%;
	border: none;
	font-size: 1.2em;
	text-transform: uppercase;
	border-bottom: .3em solid var(--transparent-dark-20);
	padding: 0;
}

.message__row__single__close button:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.shown-mobile {
	display: none;
}

.message__placeholder {
	font-size: 1.6em;
	display: block;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: var(--text-generic);
	text-align: center;
}

@media (max-width: 991px) {
	.hidden-mobile {
		display: none;
	}

	.shown-mobile {
		display: inline;
	}

	.message__row__single {
		border-left: none;
	}
}