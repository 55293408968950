.device-name {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
}

.device-name__items {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
}

.device-name__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	margin: 0;
	color: var(--background-text-region-dark);
}

.device-name__items__item {
	height: 3.7em;
	color: var(--text-generic);
	position: relative;
	border-bottom: .15em solid var(--border-neutral);
	font-size: 1.2em;
	text-align: center;
	line-height: 3.7em;
}

.device-name__items__item span {
	display: block;
	padding: 0 8em 0 1em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 1.1em;
}

.device-name__items__item.selected {
	background-image: repeating-linear-gradient(-45deg, transparent, transparent .5em, var(--transparent-light-05) .5em, var(--transparent-light-05) 1em);
}

.device-name__items__item__buttons {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 14em;
	height: 3.08em;
	font-size: 1.2em;
}

.device-name__items__item__buttons button {
	width: 6em;
	float: right;
	color: var(--text-generic);
	border: none;
	padding: 0;
	height: 100%;
	border-bottom: .3em solid var(--transparent-dark-20);
	text-transform: uppercase;
	line-height: 2.5em;
}

.device-name__items__item__buttons button:not(:disabled):hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.device-name__items__item__buttons button:disabled {
	background: var(--background-button-resolve-no-transparency);
	color: var(--text-disabled-no-transparency);
}

.device-name__items__item__input {
	color: var(--background-text-region-dark);
	border: none;
	height: 3.08em;
	padding: 0 1em;
	width: 100%;
	padding-right: 14em;
	position: absolute;
	left: 0;
	border-bottom: .3em solid var(--transparent-dark-20);
	box-shadow: none;
	font-size: 1.2em;
}

.device-name__items__item__input.ng-invalid:not(:focus) {
	border-bottom-color: var(--border-input-invalid);
	background: var(--background-input-invalid);
}

@media (max-width: 991px) {
	.device-name__items__item__buttons.open {
		position: relative;
		margin-top: 3.08em;
		width: auto;
		z-index: 10;
	}

	.device-name__items__item__buttons.open button:not(:only-of-type) {
		width: 50%;
	}

	.device-name__items__item__buttons.open button:only-of-type {
		width: 100%;
	}

	.device-name__items__item__input {
		width: 100%;
		max-width: 100%;
		padding-right: 1em;
	}

	.device-name__items__item.open {
		height: 7.4em;
	}
}