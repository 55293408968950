.sim-history {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
}

.sim-history__items {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 4em;
	overflow: hidden;
}

.sim-history__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	margin: 0;
	color: var(--background-text-region-dark);
}

.sim-history__filter__current .jambox-chevron:after {
	border-left-color: var(--background-button-generic-focused);
	height: 3.1em;
}

.sim-history__items__item {
	color: var(--text-generic);
	position: relative;
	border-bottom: .15em solid var(--border-neutral);
	font-size: 1.2em;
	text-align: center;
	line-height: 3.7em;
	height: 3.7em;
	width: 100%;
	cursor: pointer;
}

.sim-history__items__item:hover div:after {
	position: absolute;
	top: -.2em;
	left: 0;
	right: 0;
	bottom: -.15em;
	border-color: var(--highlight);
	border-style: solid;
	border-width: .3em 0;
	content: '';
	display: block;
	z-index: 100;
}

.sim-history__items__item:first-of-type:hover div:after {
	top: 0;
}

.sim-history__items__item:last-of-type:hover div:after {
	bottom: 0;
}

.sim-history__items__item:hover div.first:after {
	border-radius: .15em 0 0 .15em;
	border-left-width: .3em;
}

.sim-history__items__item:hover div.last:after,
.sim-history__items__item:hover div.visibility-border-md:after {
	border-radius: 0 .15em .15em 0;
	border-right-width: .3em;
}

.sim-history__items__item span {
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding: 0 1.5em;
}

.sim-history__items__item .mobile span:first-of-type {
	position: relative;
	top: .1em;
}

.sim-history__items__item .mobile span:last-of-type {
	position: relative;
	bottom: .1em;
}

.sim-history__selected {
	background: var(--background-body);
	position: absolute;
	top: 3.84em;
	right: 0;
	bottom: 0;
	border-left: .15em solid var(--border-neutral);
	padding: 1em 2em !important;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	z-index: 200;
	color: var(--text-generic);
}

.sim-history__selected__header h1 {
	position: relative;
	font-size: 1.5em;
	z-index: 120;
	margin-top: .35em;
	line-height: 1.3em;
}

.sim-history__selected__contents {
	overflow: hidden;
	flex: 1 1 auto;
	margin-bottom: 4.5em;
	margin-top: 1em;
	font-size: 1.15em;
	margin-right: -1.75em;
    padding-right: 1.75em;
}

.sim-history__selected__contents li,
.sim-history__selected__contents ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.sim-history__selected__contents li {
	display: block;
	border-bottom: .15em solid var(--border-neutral);
	padding: .3em;
}

.sim-history__selected__contents li:last-of-type {
	border-bottom: none;
}

.sim-history__selected__contents li span {
	text-align: right;
	float: right;
}

.sim-history__selected__close {
	margin: 1.5em 0;
	left: -1em;
	padding-left: 1em;
	margin-right: -1em;
	background: var(--background-body);
	z-index: 55;
	position: absolute;
	bottom: -1.5em;
	left: -.15em;
	right: 1em;
	padding: 0;
	height: 4em;
	flex: 0 1 auto;
}

.sim-history__selected__close button {
	width: 100%;
	height: 100%;
	border: none;
	font-size: 1.3em;
	text-transform: uppercase;
	background: var(--background-various-light);
	border-bottom: .3em solid var(--transparent-dark-20);
	padding: .6em 1em;
	display: block;
	margin-bottom: .3em;
	line-height: 1.5em;
	color: var(--text-generic);
}

.sim-history__selected__close button:hover,
.sim-history__filter__current:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.sim-history__filter__current:hover .jambox-chevron:after {
	border-left-color: var(--highligh-border) !important;
}

.sim-history__filter__current:hover span:after {
	border-bottom-color: var(--background-text-region-dark);
}

.sim-history__filter {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--background-button-generic);
	height: 4em;
	padding: 0 !important;
	text-transform: uppercase;
}

.sim-history__filter.disabled {
	opacity: .5;
}

.sim-history__filter.disabled .sim-history__filter__current {
	cursor: wait;
}

.sim-history__filter__month,
.sim-history__filter__type {
	height: 4em;
	width: 50%;
	float: left;
	position: relative;
}

.sim-history__filter__options {
	position: absolute;
	left: 0;
	right: 0;
	background: var(--border-list-item);
	overflow: hidden;
	text-transform: none;
	z-index: 150;
	margin-right: .55em;
}

.sim-history__filter__options__inner {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.sim-history__filter__options .ss-scroll {
	background: var(--background-various-light);
}

.sim-history__filter__options__inner .ss-content div {
	height: 3em;
	line-height: 3em;
	border-bottom: .15em solid var(--border-filter-list);
	font-size: 1.1em;
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 0 1em;
	cursor: pointer;
}

.sim-history__calendar.sim-history__filter__options {
	height: 21.45em; /* 6.5*3.3 */
	top: -21.45em;
}

.sim-history__calendar .sim-history__filter__options__inner {
	top: 4em;
	height: auto;
}

.sim-history__filter__options__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 4em;
	background: var(--background-title-light);
}

.sim-history__filter__options__header span {
	line-height: 3.3em; /* 4/1.2 */
	font-size: 1.2em;
	text-align: center;
	position: absolute;
	display: block;
	top: 0;
	height: 3.35em;
}

.sim-history__filter__options__header__left,
.sim-history__filter__options__header__right {
	width: 3.3em;
	color: transparent;
}

.sim-history__filter__options__header__left:after,
.sim-history__filter__options__header__right:after {
	content: '';
	color: var(--background-logo);
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	border-style: solid;
	transform: translateX(-50%) translateY(-50%);
}

.sim-history__filter__options__header__left:hover,
.sim-history__filter__options__header__right:hover {
	background: var(--highlight);
	cursor: pointer;
}

.sim-history__filter__options__header__left {
	width: 3.3em;
	left: 0;
}

.sim-history__filter__options__header__left::after {
	border-width: .6em .6em .6em 0;
	border-color: transparent var(--background-text-region-dark) transparent transparent;
	margin-left: -.1em;
}

.sim-history__filter__options__header__right {
	width: 3.3em;
	right: 0;
}

.sim-history__filter__options__header__right::after {
	border-width: .6em 0 .6em .6em;
	border-color: transparent transparent transparent var(--background-text-region-dark);
	margin-right: -.1em;
}

.sim-history__filter__options__header__center {
	left: 3.3em;
	right: 3.3em;
	font-weight: bold;
}

.sim-history__filter__options__inner .ss-content div:hover {
	background: var(--highlight);
	border-bottom-color: var(--highlight);
	color: var(--text-verydark);
}

.sim-history__filter__current {
	position: relative;
	font-size: 1.3em;
	line-height: 3.1em;
	color: var(--text-generic);
	text-align: center;
	cursor: pointer;
}

.sim-history__filter__current span {
	margin-left: -.8em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	width: 100%;
	padding: 0 2em;
}

.sim-history__filter__current span:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 .6em .6em .6em;
	border-color: transparent transparent var(--background-title-light) transparent;
	transition: .3s transform;
	position: absolute;
	top: 1.2em;
	right: 1.3em;
}

.sim-history__filter__current.menu-open span:after {
	transform: scaleY(-1);
}

.sim-history .conditional-show-lg {
	display: none;
}

.sim-history .conditional-hide-true .conditional-hide-lg {
	display: none;
}

.sim-history .conditional-hide-true .conditional-show-lg {
	display: block;
}

.sim-history .mobile {
	display: none;
}

.sim-history .filter-selected {
	font-weight: bold;
}

.sim-history__placeholder {
	font-size: 1.6em;
	display: block;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: var(--text-generic);
	text-align: center;
}

.sim-history__loading {
	font-size: 1.4em;
	display: block;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: var(--text-generic);
	text-align: center;
}

.sim-history__loading:before {
	content: '';
	width: 7em;
	height: 7em;
	background: url(../images/cogwheel.svg);
	display: block;
	position: relative;
	animation: spin 4s linear infinite;
	left: 50%;
	margin: 7em 0 2em -3.5em
}

.sim-history.has-sidebar .sim-history__items .ss-track {
	right: 33.33%;
}

@media (max-width: 1200px) {
	.sim-history.has-sidebar .sim-history__items .ss-track {
		right: 58.3%;
	}
}

@media (max-width: 1199px) {
	.sim-history .conditional-hide-true .conditional-hide-md {
		display: none;
	}

	.sim-history__items__item:hover div.visibility-border-md:after {
		display: none;
	}

	.sim-history__items__item:hover div.visibility-border-sm:after {
		border-radius: 0 .15em .15em 0;
		border-right-width: .3em;
	}

	.sim-history__filter.side-panel-open .sim-history__filter__type {
		display: none;
	}

	.sim-history__filter.side-panel-open .sim-history__filter__month {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.sim-history .mobile {
		display: block;
	}

	.sim-history .desktop {
		display: none;
	}

	.sim-history__items__item {
		line-height: 1.85em;
	}
}
