.vod {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
}

.vod__kiosks,
.vod__categories,
.vod__content {
	position: absolute;
	top: 0;
	bottom: 0;
	overflow: hidden;
}

.vod__kiosks {
	left: 0;
	width: 9em;
	z-index: 5;
}

.vod__kiosks:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 8em;
	background: var(--background-logo);
}

.vod__categories {
	left: 8em;
	width: 15em;
	background: var(--background-category-list);
}

.vod__content {
	left: 23em;
	right: 0;
}

.vod.single-category .vod__content {
	left: 8em;
}

.vod__kiosks ul,
.vod__categories ul,
.vod__content > div {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
}

.vod__kiosks ul {
	overflow-y: scroll;
}

.vod__kiosks ul,
.vod__kiosks li,
.vod__categories ul,
.vod__categories li,
.vod__content ul,
.vod__content li {
	margin: 0;
	padding: 0;
	list-style: none;
	image-rendering: auto;
}

.vod.single-category .vod__kiosks {
	width: 9.5em;
}

.vod.single-category .vod__kiosks:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 1.5em;
	background: linear-gradient(to right, var(--transparent-dark-25) 0%, transparent 100%);
	z-index: 10;
}

.vod__kiosks li {
	background: var(--background-logo);
	width: 8em;
	height: 5.3em;
	padding: 0;
	cursor: pointer;
	position: relative;
	background-repeat: no-repeat;
	background-size: 85%;
	background-position: center;
}

.vod__kiosks li:not(:last-of-type) {
	border-bottom: .15em solid var(--border-light);
}

.vod__kiosks li.selected:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 2.55em 0 2.55em 1em;
	border-color: transparent transparent transparent var(--background-logo);
	position: absolute;
	top: 0;
	bottom: 0;
	right: -1em;
	z-index: 20;
}

.vod__kiosks span {
	display: none;
}

.vod__categories ul {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	background: var(--background-category-list);
}

.vod__categories li {
	font-size: 1.15em;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.vod__categories li:not(:first-of-type) {
	margin-top: -.15em;
}

.vod__categories li:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-bottom: .14em solid var(--border-nav-item);
}

.vod__categories li:hover {
	background: var(--background-category-hover);
}

.vod__categories li:hover:after {
	border-bottom-color: var(--background-category-hover);
}

.vod__categories li.selected {
	color: var(--background-category-selected);
}

.vod__categories li.selected:before {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	top: 50%;
	margin-top: -.7em;
	right: 1.5em;
	border-style: solid;
	border-width: .7em 0 .7em .7em;
	border-color: transparent transparent transparent var(--highlight);
}

.vod__categories li span {
	position: relative;
	z-index: 10;
	height: 100%;
	width: 100%;
	display: inline-block;
	padding: .8em 2em .85em 2em;
}

.vod__content ul {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	align-items: flex-start;
}

.vod__content li {
	min-height: 25em;
	flex: 0 0 20%;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	background: url('../images/vod-placeholder.png') no-repeat 50% 150%;
	transition: .4s background-position;
}

.vod__content li.extension-visible {
	cursor: default;
}

.vod__content li:not(.extension-visible):hover:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: .3em solid var(--highlight);
	display: block;
	border-radius: .15em;
	z-index: 4;
}

.vod__content li.is-new:after {
	content: 'Nowy';
	position: absolute;
	top: 4.3em;
	right: -.7em;
	width: 10em;
	height: 2em;
	line-height: 2em;
	background: var(--background-reject);
	transform: rotate(45deg);
	text-align: center;
	transform-origin: bottom right;
	text-transform: uppercase;
	font-weight: bold;
	box-shadow: 0 0 1em var(--transparent-dark-20);
	transition: .4s top, .4s right, .4s color;
	z-index: 0;
}

.vod__content li.extension-visible.is-new:after {
	top: 2.7em;
	right: -2.3em;
	color: transparent;
}

.vod__content__list__image div {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	transition: .4s transform;
}

.vod__content__list__image__crisp-mask {
	image-rendering: pixelated;
	opacity: .5;
}

.vod__content__list__extension__inner__info h1,
.vod__content__list__extension__fake-header {
	font-size: 1.4em;
	padding: .5em .6em;
	margin-top: 0;
}

.vod__content__list__extension__inner__info h1 span,
.vod__content__list__extension__fake-header {
	line-height: 1.2em;
}

.vod__content > div {
	height: 100%;
}

.vod__content__list__extension {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
}

.vod__content__list__extension p {
	flex-grow: 1;
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 0 .9em;
	margin-bottom: 1em;
	position: absolute;
	top: .3em;
	bottom: 4.3em;
	left: 0;
}

.vod__content__list__extension button {
	display: block;
	height: 3.25em;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	padding: .6em 1em;
	line-height: 1.5em;
	font-size: 1.3em;
	text-transform: uppercase;
	background: var(--background-various-light);
}

.vod__content__list__extension button:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.vod__content__list__extension button:disabled {
	opacity: .5;
}

.vod__content button.if-leased {
	background: var(--background-resolve);
	opacity: 1;
	color: var(--text-generic);
	cursor: not-allowed;
}

.vod__content li.is-tvod.is-leased > div:before,
.vod__content li.is-custom.is-leased > div:before {
	content: '';
	position: absolute;
	top: .7em;
	left: .7em;
	width: 2em;
	height: 2em;
	border-radius: 5em;
	background: var(--background-home-icon) url('../images/tick.svg') no-repeat center;
	background-size: 70%;
	display: block;
	z-index: 4;
	transition: .5s opacity;
	opacity: 1;
	box-shadow: 0 0 2em var(--transparent-ligth-40);
}

.vod__content li.is-tvod.is-leased.extension-visible > div:before,
.vod__content li.is-custom.is-leased.extension-visible > div:before {
	transition: .3s opacity;
	opacity: 0;
}

.vod__content li.is-tvod.is-leased .vod__content__list__image:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--background-video);
	display: block;
	z-index: 3;
	transition: .5s opacity;
	opacity: .55;
}

.vod__content li.is-tvod.is-leased:hover .vod__content__list__image:after {
	transition: .3s opacity;
	opacity: 0;
}

.vod__content__list__extension button {
	display: none;
}

.vod__content li.is-custom.is-leased button.if-leased,
.vod__content li.is-custom:not(.is-leased) button.if-queued {
	display: block;
}

.vod__content li.is-tvod.is-leased button.if-tvod.if-leased,
.vod__content li.is-tvod:not(.is-leased) button.if-tvod.if-not-leased,
.vod__content li.is-regular.is-queued button.if-not-tvod.if-queued,
.vod__content li.is-regular:not(.is-queued) button.if-not-tvod.if-not-queued {
	display: block;
}

.vod__content__list__price,
.vod__content__list__extension__price {
	display: none;
}

.vod__content li.is-tvod:not(.is-leased) .vod__content__list__extension__price,
.vod__content li.is-tvod:not(.is-leased) .vod__content__list__price {
	display: block;
	z-index: 4;
	position: absolute;
	padding: .2em .4em;
	text-align: center;
	background: var(--highlight);
	color: var(--background-text-region-dark);
	font-size: 1.1em;
	font-weight: bold;
	border-radius: .1em;
}

.vod__content li.is-tvod:not(.is-leased) .vod__content__list__price {
	top: .5em;
	left: .5em;
	opacity: 1;
	transition: .5s opacity;
	z-index: 0;
}

.vod__content li.is-tvod.extension-visible .vod__content__list__price {
	opacity: 0;
}

.vod__content li.is-tvod:not(.is-leased) .vod__content__list__extension__price {
	bottom: 0;
	top: auto;
	opacity: 0;
	left: 50%;
	transform: translateX(-50%);
	border-radius: .2em .2em 0 0;
	padding: .4em .8em;
	transition: .5s opacity, .5s bottom;
	z-index: 3;
	box-shadow: 0 0 .5em .3em var(--background-body);
}

.vod__content li.is-tvod:not(.is-leased) button.if-tvod.if-not-leased {
	z-index: 4;
}

.vod__content li.is-tvod:not(.is-leased) button.if-tvod.if-not-leased:hover + .vod__content__list__extension__price {
	opacity: 1;
	bottom: 3.8em;
}

.vod__content__list__extension__inner {
	flex:  1 1 auto;
	position:  relative;
}

.vod__content__list__extension__fake-header {
	flex: 0 0 auto;
	margin:  0;
	opacity:  0;
}

.vod__content__list__extension__inner__info {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	transform: translateY(100%);
	background: var(--transparent-mid-80);
	transition: .4s transform, .4s background;
}

.vod__content__list__extension__inner__info h1 {
	margin: 0;
	background: var(--transparent-mid-80);
	position: relative;
	transition: .4s background;
	/* fixy na niedokładne renderowanie subpikselowe */
	top: .05em;
	transform: translateY(calc(-100% - .05em));
}

.vod__content .ss-content {
	width: 100%;
}

.vod__content .ss-scroll {
	margin-left: -1.25em;
	z-index: 0;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { /* chrome hack */
	.vod__content__list__extension__inner__info h1 {
		transform: translateY(calc(-100% - .002em));
	}
}

@-moz-document url-prefix() {
	@supports (animation: calc(0s)) {
		.vod__content__list__extension__inner__info h1 {
			transform: translateY(calc(-100% - .05em));
		}
	}
}

.vod__content li:hover {
	background-position: 50% 300%;
}

.vod__content li:hover .vod__content__list__image div {
	transform: scale(1.1);
}

.vod__content li:hover .vod__content__list__extension__inner__info {
	transform: translateY(60%);
}


.vod__content li.extension-visible .vod__content__list__extension__inner__info {
	transform: translateY(0);
	background: var(--background-body);
	margin-top: -.1em;
}

.vod__content li.extension-visible .vod__content__list__extension__inner__info h1 {
	background: var(--background-body);
	padding-top: .6em;
	padding-bottom: .6em;
	line-height: 1em;
}

.vod__content__list__extension__inner__info span {
	position: relative;
	top: 0;
	transition: .5s top;
}

.vod__content li:hover .vod__content__list__extension__inner__info span,
.vod__content li.extension-visible .vod__content__list__extension__inner__info span {
	top: .2em;
}

.vod__category-current {
	position: absolute;
	top: 0;
	left: 6.652em;
	right: 0;
	height: 3em;
	padding: .7em 1em;
	text-align: center;
	color: var(--background-text-region-dark);
	font-size: 1.2em;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	background: var(--background-logo);
	cursor: pointer;
	display: none;
}

.vod__content__list__rating {
	margin-top: .5em;
	display: block;
}

.vod__content__list__rating__badge {
	width: 1.5em;
	height: 1.5em;
	float: left;
	margin-right: .4em;
	vertical-align: center;
	display: block;
}

.vod__content__list__rating__badge.age-rating-0 {
	display: none;
}

.vod__content__list__rating__badge.age-rating-1 {
	background-image: url('../images/rating/1.svg');
}

.vod__content__list__rating__badge.age-rating-7 {
	background-image: url('../images/rating/7.svg');
}

.vod__content__list__rating__badge.age-rating-12 {
	background-image: url('../images/rating/12.svg');
}

.vod__content__list__rating__badge.age-rating-16 {
	background-image: url('../images/rating/16.svg');
}

.vod__content__list__rating__badge.age-rating-18 {
	background-image: url('../images/rating/18.svg');
}

.vod__content__placeholder {
	margin: 20vh auto 0;
	padding: 0 5em;
	text-align: center;
	font-size: 1.2em;
}

.vod__content__placeholder span {
	display: block;
	margin-bottom: .5em;
}

.vod .auth-required:after {
	content: '';
	display: inline-block;
	background: url(../images/login.svg) no-repeat;
	background-size: 1em 1em;
	width: 1em;
	height: 1em;
	opacity: .5;
	margin-left: .5em;
	position: relative;
	top: .1em;
}

.vod .auth-required:not(:disabled):hover:after {
	filter: invert(100%);
}

.cross-out {
	text-decoration: line-through;
}

.vod .epg__details__pane {
	position: absolute;
	top: 0;
}

@media (max-width: 4113px) {
	.vod__content li {
		flex-basis: 7.692307692%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 7.142857143%;
	}

	.vod.single-category .vod__content li:nth-of-type(14n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(14n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 3855px) {
	.vod__content li {
		flex-basis: 8.333333333%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 7.692307692%;
	}

	.vod.single-category .vod__content li:nth-of-type(13n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(13n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 3597px) {
	.vod__content li {
		flex-basis: 9.090909091%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 8.333333333%;
	}

	.vod.single-category .vod__content li:nth-of-type(12n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(12n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 3339px) {
	.vod__content li {
		flex-basis: 10%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 9.090909091%;
	}

	.vod.single-category .vod__content li:nth-of-type(11n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(11n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 3081px) {
	.vod__content li {
		flex-basis: 11.1111111111%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 10%;
	}

	.vod.single-category .vod__content li:nth-of-type(10n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(10n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 2823px) {
	.vod__content li {
		flex-basis: 12.5%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 11.1111111111%;
	}

	.vod.single-category .vod__content li:nth-of-type(9n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(9n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 2565px) {
	.vod__content li {
		flex-basis: 14.28571429%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 12.5%;
	}

	.vod.single-category .vod__content li:nth-of-type(8n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(8n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 2307px) {
	.vod__content li {
		flex-basis: 16.6666666666%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 14.28571429%;
	}

	.vod.single-category .vod__content li:nth-of-type(7n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(7n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 2049px) {
	.vod__content li {
		flex-basis: 20%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 16.6666666666%;
	}

	.vod.single-category .vod__content li:nth-of-type(6n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(6n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 1791px) {
	.vod__content li {
		flex-basis: 25%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 20%;
	}

	.vod.single-category .vod__content li:nth-of-type(5n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(5n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 1533px) {
	.vod__content li {
		flex-basis: 33.333333333%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 25%;
	}

	.vod.single-category .vod__content li:nth-of-type(4n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(4n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 1275px) {
	.vod__content li {
		flex-basis: 50%;
	}

	.vod.single-category .vod__content li {
		flex-basis: 33.333333333%;
	}

	.vod.single-category .vod__content li:nth-of-type(3n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(3n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 991px) {
	.vod__categories {
		width: auto;
		right: 0;
		z-index: 4;
		display: none;
	}

	.vod.mobile-category-selector .vod__categories {
		display: block;
	}

	.vod__content {
		left: 8em;
		top: 3.6em;
	}

	.vod__content li,
	.vod.single-category .vod__content li {
		flex-basis: 33.333333333%;
	}

	.vod__kiosks:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 1.5em;
		background: linear-gradient(to right, var(--transparent-dark-25) 0%, transparent 100%);
		z-index: 10;
	}

	.vod__kiosks {
		width: 9.5em;
	}

	.vod__categories li.selected {
		background: var(--highlight);
		color: var(--background-text-region-dark);
	}

	.vod__category-current {
		display: block;
	}

	/*
	 * nie ma stanu hover na mobilnym więc nie przejmujemy się
	 * że kategoria następna po aktywnej nachodzi o .15em w tym stanie
	 */
	.vod__categories li.selected:after {
		border-bottom-color: var(--highlight);
	}

	.vod__content__placeholder {
		font-size: 1.1em;
		width: 16em;
		padding: 0;
	}

	@media screen and (-webkit-min-device-pixel-ratio:0) { /* chrome hack */
		.vod__content__list__extension__inner__info h1 {
			transform: translateY(calc(-100% - .055em));
		}
	}
}

@media (max-width: 740px) {
	.vod__content li,
	.vod.single-category .vod__content li {
		flex-basis: 50%;
	}

	.vod.single-category .vod__content li:nth-of-type(2n+1) .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li:nth-of-type(2n+1) h1 {
		padding-left: 1em
	}
}

@media (max-width: 550px) {
	.vod__content li,
	.vod.single-category .vod__content li {
		flex-basis: 100%;
	}

	.vod.single-category .vod__content li .vod__content__list__extension__inner__info p {
		padding-left: 1.45em
	}

	.vod.single-category .vod__content li h1 {
		padding-left: 1em
	}
}