.watch {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
}

.watch__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	font-size: 1.2em;
	height: 3.23em;
	line-height: 3.23em;
	background: var(--background-title-light);
	text-align: center;
	padding: 0 1em !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.watch__channels {
	position: absolute;
	top: 3.85em;
	bottom: 0;
	right: 0;
}

.watch__missing-jbxgo-notice {
	color: var(--text-generic);
	text-align: center;
	align-items: center;
	justify-content: center;
	padding: 1em .8em .6em;
	width: 100%;
	font-size: 1.05em;
}

.watch__missing-jbxgo-notice a {
	color: var(--text-generic);
	background: var(--background-various-light);
	display: block;
	white-space: nowrap;
	margin: 1em auto;
	padding: .4em 1em;
	font-size: .95em;
	text-transform: uppercase;
	font-weight: bold;
	width: 12em;
	text-decoration: none;
}

.watch__missing-jbxgo-notice a:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.watch__video {
	position: absolute;
	top: 3.85em;
	bottom: 0;
	left: 0;
}

/* -------------------------------------------------------- */

.watch__video__epg {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	color: var(--text-generic);
	overflow: hidden !important;
	white-space: nowrap;
	cursor: grab;
}

.watch__video__epg:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 3.5em;
	height: 100%;
	background: linear-gradient(to right, transparent 0%, var(--background-text-region-dark) 100%);
	display: block;
}

.watch__video__epg.has-placeholder,
.watch__channels.has-placeholder {
	cursor: default;
	background-image: repeating-linear-gradient(-45deg, transparent, transparent .5em, var(--transparent-light-05) .5em, var(--transparent-light-05) 1em);
	background-attachment: fixed;
}

.watch__video__epg.has-placeholder .ss-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.watch__video__epg__placeholder {
	display: inline-flex;
	padding: .6em 1em;
	font-size: 1.2em;
	color: var(--text-generic);
	background: var(--background-text-region-dark);
	flex: 0 0 auto;
	margin-top: -.8em;
}

.watch__video__epg .ss-wrapper {
	overflow-x: scroll;
	overflow-y: hidden;
}

.watch__video__epg .ss-track {
	top: 0;
}

.watch__video__epg:not(.vertical) .ss-content {
	overflow: hidden;
	display: flex;
}

.watch__video__epg__brick:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 3.5em;
	background: linear-gradient(to bottom, transparent 0%, var(--background-text-region-dark) 50%);
	display: block;
}

.watch__video__epg__brick {
	height: 100%;
	display: inline-block;
	padding: 1em 3em 1em 2em;
	position: relative;
	border-right: .2em solid var(--highlight);
	min-width: 5em;
	max-width: 75%;
}

.watch__video__epg__name {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 1.25em;
	margin: .6em 0 .6em;
}

.watch__video__epg__description {
	position: absolute;
	top: 4.2em;
	left: 2em;
	right: 2em;
	bottom: .5em;
	white-space: normal;
	overflow: hidden;
	opacity: .8;
}

.watch__video__epg__description span {
	min-width: 5em;
	display: block;
	hyphens: auto;
}

.watch__video__epg__end {
	font-size: 1.1em;
	position: absolute;
	top: 0;
	right: 0;
	width: 3.2em;
	height: 1.5em;
	line-height: 1.5em;
	text-align: center;
	margin-right: -1.6em;
	background: var(--highlight);
	color: var(--background-video);
	font-weight: bold;
	border-radius: 0 0 .1em .1em;
	z-index: 20;
}

.watch__video__epg__brick:last-of-type {
	border: none;
}

.watch__video__epg__brick:last-of-type .watch__video__epg__end {
	display: none;
}

/* -------------------------------------------------------- */

.watch__video__epg.vertical {
	cursor: default;
}

.watch__video__epg.vertical .watch__video__epg__brick {
	display: block;
	width: 100%;
	border: none;
	border-bottom: .2em solid var(--highlight);
	min-width: 100%;
	max-width: 100%;
	height: auto;
}

.watch__video__epg.vertical .watch__video__epg__brick:after {
	display: none;
}

.watch__video__epg.vertical .watch__video__epg__description {
	width: 100%;
	position: static;
	margin-bottom: 1em;
	max-height: 8em;
}

.watch__video__epg.vertical .watch__video__epg__end {
	bottom: 0;
	left: 50%;
	transform: translateY(-56%);
}

.watch__video__epg.vertical .watch__video__epg__brick:last-of-type .watch__video__epg__end {
	display: block;
}

.watch__video__epg.vertical .watch__video__epg__brick:last-of-type {
	border: none !important;
}

.watch__video__epg.vertical .watch__video__epg__brick:first-of-type .watch__video__epg__end {
	display: none;
}

.watch__video__epg.vertical .watch__video__epg__name {
	margin-top: .5em;
}

/* -------------------------------------------------------- */

.watch__channels__channel {
	height: 5em;
	font-size: 1.2em;
	border-bottom: .15em solid var(--border-neutral);
	color: var(--text-generic);
	position: relative;
	padding: 1em;
	cursor: pointer;
}

.watch__channels__channel__epg__name,
.watch__channels__channel__epg__description {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	position: absolute;
	left: 7em;
	right: 1em;
}

.watch__channels__channel__epg__name {
	top: .8em;
}

.watch__channels__channel__epg__description {
	top: 3.1em;
	left: 8.3em;
	font-size: .85em;
	opacity: .85;
}

.watch__channels__channel:hover:before {
	position: absolute;
	top: -.2em;
	left: 0;
	right: 0;
	bottom: -.2em;
	border: .3em solid var(--highlight);
	content: '';
	display: block;
	border-radius: .15em;
	z-index: 100;
}

.watch__channels__channel:first-of-type:hover:before {
	top: 0;
}

.watch__channels__channel__logo {
	background: var(--background-logo);
	position: absolute;
	top: 0;
	bottom: -.15em;
	left: 0;
	width: 6em;
}

.watch__channels__channel__logo img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: block;
	max-width: 85%; /* height się przeskaluje sam */
}

.watch__channels__channel__logo {
	border-bottom: .2em solid var(--border-light);
}

.watch__video__placeholder {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.watch__video__placeholder:not([style]) {
	display: none;
}

.watch__video__placeholder__button {
	content: '';
	width: 8em;
	height: 8em;
	background: var(--border-light);
	border-radius: 100%;
	display: flex;
	align-items: center;
	text-indent: -10000vw;
	overflow: hidden;
	transition: .8s background;
}

.watch__video__placeholder:hover .watch__video__placeholder__button {
	background: var(--highlight);
	transition: .4s background;
}

.watch__video__placeholder__button:after {
	content: '';
	width: 0;
	height: 0;
	display: block;
	border-style: solid;
	border-width: 2.2em 0 2.2em 3.8em;
	border-color: transparent transparent transparent var(--background-text-region-dark);
	margin-left: 2.7em;
}

/* -------------------------------------------------------- */

body.watch-standalone .video {
	position: absolute !important;
	z-index: 80 !important;
	left: 22em !important;
	right: 0 !important;
	width: calc(100% - 48em);
	height: calc(100% - 21.9em);
	border-radius: 0 !important;
	padding: 0 !important;
	box-shadow: none !important;
}

body.watch-standalone .video__button.drag-handle,
body.watch-standalone .video:not(.fullscreen) .video__ui__channel-name {
	display: none !important;
}

body.watch-standalone .video__ui__quality {
	top: calc(50% + 5.8em);
}

/* -------------------------------------------------------- */

@media (min-width: 769px) { /* próg jest > 768 */
	body.watch-standalone .video {
		top: 11.85em !important;
	}

	body.watch-standalone .video__ui__chromecast {
		bottom: 4.5em;
		right: .5em;
		left: auto;
	}
}

@media (max-width: 768px) {
	.watch__channels {
		top: 3.85em !important;
	}

	.watch__video__placeholder {
		display: none !important;
	}
}

@media (max-width: 1399px) {
	body.watch-standalone .video {
		width: calc(100% - 22em) !important;
	}

	.watch__video__epg {
		display: none;
	}

	.watch__channels {
		width: 100% !important;
	}

	.watch__video__placeholder {
		width: 100% !important;
		display: flex;
	}
}

@media (max-width: 1399px) and (min-width: 769px), (max-height: 768px) and (min-width: 769px) {
	body.watch-standalone .video__ui__fullscreen,
	body.watch-standalone .video__ui__chromecast {
		right: auto;
		left: .5em;
	}

	body.watch-standalone .video__ui__volume-bar {
		top: auto;
		bottom: 4.5em;
		margin-top: 0;
	}

	body.watch-standalone .video__ui__quality {
		top: auto;
		bottom: .5em;
	}

	body.watch-standalone .video__ui__close {
		right: auto;
		left: .5em;
	}
}

@media (max-width: 992px) {
	body.watch-standalone .video {
		left: 0 !important;
		width: 100% !important;
	}
}