.cast {
	position: absolute;
	bottom: -3.8em;
	left: 0;
	right: 0;
	height: 3.8em;
	background: var(--background-tooltip-recording);
	color: var(--background-tooltip);
	z-index: 200;
}

body.has-active-chromecast .cast {
	display: block;
}

.cast__jbx > *:not(.cast__gui__volume__container),
.cast__gui__volume {
	position: absolute;
	top: 0;
	height: 3.8em;
}

.cast button {
	width: 3.8em;
	text-indent: -1000vw;
	overflow: hidden;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	border: none;
}

@media (min-width: 768px) {
	.cast button:not(:disabled):hover {
		background-color: var(--transparent-light-30);
	}
}

@media (max-width: 768px) {
	.cast .video__ui__volume-bar__range-cover:hover + .video__ui__volume-bar__range .video__ui__volume-bar__slider {
		background: var(--background-logo) !important;
	}
}

.cast button:disabled {
	opacity: .5;
	cursor: not-allowed;
}

.cast__icon {
	left: 0;
	width: 3.8em;
	background: url('../images/player/cast.svg') no-repeat center;
	background-size: 60%;
}

.cast__title {
	left: 2.3em;
	right: 9em;
	line-height: 2.9em;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding: 0 .5em 0 1em;
	font-size: 1.3em;
}

.cast__gui__stop {
	right: 0;
	background-image: url('../images/player/stop.svg');
	background-size: 53%;
}

.cast__gui__mute {
	right: 3.8em;
	background-image: url('../images/player/volume.svg');
	background-size: 53%;
}

.cast__gui__mute.muted {
	background-image: url('../images/player/volume-mute.svg');
}

.cast__gui__pause {
	right: 7.6em;
	background-image: url('../images/player/pause.svg');
	background-size: 50%;
}

.cast__gui__pause.paused {
	background-image: url('../images/player/play.svg');
}

.cast__gui__volume {
	bottom: 100%;
	top: auto;
	width: 4em;
	height: 11em;
	right: 4em;
}

.cast__gui__volume .video__ui__volume-bar__track {
	bottom: 0;
	background: var(--background-tooltip-recording);
}

.cast__gui__volume div {
	position: absolute;
}

body.has-active-chromecast .main {
	bottom: 3.8em;
}