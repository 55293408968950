.simorder {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
}

.simorder__header {
	position: relative;
}

.simorder__header > div {
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	color: var(--background-text-region-dark);
}

.simorder__content {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	height: 100%;
}

.simorder .tvorder__order-list__inner {
	height: 100%;
}

.simorder .tvorder__order-list__inner,
.simorder .tvorder__order-list > div {
	position: static;
}

.simorder__service .ss-content > div:last-of-type {
	border-bottom: none;
}

.simorder .tvorder__order-list > div {
	background: transparent;
}

.simorder__service {
    display: flex;
    max-height: 24vh;
}

.simorder__service__inner {
	width: 100%;
}

.simorder .tvorder__order-list {
	display: flex;
	flex-flow: column;
	height: 1em;
	flex: 1 1 auto;
}

.simorder__header__flippable {
	cursor: pointer;
}

.simorder__header__flippable .simorder__header__toggle::after {
	content: '';
	color: var(--background-logo);
	position: absolute;
	top: 45%;
	right: 1em;
	width: 0;
	height: 0;
	border-style: solid;
	transform: translateX(-50%) translateY(-50%);
	border-width: 0 .6em .6em .6em;
	border-color: transparent transparent var(--background-text-region-dark) transparent;
	margin-right: -.1em;
}

.simorder__header__flippable.flipped .simorder__header__toggle::after {
	border-width: .6em .6em 0 .6em;
	border-color: var(--background-text-region-dark) transparent transparent transparent;
	top: 50%;
}

.simorder__header__flippable.flipped {
	opacity: .7;
}

.simorder__status {
	width: 100%;
	margin: 2em auto 4em;
	font-size: 1.2em;
	text-align: center;
}

.simorder .ss-track {
	z-index: 15;
}