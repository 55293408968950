.recording {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
}

.recording.has-sidebar .ss-track {
	right: 41.65%;
}

.recording__list {
	padding: 0;
}

.recording__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
}

.recording__row__meta {
	height: 5em;
	border-bottom: .15em solid var(--border-neutral);
	margin-left: 2em;
	font-size: 1.2em;
	position: relative;
	cursor: pointer;
}

.recording__row__meta > div {
	padding: 1em 3em 1em 1em !important;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.recording__row__meta__date {
	text-align: right;
}

.recording__row__meta__title span,
.recording__row__meta__date span {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.recording__row__meta__title span {
	padding-left: 4.5em;
}

.recording__row__meta__title__spot {
	position: absolute;
	top: 50%;
	left: 1.5em;
	transform: translateY(-50%);
	width: 3em;
	height: 3em;
	border-radius: 3em;
	background: var(--background-reject) url('../images/record.svg') no-repeat center;
	background-size: 56%;
}

.recording__row__single {
	background: var(--background-body);
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	border-left: .2em solid var(--border-neutral);
	padding: 1em 2em !important;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	z-index: 150;
}

.recording__row__single h1 {
	position: relative;
	font-size: 2em;
	z-index: 120;
	margin-top: .35em;
}

.recording__row__single h2 {
	position: relative;
	font-size: 1.5em;
	margin: 0 -1.5em .5em;
	padding: 0 1.5em .8em;
	z-index: 120;
}

.recording__row__contents {
	overflow-y: scroll;
	overflow-x: hidden;
	flex: 1 1 auto;
	margin-bottom: 4.5em;
	margin-top: 1.4em;
}

.recording__row__contents br {
	margin-bottom: .2em;
}

.recording__row__meta:hover:after {
	position: absolute;
	top: -.2em;
	left: 0;
	right: 0;
	bottom: -.2em;
	border: .3em solid var(--highlight);
	content: '';
	display: block;
	border-radius: .15em;
	z-index: 100;
}

.recording__row__meta:first-of-type:hover:after {
	top: 0;
}

.recording__row__single__meta {
	flex: 0 1 auto;
}

.recording__row__single__buttons {
	position: relative;
	left: -1em;
	padding-left: 1em;
	margin-right: -1em;
	background: var(--background-body);
	z-index: 150;
	flex: 0 1 auto;
	min-height: 4em;
}

.recording__row__single__close {
	margin: 1.5em 0;
	left: -1em;
	padding-left: 1em;
	margin-right: -1em;
	background: var(--background-body);
	z-index: 55;
	position: absolute;
	bottom: -1.5em;
	left: 0;
	right: 1em;
	padding: 0;
	height: 4em;
	flex: 0 1 auto;
}

.recording__row__single__close button {
	width: 100%;
	height: 100%;
	border: none;
	font-size: 1.2em;
	text-transform: uppercase;
	background: var(--background-various-light);
	border-bottom: .3em solid var(--transparent-dark-20);
}

.recording__row__single__buttons input:disabled,
.recording__header input:disabled {
	opacity: .5;
}

.recording__row__single__buttons:after {
	position: absolute;
	bottom: -1em;
	left: 0;
	right: -1em;
	height: 1em;
	width: 100%;
	content: '';
}

.recording__row__single__buttons input,
.recording__row__single__buttons button,
.recording__header input {
	text-transform: uppercase;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	padding: .6em 1em;
	display: block;
	width: 100%;
	margin-bottom: .3em;
	line-height: 1.5em;
	color: var(--text-generic);
}

.recording__row__single__buttons input,
.recording__row__single__buttons button {
	line-height: 1.4em;
	font-size: 1.3em;
}

.recording__header__transform-box {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 20.85em;
	transition: .5s left, .5s width;
	white-space: nowrap;
	overflow: hidden;
}

.recording__header__obliterate__prepare {
	left: 0;
}

.recording__header__obliterate__confirm {
	left: 20.85em;
}

.recording__header input {
	position: absolute;
	top: 0;
	height: 100%;
	width: 20.85em;
	display: inline-block;
}

.recording__header input:not(.no) {
	background: var(--background-various-light);
}

.recording__row__single__buttons input:not(:disabled):hover,
.recording__row__single__buttons button:not(:disabled):hover,
.recording__header__obliterate__prepare:not(:disabled):hover,
.recording__row__single__close button:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.recording .status-ready .recording__row__meta__title__spot {
	background-color: var(--background-resolve);
	background-image: url('../images/play.svg');
	background-position: 62% 50%;
}

.recording .status-scheduled .recording__row__meta__title__spot {
	background-color: var(--highlight);
	background-image: url('../images/schedule.svg');
}

.recording .status-failed .recording__row__meta__title__spot {
	background-color: var(--recording-failed);
	background-image: url('../images/cross.svg');
	background-size: 50%;
}

.recording .status-watched .recording__row__meta__title__spot {
	background-color: var(--background-resolve);
	background-image: url('../images/tick.svg');
}

.recording .half-width {
	display: inline-block;
	width: 49%;
	float: left;
}

.recording__placeholder {
	font-size: 1.6em;
	display: block;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: var(--text-generic);
	text-align: center;
}

.recording__header__storage {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	padding-right: 2em;
	width: 15em;
	text-align: right;
}

.recording__header.animate .recording__header__transform-box {
	left: -20.85em;
	width: 41.7em;
}

@media (max-width: 1200px) {
	.recording.has-sidebar .ss-track {
		right: 50%;
	}
}

@media (max-width: 991px) {
	.recording__header__storage {
		width: 6em;
		white-space: nowrap;
	}

	.recording__header__storage span {
		display: none;
	}

	.recording__header__transform-box {
		width: 18em;
	}

	.recording__header__obliterate__confirm {
		left: 18em;
	}

	.recording__header input {
		width: 18em;
	}

	.recording__header.animate .recording__header__transform-box {
		left: -18em;
		width: 36em;
	}
}
