.login:after {
	background: var(--background-button-generic);
	height: 8em;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.login__form {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	background: var(--background-body);
}

.login__form__inner {
	position: absolute;
	width: 60em;
	max-width: 90%;
	transform: translateX(-50%);
	top: 0;
	bottom: 0;
	left: 50%;
	margin-left: 0;
	background: var(--background-text-region-dark);
	padding-top: 3em;
}

.login__form__inner .row {
	color: var(--text-generic);
	font-size: 1.2em;
	padding: 0 3em !important;
	margin-bottom: 1em !important;
}

.login__form__inner .row > div {
	text-align: right;
}

.login__form__inner input {
	width: 100%;
	border: none;
	padding: .6em 1em;
}

.login__form__inner input[type="text"],
.login__form__inner input[type="password"] {
	color: var(--background-text-region-dark);
	border-bottom: .3em solid var(--transparent-dark-20);
}

.login__form__inner label {
	font-weight: normal;
	padding: .6em 2em .6em 0 !important;
}

.login__form__inner input[type="submit"],
.login__form__inner input[type="button"] {
	text-transform: uppercase;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	line-height: 1.5em;
	margin: .5em auto .3em;
	width: calc(100% - 1em);
}

.login__form__inner input[type="submit"]:not(:disabled):hover,
.login__form__inner input[type="button"]:not(:disabled):hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.login__form__inner input[type="button"] {
	background: var(--background-various-light);
}

.login__form__inner input[type="submit"]:disabled {
	opacity: .5;
}

.login__form__inner .row.login__form__inner__branding {
	margin: 0 0 3em !important;
	padding: 0 1em 2em !important;
	border-bottom: .3em solid var(--background-button-generic);
	position: relative;
}

.login__form__inner .row.login__form__inner__branding:after {
	content: '';
	display: block;
	position: absolute;
	left: -100vw;
	width: 300vw;
	bottom: -.3em;
	border-bottom: .3em solid var(--background-button-generic);
}

.login__form__inner__branding > * {
	display: block;
}

.login__form__inner__branding img {
	width: 15em;
	margin: 0 auto;
}

.login__form__inner__branding span {
	font-size: 1.2em;
	color: var(--text-intro);
	width: 12.4em;
	margin-left: 2.6em;
}

.login__form__inner__first-login-tip div {
	text-align: left !important;
}

.login__form__inner__remind {
	margin-top: 2em;
	text-align: center !important;
}

.login__form__inner__remind a,
.login__form__inner__first-login-tip a {
	color: var(--background-title-light);
	text-decoration: none;
}

.login__form__inner__remind a:hover,
.login__form__inner__first-login-tip a:hover {
	color: var(--highlight);
}

.login__form__challenge > div {
	margin: 1em auto !important;
}

/* ------------------------------------------- */

.login__form__ask-remember p {
	font-size: .92em;
	padding: 0 1.05em;
}

.login__form__ask-remember p:first-of-type {
	font-size: 1.2em;
	padding: 0 .8em;
}

/* ------------------------------------------- */

.login__device-selector-mask {
	position: absolute;
	top: 0;
	left: 22em;
	height: 8em;
	width: 25em;
	background: var(--background-button-generic);
	z-index: 150;
}

.device-current {
	position: absolute;
	left: 22em;
	background: var(--background-button-generic);
	color: var(--text-generic);
	white-space: nowrap;
	width: 25em;
	z-index: 220;
}

.device-current {
	top: 0;
	cursor: pointer;
}

@media (max-width: 991px) {
	.device-current {
		left: auto;
		right: 0;
	}

	.login__form__inner .row > div {
		text-align: left;
	}

	.row.login__form__inner__branding:first-of-type {
		margin: 5vh 0 !important;
		padding-bottom: 5vh !important;
	}

	.login__form__inner {
		padding-top: 1em;
	}

	.login__form__inner__buttons {
		display: flex;
		flex-direction: column-reverse;
	}

	.login__form__ask-remember p {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.device-current:hover,
.device-current.menu-open {
	background: var(--background-button-generic-focused);
}

.device-current:after {
	content: '';
	position: absolute;
	top: 50%;
	right: 2.5em;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: .7em .7em 0 .7em;
	border-color: var(--background-title-light) transparent transparent transparent;
	margin-top: -.5em;
	transition: .3s transform;
}

.device-current.menu-open:after {
	transform: scaleY(-1);
}

.device-current .device__tile {
	border: none;
}

.device-current .device__tile__image > div {
	background-position: 20% 40%;
	top: 1.4em;
	left: 1.85em;
	width: 5em;
	height: 5em;
	border-radius: 5em;
}

.device-current .device__tile__details {
	width: 17em;
	height: 8em;
	position: absolute;
	top: 0;
	left: 8em;
	padding: 1.7em 1em 1.5em;
}

.device-current .device__tile__details div {
	color: var(--background-title-light);
}

.device-current .device__tile__details div:first-of-type {
	color: var(--text-generic);
}

.device__tile {
	width: 100%;
	height: 8em;
	display: block;
	position: relative;
}

.device__tile__details {
	width: 17em;
	height: 8em;
	position: absolute;
	top: 0;
	left: 8em;
	padding: 1em;
}

.device__tile__image {
	width: 8em;
	height: 8em;
	background: var(--border-light);
	position: absolute;
	top: 0;
	left: 0;
	image-rendering: optimizequality;
}

.device__tile__image > div {
	background-repeat: no-repeat;
	background-color: var(--background-logo);
	position: absolute;
}

.device__tile__details__name {
	width: 11.5em;
	text-overflow: ellipsis;
	overflow: hidden;
}

.device__tile__details div {
	color: var(--background-button-generic);
	font-size: .9em;
}

.device__tile__details div:first-of-type {
	color: var(--background-text-region-dark);
	font-weight: 600;
	padding-bottom: .3em;
	font-size: 1em;
}

.device-current .device__tile__image {
	background: transparent;
}

/* ------------------------------------------- */

.login__init {
	width: 100%;
    height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100000;
	background: var(--background-body);
}

.login__init__fadein__container {
	/* webkit nie lubi opacity na obiektach 3d */
	opacity: 0;
	animation: fade-in .7s forwards ease-out;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.login__init__cube {
   transform-style: preserve-3d;
   animation: login__skew .7s .5s forwards ease-out;
   position: relative;
   width: 10em;
   height: 10em;
   top: 50%;
   left: 50%;
   margin-left: -5em;
   margin-top: -10em;
}

.login__init__text {
	color: var(--text-generic);
	font-size: 1.5em;
	position: absolute;
	top: 50%;
	margin-top: 3em;
	width: 100%;
	text-align: center;
	animation: 1s pulse-preload infinite;
}

.login__init__cube div {
	width: 10em;
	height: 10em;
	line-height: 10em;
	text-align: center;
	box-shadow: inset 0 0 0 .1em var(--transparent-intro-shine);
	display: block;
	position: absolute;
	outline: 1px solid transparent;
}

.login__init__cube__front {
	background: var(--background-title-light) url(../images/icon/icon.svg) no-repeat center;
	background-size: 13em;
	background-position: 20% 20%;
}

.login__init__cube__top,
.login__init__cube__bottom,
.login__init__cube__left,
.login__init__cube__back,
.login__init__cube__right {
	background: var(--background-title-light);
}

.login__init__cube__top {
	transform: rotateX(90deg);
	margin-top: -5em;
}

.login__init__cube__right {
	transform: rotateY(90deg);
	margin-left: 5em;
}

.login__init__cube__bottom {
	transform: rotateX(-90deg);
	margin-top: 5em;
}

.login__init__cube__left {
	transform: rotateY(-90deg);
	margin-left: -5em;
}

.login__init__cube__front {
	transform: translateZ(5em);
}

.login__init__cube__back {
	transform: translateZ(-5em) scaleX(-1);
}

/* ---------------------------------------------- */

.login__button {
	background: var(--background-button-generic);
	position: absolute;
	height: 8em;
	color: var(--text-generic);
	z-index: 50;
	cursor: pointer;
	width: 25em;
	right: 0;
	top: 0;
}

.login__button span {
	position: absolute;
	display: block;
	padding-right: 1em;
}

.login__button__primary {
	top: 1.4em;
	left: 6.7em;
	width: 14.2em;
	margin-bottom: .5em;
	font-size: 1.2em;
}

.login__button:after {
	content: '';
	width: 3.8em;
	height: 3.8em;
	position: absolute;
	left: 2.5em;
	top: 2.2em;
	background: url('../images/login.svg') no-repeat center;
}

.login__button__secondary {
	top: 3.7em;
	left: 8.45em;
	width: 17.9em;
	font-size: .95em;
}

.login__button:hover {
	background: var(--background-button-generic-focused);
}

@keyframes login__skew {
	0% { transform: rotateX(0) rotateY(0); }
	100% { transform: rotateX(-20deg) rotateY(-45deg); }
}

@keyframes fade-in {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes pulse-preload {
	0% { opacity: .5; }
	50% { opacity: 1; }
	100% { opacity: .5; }
}

@media (max-width: 991px) {
	.login__form__inner {
		max-width: 100%;
		width: 100%;
	}

	.login__form__inner input[type="submit"],
	.login__form__inner input[type="button"] {
		width: 100%;
	}

	.login__form__inner.has-input-focus .row.login__form__inner__branding,
	.login__device-selector-mask {
		display: none;
	}
}

/* ---------------------------------------------------- */

.device__home-indicator {
	position: absolute;
	top: 4.4em;
	left: 4.9em;
	width: 2.5em;
	height: 2.5em;
}

.device__home-indicator__icon {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 100em;
	width: 100%;
	height: 100%;
	background: var(--background-home-icon) url('../images/house.svg') no-repeat center 45%;
	background-size: 68%;
}

.device__home-indicator__pop {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 100em;
	box-shadow: 0 0 0 .3em var(--background-resolve);
	width: 100%;
	height: 100%;
	transform: translateX(-50%) translateY(-50%);
	animation: 3s glow linear infinite;
}

.device__home-indicator.out-of-home .device__home-indicator__icon {
	background-color: var(--background-button-generic);
	background-image: url('../images/house-strike.svg');
}

.device-current:hover .device__home-indicator.out-of-home .device__home-indicator__icon,
.device-current.menu-open .device__home-indicator.out-of-home .device__home-indicator__icon {
	background-color: var(--background-button-generic-focused);
}

.device__home-indicator.out-of-home .device__home-indicator__pop {
	display: none;
}

.device__home-indicator__tooltip {
	background: var(--background-tooltip);
	color: var(--background-text-region-dark);
	width: 24em;
	white-space: normal;
	position: absolute;
	top: 127%;
	font-size: 90%;
	left: 50%;
	margin-left: -12.5em; /* padding! */
	text-align: center;
	box-shadow: 0 0 .8em var(--transparent-dark-60);
}

.device__home-indicator__tooltip:before {
	content: '';
	position: absolute;
	top: -1em;
	left: 50%;
	margin-left: -.5em;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 1em 1em 1em;
	border-color: transparent transparent var(--background-tooltip) transparent;
}

.device__home-indicator__tooltip span {
	padding: .6em 1em;
	display: block;
}

.device__home-indicator__tooltip a {
	margin-top: .3em;
	display: block;
	font-weight: bold;
	background: var(--background-various-light);
	color: var(--text-generic);
	text-transform: uppercase;
	padding: .5em 1em .6em;
	text-decoration: none;
	font-size: 105%;
}

.device__home-indicator__tooltip a:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

@keyframes glow {
	0% { opacity: 1; transform: scale(.8); box-shadow: 0 0 0 .3em var(--background-home-icon); }
	30% { opacity: 1; transform: scale(1.2); box-shadow: 0 0 0 .15em var(--background-home-icon); }
	60% { opacity: 0; transform: scale(1.6); box-shadow: 0 0 0 0 var(--background-home-icon); }
	100% { opacity: 0; }
}