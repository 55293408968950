.sim-summary {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
}

.sim-summary__grid__header {
	min-height: 3.24em;
	max-height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	margin: 0;
	color: var(--background-text-region-dark);
	padding: 0 1em;
	position: relative;
}

.sim-summary__grid {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 4em;
	overflow: hidden;
}

.sim-summary__grid .ss-content {
	overflow-x: hidden;
}

.sim-summary__grid__column {
	width: 33.333333%;
	float: left;
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.sim-summary__grid__column > div,
.sim-summary__grid__column > ul {
	width: 100%;
}

.sim-summary__grid__column:not(:first-of-type) {
	border-left: .15em solid var(--border-neutral);
}

.sim-summary__grid__column:not(:first-of-type) .sim-summary__grid__header {
	margin-left: -.125em;
	width: calc(100% + .15em);
}

.sim-summary__grid__usage {
	color: var(--text-generic);
	font-size: 1.2em;
	margin: 0 auto;
	padding: 1em;
	text-align: center;
	max-height: 9.6em;
	min-height: 9.6em;
}

.sim-summary__grid__usage span {
	font-size: 4em;
	display: block;
}

.sim-summary__grid__chart {
	height: 100%;
	width: 100%;
	background: var(--background-category-list);
	min-height: 15em;
	flex: 1 1 auto;
	position: relative;
	padding: 1em 1em 1em 0;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	/* chrome nie potrafi skalować do 100% wysokości we fleksie */
	.sim-summary__grid__chart svg {
		position: absolute;
		transform: scale(.97, .97);
	}
}

.sim-summary__grid__details {
	min-height: 10em;
	padding: 0 !important;
	margin: 0 !important;
	flex: 0 1 auto;
}

.sim-summary__grid__details li {
	margin: 0 2em;
	display: block;
	border-top: .2em solid var(--border-neutral);
	padding: .3em;
	color: var(--text-generic);
	position: relative;
}

.sim-summary__grid__details span {
	position: absolute;
	right: 0;
}

.sim-summary__tariff .jambox-chevron:after {
	border-left-color: var(--shadow-tooltip);
	height: 3.34em;
}

.sim-summary__filter__current .jambox-chevron:after {
	border-left-color: var(--background-button-generic-focused);
	height: 3.1em;
}

.sim-summary__filter__current:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.sim-summary__filter__current:hover .jambox-chevron:after {
	border-left-color: var(--highligh-border);
}

.sim-summary__filter__current:hover span:after {
	border-bottom-color: var(--background-text-region-dark);
}

.sim-summary__tariff,
.sim-summary__filter {
	position: absolute;
	bottom: 0;
	background: var(--background-button-generic);
	height: 4em;
	padding: 0 !important;
}

.sim-summary__filter {
	left: 0;
	text-transform: uppercase;
	width: 40%;
}

.sim-summary__tariff {
	left: 40%;
	right: 0;
	text-align: center;
	font-size: 1.2em;
	height: 3.35em;
	color: var(--text-generic);
	line-height: 3.35em;
	padding: 0 1em !important;
	background: var(--background-tooltip-recording);
}

.sim-summary__filter__month {
	height: 4em;
	width: 100%;
	float: left;
	position: relative;
}

.sim-summary__filter__options {
	position: absolute;
	left: 0;
	right: 0;
	background: var(--border-list-item);
	overflow: hidden;
	text-transform: none;
	z-index: 150;
	margin-right: .55em;
}

.sim-summary__filter__options__inner {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.sim-summary__filter__options__inner .ss-scroll {
	background: var(--background-various-light);
}

.sim-summary__filter__options__inner__month {
	height: 3em;
	line-height: 3em;
	border-bottom: .15em solid var(--border-filter-list);
	font-size: 1.1em;
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 0 1em;
	cursor: pointer;
}

.sim-summary__calendar.sim-summary__filter__options {
	height: 21.45em; /* 6.5*3.3 */
	top: -21.45em;
}

.sim-summary__calendar .sim-summary__filter__options__inner {
	top: 4em;
	height: auto;
}

.sim-summary__filter__options__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 4em;
	background: var(--background-title-light);
}

.sim-summary__filter__options__header span {
	line-height: 3.3em; /* 4/1.2 */
	font-size: 1.2em;
	text-align: center;
	position: absolute;
	display: block;
	top: 0;
	height: 3.35em;
}

.sim-summary__filter__options__header__left,
.sim-summary__filter__options__header__right {
	width: 3.3em;
	color: transparent;
}

.sim-summary__filter__options__header__left:after,
.sim-summary__filter__options__header__right:after {
	content: '';
	color: var(--background-logo);
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	border-style: solid;
	transform: translateX(-50%) translateY(-50%);
}

.sim-summary__filter__options__header__left:hover,
.sim-summary__filter__options__header__right:hover {
	background: var(--highlight);
	cursor: pointer;
}

.sim-summary__filter__options__header__left {
	width: 3.3em;
	left: 0;
}

.sim-summary__filter__options__header__left::after {
	border-width: .6em .6em .6em 0;
	border-color: transparent var(--background-text-region-dark) transparent transparent;
	margin-left: -.1em;
}

.sim-summary__filter__options__header__right {
	width: 3.3em;
	right: 0;
}

.sim-summary__filter__options__header__right::after {
	border-width: .6em 0 .6em .6em;
	border-color: transparent transparent transparent var(--background-text-region-dark);
	margin-right: -.1em;
}

.sim-summary__filter__options__header__center {
	left: 3.3em;
	right: 3.3em;
	font-weight: bold;
}

.sim-summary__filter__options__inner__month:hover {
	background: var(--highlight);
	border-bottom-color: var(--highlight);
	color: var(--text-verydark);
}

.sim-summary__filter__current {
	position: relative;
	font-size: 1.3em;
	line-height: 3.1em;
	color: var(--text-generic);
	text-align: center;
	cursor: pointer;
}

.sim-summary__filter__current span {
	margin-left: -.8em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	width: 100%;
	padding: 0 2em;
}

.sim-summary__filter__current span:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 .6em .6em .6em;
	border-color: transparent transparent var(--background-title-light) transparent;
	transition: .3s transform;
	position: absolute;
	top: 1.2em;
	right: 1.3em;
}

.sim-summary__filter__current.menu-open span:after {
	transform: scaleY(-1);
}

.sim-summary .filter-selected {
	font-weight: bold;
}

.sim-summary .ct-grid {
	stroke: var(--border-neutral);
}

.sim-summary .ct-label {
	color: var(--text-label);
	font-size: 1em;
	white-space: nowrap;
}

.sim-summary .ct-label.ct-vertical {
	transform: translateY(.45em) translateX(.3em);
}

.sim-summary .ct-label.ct-horizontal {
	transform: translateX(-0.9em);
	min-width: 2em;
	text-align: center;
	display: block;
}

.sim-summary .ct-point {
	stroke-width: 1.2em;
	opacity: .1;
}

.sim-summary .ct-point:hover {
	opacity: 1;
}

.sim-summary .ct-slice-pie.ct-secondary {
	fill: var(--highlight);
}

.sim-summary .ct-line {
	stroke-width: .15em;
}

.sim-summary .ct-point,
.sim-summary .ct-line {
	stroke: var(--highlight);
}

.sim-summary .ct-area {
	fill: var(--highlight);
}

.sim-summary .chartist-tooltip-value {
	display: none;
}

.sim-summary__filter.disabled {
	opacity: .5;
}

.sim-summary__filter.disabled .sim-summary__filter__current {
	cursor: wait;
}

.sim-summary__loading-mask {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: var(--background-text-region-dark);
}

.sim-summary__loading {
	font-size: 1.4em;
	display: block;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: var(--text-generic);
	text-align: center;
}

.sim-summary__loading:before {
	content: '';
	width: 7em;
	height: 7em;
	background: url(../images/cogwheel.svg);
	display: block;
	position: relative;
	animation: spin 4s linear infinite;
	left: 50%;
	margin: 7em 0 2em -3.5em
}

@media (max-width: 1199px) {
	.sim-summary .ct-point {
		opacity: .05;
	}
}

@media (max-width: 991px) {
	.sim-summary__grid__column {
		width: 100%;
		float: none;
	}

	.sim-summary__grid__column:not(:first-of-type) {
		border-left: none;
	}
}
