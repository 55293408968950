.welcome__items__promo {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
	position: absolute;
	top: 3.84em;
	bottom: 0;
	left: 0;
	right: 0;
}

.welcome__items__promo__item {
	flex: 0 0 20%;
	align-self: auto;
	height: 20%;
	position: relative;
	background: var(--background-logo);
	border: .07em solid var(--border-light);
	overflow: hidden;
}

.welcome__items__promo__item span {
	position: absolute;
	left: 0;
	right: 0;
	display: block;
	text-align: center;
	color: var(--background-button-generic-focused);
	font-size: 1.1em;
	z-index: 10;
}

.welcome__items__promo__item span:first-of-type {
	bottom: 20%;
}

.welcome__items__promo__item span:last-of-type {
	bottom: 5px;
}

.welcome__items__promo__item > img {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	image-rendering: auto;
}

.welcome__items__promo__item .obscured-left,
.welcome__items__promo__item .obscured-right,
.welcome__items__promo__item .obscured-number {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: 1s transform;
	z-index: 15;
}

.welcome__items__promo__item.current {
	cursor: pointer !important;
}

.welcome__items__promo__item.current:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 20;
	color: var(--highlight);
	box-shadow: inset 0 0 1em .5em var(--transparent-light-40);
}

.welcome__items__promo__item .obscured-number {
	color: var(--background-button-generic);
	font-size: 6vh;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 100%;
	bottom: auto;
	right: auto;
}

.welcome__items__promo__item .obscured-number.current {
	font-size: 3.5vh !important;
	text-transform: uppercase;
	font-weight: 700;
	text-shadow: 0.06em 0.06em var(--highligh-shadow),0.03em 0.03em var(--highligh-shadow);
	color: var(--highlight);
	letter-spacing: 0.05em;
	margin-top: -.5vh;
}

.welcome__items__promo__item .obscured-number img {
	height: 13vh;
	margin-top: 1vh;
}

.welcome__items__promo__item .obscured-left {
	background: linear-gradient(135deg, var(--background-logo) 0, var(--text-generic) 50%, transparent 50%, transparent 100%);
}

.welcome__items__promo__item .obscured-right {
	background: linear-gradient(135deg, transparent 50%, var(--border-list-item) 50%, var(--text-generic) 100%);
}

.welcome__items__promo__disclaimer {
	background: var(--background-body);
	border-color: var(--background-body);
}

.welcome__items__promo__disclaimer span {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0 !important;
	left: 0;
	right: 0;
	bottom: 0 !important;
	color: var(--text-generic);
	font-size: .8em;
	padding: .8em;
	text-overflow: ellipsis;
}

.welcome__items__promo__item.current.revealed .obscured-left {
	transform: translateX(-100%);
	transition-delay: .5s;
}

.welcome__items__promo__item.current.revealed .obscured-right {
	transform: translateX(100%);
	transition-delay: .5s;
}

.welcome__items__promo__item.current.revealed .obscured-number {
	transform: translateY(-350%) translateX(-50%);
}

.welcome__items__promo .viewport-large,
.welcome__items__promo .viewport-small {
	display: none;
}

.welcome__items__promo__item .obscured-number img {
	width: 20vw;
}

.welcome__items__promo__item img{
	max-width: 95%;
	overflow: hidden;
}

@media (max-width: 1199px) {
	.welcome__items__promo__item span:first-of-type {
		bottom: 18%;
	}

	.welcome__items__promo__item span:last-of-type {
		bottom: 5px;
	}

	.welcome__items__promo__item > img {
		top: 50%;
	}

	.welcome__items__promo__item img{
		margin-top: 0;
		max-width: 140px;
		max-height: 140px;
		overflow: hidden;
	}

}

@media (max-width: 991px) {
	.welcome__items__promo__disclaimer span {
		padding: .2em;
	}

	.welcome__header span {
		display: none;
	}

	.welcome__items__promo .viewport-small {
		display: block;
	}

	.welcome__items__promo__item.current.revealed .obscured-number {
		transform: translateY(-200%) translateX(-50%);
	}
}

@media (min-width: 992px) {
	.welcome__items__promo .viewport-large {
		display: block;
	}
}

@media (max-width: 640px) {
	.welcome__items__promo__item > img {
		max-width: 90%;
	}
}

@media (max-height: 720px){
	.welcome__items__promo__item > img {
		max-width: 50%;
	}
}
