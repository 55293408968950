.parental-lock__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-reject);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	transition: .3s background-color;
	cursor: pointer;
}

.parental-lock.enabled .parental-lock__header {
	background: var(--background-resolve);
}

.parental-lock__header .jambox-chevron:after {
	border-color: transparent transparent transparent var(--shadow-header-status-reject);
}

.parental-lock.enabled .parental-lock__header .jambox-chevron:after {
	border-color: transparent transparent transparent var(--shadow-header-status-resolve);
}

.parental-lock__contents {
	position: absolute;
	top: 3.84em;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	background: var(--background-text-region-dark);
}

.parental-lock__contents__inner {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
}

.parental-lock__contents__inner__list {
	height: 4em;
	color: var(--text-generic);
	line-height: 3.9em;
	border-bottom: .15em solid var(--border-neutral);
	padding-left: 5em !important;
	font-size: 1.2em;
	position: relative;
	cursor: pointer;
}

/* WTF */
/* ostatni element jeśli nieparzysty */

.parental-lock__contents__inner__list:nth-child(odd):last-child:not(:only-child) {
	width: 100%;
}

.parental-lock__contents__inner__list:nth-child(odd):last-child:not(:only-child):hover {
	border-bottom-color: transparent;
}

.parental-lock__contents__inner__list:nth-child(odd):last-child:not(:only-child):hover:before {
	right: 0;
	bottom: -.1em;
}

.parental-lock__contents__inner span {
	text-overflow: ellipsis;
	overflow: hidden;
	padding-right: 1em;
	display: block;
	height: 100%;
	white-space: nowrap;
}

.parental-lock__contents__inner__list:hover:before {
	position: absolute;
	top: -.2em;
	left: -.2em;
	right: -.2em;
	bottom: -.2em;
	border: .3em solid var(--highlight);
	content: '';
	display: block;
	border-radius: .15em;
	z-index: 100;
}

@media (min-width: 992px) {
	.parental-lock__contents__inner__list:nth-of-type(2n) {
		border-left: .15em solid var(--border-neutral);
	}

	.parental-lock__contents__inner__list:nth-of-type(2n-1):hover:before {
		left: 0;
	}

	.parental-lock__contents__inner__list:nth-of-type(2n):hover:before {
		right: 0;
	}

	.parental-lock__contents__inner__list:first-of-type:hover:before,
	.parental-lock__contents__inner__list:nth-of-type(2):hover:before {
		top: 0;
	}

	.parental-lock__contents__inner__list:last-of-type:hover:before,
	.parental-lock__contents__inner__list:last-nth-of-type(2):hover:before {
		bottom: 0;
	}
}

@media (max-width: 991px) {
	.parental-lock__contents__inner__list:hover:before {
		left: 0;
		right: 0;
	}

	.parental-lock__contents__inner__list:first-of-type:hover:before {
		top: 0;
	}

	.parental-lock__contents__inner__list:last-of-type:hover:before {
		bottom: 0;
	}
}

.parental-lock__contents__inner__status {
	position: absolute;
	top: 50%;
	left: 1.5em;
	transform: translateY(-50%);
	width: 2.5em;
	height: 2.5em;
	border-radius: 2.5em;
	background: var(--background-resolve) url('../images/lock-unlocked-wide.svg') no-repeat 57% 45%;
	background-size: 60%;
	opacity: .7;
}

.parental-lock.enabled .parental-lock__contents__inner__status {
	opacity: 1;
}

.locked .parental-lock__contents__inner__status {
	background-color: var(--background-reject);
	background-image: url('../images/lock.svg');
	background-size: 55%;
	background-position: 50% 45%;
}

.parental-lock__header__slider {
	position: absolute;
	top: 0;
	right: 1em;
	bottom: 0;
	width: 16em;
}

.parental-lock__header__slider__text__enabled,
.parental-lock__header__slider__text__disabled {
	display: none;
	position: absolute;
	top: 0;
	right: 5em;
	bottom: 0;
	width: 100%;
	font-size: 1.1em;
	text-align: right;
}

.parental-lock.enabled .parental-lock__header__slider__text__enabled,
.parental-lock:not(.enabled) .parental-lock__header__slider__text__disabled {
	display: block;
	color: var(--background-body);
}

.parental-lock__header__slider__graphics {
	position: absolute;
	top: 50%;
	right: 1em;
	width: 3.5em;
	height: 2.1em;
	transform: translateY(-50%);
	border-radius: 1.1em;
	border: .2em solid var(--background-body);
}

.parental-lock__header__slider__graphics:after {
	content: '';
	position: absolute;
	top: 50%;
	left: .25em;
	width: 1.4em;
	height: 1.4em;
	margin-top: -.73em;
	border-radius: 1.5em;
	background: var(--background-body);
	transition: .3s left;
}

.parental-lock.enabled .parental-lock__header__slider__graphics:after {
	left: 1.6em;
}

.parental-lock__header__transform-box {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 20.85em;
	transition: .5s left, .5s width;
	white-space: nowrap;
	overflow: hidden;
}

.parental-lock__header__transform-box input {
	text-transform: uppercase;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	padding: .6em 1em;
	display: block;
	width: 100%;
	margin-bottom: .3em;
	line-height: 1.5em;
	color: var(--text-generic);
}

.parental-lock__header.animate .parental-lock__header__transform-box {
	left: -20.85em;
	width: 41.7em;
}

.parental-lock__header__apply-restart__prepare {
	left: 0;
	background: var(--background-various-light);
}

.parental-lock__header__apply-restart__confirm {
	left: 20.85em;
	background: var(--highlight);
	color: var(--background-text-region-dark) !important;
}

.parental-lock__header input {
	position: absolute;
	top: 0;
	height: 100%;
	width: 20.85em;
	display: inline-block;
}

@media (max-width: 991px) {
	.parental-lock__header__transform-box {
		width: 18em;
	}

	.parental-lock__header__apply-restart__confirm {
		left: 18em;
	}

	.parental-lock__header input {
		width: 18em;
	}

	.parental-lock__header.animate .parental-lock__header__transform-box {
		left: -18em;
		width: 36em;
	}

	.parental-lock__header__slider__text__enabled,
	.parental-lock__header__slider__text__disabled {
		display: none !important;
	}

	.parental-lock__header__slider {
		width: 5.5em;
	}
}