.password {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
}

.password a {
	color: var(--background-title-light);
	text-decoration: none;
}

.password a:hover {
	color: var(--highlight);
}

.password__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	color: var(--background-text-region-dark);
}

.password__content {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 0;
}

.password__content .row {
	line-height: 4em;
	border-bottom: .2em solid var(--background-body);
}

.password__content__current-password-entry .row {
	line-height: 3.4em;
}

.password__content .row > div:first-of-type {
	text-align: right;
	padding-right: 1em !important;
}

.password__content .row div:first-of-type,
.password__content .row div:nth-of-type(2) {
	font-size: 1.2em;
}

.password__content__buttons {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 14em;
	height: 2.9em;
}

.password__content__buttons button,
.password__content__current-password-entry button {
	width: 6em;
	float: right;
	color: var(--text-generic);
	border: none;
	padding: 0;
	height: 100%;
	border-bottom: .3em solid var(--transparent-dark-20);
	text-transform: uppercase;
	line-height: 2.5em;
}

.password__content__buttons button:not(:disabled):hover,
.password__content__current-password-entry button:not(:disabled):hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.password__content__buttons button:disabled,
.password__content__current-password-entry button:disabled {
	background: var(--background-button-resolve-no-transparency);
	color: var(--text-disabled-no-transparency);
}

.password__content__input {
	color: var(--background-text-region-dark);
	border: none;
	height: 2.9em;
	padding: 0 1em;
	width: 100%;
	padding-right: 14em;
	position: absolute;
	left: 0;
	border-bottom: .3em solid var(--transparent-dark-20);
	box-shadow: none;
}

.password__content__input.ng-invalid:not(:focus) {
	border-bottom-color: var(--border-input-invalid);
	background: var(--background-input-invalid);
}

.password__content__tooltip {
	display: block;
	line-height: 130%;
	margin: .05em 0 0;
	border-top: .15em solid var(--background-body);
	padding: 1em 20% 1em 0;
}

.password__content__tooltip.change-open {
	margin-top: 3.38em;
}

@-moz-document url-prefix() { /* firefox hack */
	.password__content__tooltip.change-open {
		margin-top: 3.39em;
	}
}

.password__content__current-password-entry {
	position: absolute;
    top: 0;
    left: 0;
    background: var(--background-text-region-dark);
    bottom: 0;
    right: 0;
    z-index: 40;
    padding: 4em;
}

.password__content__current-password-entry form {
	max-width: 40em;
	margin: 0 auto;
}

.password__content__current-password-entry button {
	display: inline-block;
	float: none;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 8em;
	font-size: 1.4em;
	height: auto;
}

.password__content__current-password-entry__header {
	font-size: 1.4em !important;
	text-align: center;
	white-space: normal;
	padding-bottom: .6em;
	margin: 0;
}

.password__content__current-password-entry__inputs {
	position: relative;
	margin: .5em 0 !important;
}

.password__content__current-password-entry input[type="password"] {
	font-size: 1.4em;
	color: var(--background-text-region-dark);
	border: none;
	padding: 0 .6em;
	width: 100%;
	margin: 0 auto !important;
	display: inline-block;
	text-align: left;
	padding-right: 10em;
	border-bottom: .35em solid var(--transparent-dark-20);
}

.password__content__current-password-entry .row,
.password__content__current-password-entry__inputs {
	border-bottom: none;
}

.password__content__current-password-entry__return {
	text-align: center !important;
	font-size: 1.25em !important;
	margin-top: .5em !important;
}

@media (max-width: 991px) {
	.password__content__buttons.open {
		position: relative;
		margin-top: 1.45em;
		width: auto;
		top: 1.35em;
		margin-bottom: -1.75em;
	}

	.password__content__buttons.open button:not(:only-of-type) {
		width: 50%;
	}

	.password__content__buttons.open button:only-of-type {
		width: 100%;
	}

	.password__content__input {
		width: 100%;
		max-width: 100%;
		padding-right: 1em;
	}

	.password__content__tooltip {
		padding-right: 2em;
	}

	.password__content__current-password-entry button {
		width: 3em;
	}

	.password__content__current-password-entry input[type="password"] {
		padding-right: 4em;
	}
}