.video {
	position: absolute;
	z-index: 500;
	bottom: 1em;
	right: 1em;
	width: 50em;
	height: 28.6em;
	background: var(--background-video);
	border-radius: .2em;
	padding: .5em;
	box-shadow: 0 0 1em var(--text-dark);
}

.video.autoplay-unsupported {
	right: auto;
	left: 1em;
}

.video video {
	width: 100%;
	height: 100%;
	image-rendering: auto;
	/* żeby ukryło spinner jeśli <video> się pokaże */
	position: relative;
}

/* -------------------------------------------------- */

/* element dodawany dynamicznie przez dyrektywę jako sibling video */
.video .drag-handle {
	bottom: 4.5em;
	left: .5em;
	background: transparent !important;
	cursor: move;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	width: 45em !important;
	height: 23.6em !important;
}

.video .drag-handle:after {
	/* część wystająca za kontener, żeby dało się kliknąć chromecast */
	content: '';
	position: absolute;
	bottom: -4em;
	left: 4em;
	height: 4em;
	right: 0;
	display: block;
}

.video .drag-handle:active {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}

.video__ui,
.video .drag-handle {
	opacity: 0;
	transition: opacity .4s;
}

.video.fullscreen {
	padding: 0;
}

.video.fullscreen .video__ui,
.video.fullscreen .video__full-epg {
	font-size: 115%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
@media only screen and (-moz-min-device-pixel-ratio: 2),
@media only screen and (-o-min-device-pixel-ratio: 2),
@media only screen and (min-device-pixel-ratio: 2),
@media only screen and (min-resolution: 2dppx) {
	.video.fullscreen .video__channels {
		font-size: 115%;
	}
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.video.fullscreen {
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
}

.video.fullscreen .drag-handle,
.video.quality-selector-open .drag-handle,
.video.autoplay-unsupported .drag-handle {
	display: none;
}

/* -------------------------------------------------- */

.video__manual-play {
	color: var(--text-generic);
	width: 80%;
	position: absolute;
	top: 75%;
	left: 50%;
	z-index: 500;
	font-size: 1.6em;
	text-align: center;
	transform: translateX(-50%) translateY(-50%);
}

/* przejście nie dochodzi do końca jeśli się za szybko zjedzie myszą, dlatego JSem */
.video.hover .video__ui,
.video.hover .drag-handle {
	opacity: .9;
}

.video__ui > div,
.video .drag-handle,
.video__ui__volume-bar__icon,
.video__ui__volume-bar div,
.video__setting,
.video__quality__selector {
	position: absolute;
}

.video__ui > div:not(.video__setting),
.video .drag-handle,
.video__ui__volume-bar__icon,
.video__quality__selector li,
.video__quality__selector1 li,
.audio__track__selector li,
.video__ui__channel-list-toggle,
.video__channels__close {
	width: 4em;
	height: 4em;
	background-color: var(--background-various-light);
	background-position: center;
	background-repeat: no-repeat;
}

.video__ui-minimal {
	position: absolute;
	top: 0;
	left: 50%;
	height: 5em;
	width: 7em;
	margin-left: -3.5em;
	opacity: 1;
}

.video__ui-minimal .video__ui__close {
	left: 0;
	background-image: none;
	color: var(--text-generic);
	text-align: center;
	line-height: 3em;
	width: 100%;
	font-size: 1.3em;
	height: 3em;
}

.video__ui__close:hover,
.video__ui__fullscreen:hover,
.video__ui__chromecast:hover,
.video__ui__volume-bar__icon:hover,
.video.changing-volume .video__ui__volume-bar__slider,
.video__ui__quality:hover,
.video__quality__selector1 li:hover,
.audio__track__selector li:hover,
.video__ui__channel-list-toggle:hover,
.video__channels__close:hover {
	background-color: var(--background-various-light) !important;
}

.video__ui__volume-bar__range-cover:hover + .video__ui__volume-bar__range .video__ui__volume-bar__slider {
	background: var(--highlight) !important;
}

.video__ui__close {
	top: .5em;
	right: .5em;
	background-image: url('../images/player/exit.svg');
	background-size: 44%;
	cursor: pointer;
}

.video__ui__fullscreen {
	bottom: .5em;
	right: .5em;
	background-image: url('../images/player/fullscreen.svg');
	background-size: 50%;
	cursor: pointer;
}

.video__ui__chromecast {
	bottom: .5em;
	left: .5em;
	background-image: url('../images/player/cast.svg');
	background-size: 56%;
	cursor: pointer;
}

.video.fullscreen .video__ui__fullscreen {
	background-image: url('../images/player/fullscreen-exit.svg');
}

.video__ui__volume-bar {
	top: 50%;
	right: .5em;
	height: 15.6em !important;
	margin-top: -9.8em;
}

.video__ui__volume-bar__track {
	top: 0;
	left: 0;
	bottom: 4em;
	right: 0;
	background: var(--background-various-light);
}

.video__ui__volume-bar__range,
.video__ui__volume-bar__range-cover {
	top: 1em;
	bottom: 1em;
	width: 2em;
	left: 1em;
}

/* rynna do slidera, fake */
.video__ui__volume-bar__range {
	background: var(--background-body);
	z-index: 501;
}

.video__ui__volume-bar__range-mute-placeholder {
	background: repeating-linear-gradient(-45deg, var(--transparent-mid-50), var(--transparent-mid-50) .5em, transparent .5em, transparent .93em);
	top: 0;
	left: 0;
	width: 100%;
	display: none;
}

.video.muted .video__ui__volume-bar__range-mute-placeholder {
	display: block;
}

.video.muted .video__ui__volume-bar__range {
	background: transparent;
}

/* rzeczywisty element który user klika nad rynną, bo wypełnienie staje się
/* event.target-em jeśli mysz najedzie na wypełnienie a nie rynnę. Taki mały hack. */
.video__ui__volume-bar__range-cover {
	cursor: pointer;
	z-index: 502;
}

.video__ui__volume-bar__slider {
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
	background: var(--background-logo);
}

.video.muted .video__ui__volume-bar__slider  {
	/* 135deg żeby był w odwrotnym kierunku niż w rynnie, przez co jest złudzenie ze to */
	/* ciągły gradient (paski z rynny są równo z paskami z suwaka) */
	background: repeating-linear-gradient(135deg, rvar(--transparent-light-60), rvar(--transparent-light-60) .5em, transparent .5em, transparent .93em);
}

.video__ui__volume-bar__icon {
	background-image: url('../images/player/volume.svg');
	background-size: 50%;
	cursor: pointer;
	bottom: 0;
}

.video.muted .video__ui__volume-bar__icon {
	background-image: url('../images/player/volume-mute.svg');
}

.video.fullscreen .video__ui__volume-bar {
	margin-top: -11.1em;
	height: 17.9em !important;
}

/* -------------------------------------------------- */

.video__right-click-overlay {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}

.video.fullscreen:not(.hover) {
	cursor: none;
}

.video__error-message {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.video__error-message span {
	height: 2em;
	line-height: 2em;
	position: absolute;
	top: 50%;
	margin-top: -1em;
	color: var(--text-generic);
	font-size: 1.2em;
	text-align: center;
	width: 100%;
	display: block;
}

.video__error-message.has-details span {
	margin-top: -2em;
}

.video__error-message span:last-of-type {
	margin-top: -.6em;
	font-size: .8em;
	opacity: .5;
}

.video__error-message span:last-of-type:not(:empty):before {
	content: '(';
}

.video__error-message span:last-of-type:not(:empty):after {
	content: ')';
}

.video__ui__channel-name {
	top: .5em;
	left: .5em;
	width: auto !important;
	padding: 0 1.6em;
	background-color: var(--transparent-dark-50) !important;
	background-size: 9em;
	background-position: 1em 50% !important;
}

.video__ui__channel-name > span {
	display: block;
	color: var(--background-logo);
	height: 1.65em;
	line-height: 2em;
	font-size: 1.2em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 25em;
}

.video__ui__channel-name > span:first-of-type {
	font-weight: bold;
}

.video__ui__channel-name > span:last-of-type {
	margin-top: -.4em;
}

.video__ui__channel-name > span span {
	font-size: .95em;
}

.video.fullscreen .video__ui__channel-name {
	left: 5em;
}

/*.video.fullscreen .video__ui__channel-name {
	background-image: url(../images/logojambox-white.svg);
	padding-left: 0;
}

.video.fullscreen .video__ui__channel-name > span {
	margin-left: 9.7em;
}*/

.video__ui__quality {
	position: absolute;
	background-size: 56%;
	top: 20.1em;
	right: .5em;
	cursor: pointer;
}

.video.fullscreen .video__ui__quality {
	top: 50%;
	margin-top: 6.8em;
}

.video__ui__quality.has-error:before,
.video__ui__quality.has-error:after,
.video__ui__quality.has-error span {
	display: none !important;
}

.video__ui__quality.has-error {
	background-image: none !important;
}

.video__ui__quality.has-error:hover {
	background-color: var(--background-various-light) !important;
	cursor: not-allowed;
}

/* -------------------------------------------------- */

.video.quality-selector-open .video__ui__quality {
	background-color: var(--background-button-generic-focused) !important;
}

.video__setting {
	position: absolute;
	width: 18em;
	right: 4.5em;
	top: 50%;
	padding: 0;
	margin: 0;
	background-color: var(--background-button-generic);
	background-position: center;
	background-repeat: no-repeat;
}

.video__setting ul {
	display: inline-block;
	margin-left: -2em;
	height: 100%;
}

.vertical {
	display: inline-block;
	margin-left: 0;
	border-left: 1px solid var(--background-various-light);
	height: 290px;
  }

.video__setting ul > li {
	list-style: none;
	width: 7em;
	background-color: var(--background-button-generic);
	cursor: pointer;
	padding: 0;
	margin: 0;
}

.video__setting ul[title]::before {
	content: attr(title);
	/* then add some nice styling as needed, eg: */
	display: block;
	font-weight: bold;
	font-size: 1.3em;
	/* padding: 4px; */
	color: var(--background-logo);
	height: 2em;
	line-height: 2.5em;
	margin: 0 1em;
  }

.video__quality__selector {
	position: absolute;
	width: 7em;
	right: 4.5em;
	top: 50%;
	padding: 0;
	margin: 0;
}

.video__quality__selector.quality-count-1,
.video__quality__selector.quality-count-2,
.audio__track__selector.quality-count-1,
.audio__track__selector.quality-count-2 {
	margin-top: 5.8em;
}

.video__quality__selector.quality-count-3 {
	margin-top: 1.8em;
}

.video__quality__selector.quality-count-4 {
	margin-top: -2.2em;
}

.video__quality__selector1.quality-count-5 {
	margin-top: -6.2em;
}

.video__quality__selector.quality-count-6 {
	margin-top: -10.2em;
}

.video__quality__selector.quality-count-7 {
	margin-top: -14.2em;
}

.video__quality__selector.quality-count-8 {
	margin-top: -18.2em;
}

.video__quality__selector.quality-count-9 {
	margin-top: -22.2em;
}

.video__quality__selector.quality-count-10 {
	margin-top: -26.2em;
}

.video.fullscreen .video__quality__selector.quality-count-1,
.video.fullscreen .video__quality__selector.quality-count-2 {
	margin-top: 6.8em;
}

.video.fullscreen .video__quality__selector.quality-count-3 {
	margin-top: 2.8em;
}

.video.fullscreen .video__quality__selector.quality-count-4 {
	margin-top: -1.2em;
}

.video.fullscreen .video__quality__selector.quality-count-5 {
	margin-top: -5.2em;
}

.video.fullscreen .video__quality__selector.quality-count-6 {
	margin-top: -9.2em;
}

.video.fullscreen .video__quality__selector.quality-count-7 {
	margin-top: -13.2em;
}

.video.fullscreen .video__quality__selector.quality-count-8 {
	margin-top: -17.2em;
}

.video.fullscreen .video__quality__selector.quality-count-9 {
	margin-top: -21.2em;
}

.video.fullscreen .video__quality__selector.quality-count-10 {
	margin-top: -25.2em;
}

.video__quality__selector li {
	width: 100%;
	list-style: none;
	height: 4em;
	background-color: var(--background-button-generic);
	cursor: pointer;
}

/* .video__quality__selector li:hover span,
.video__quality__selector1 li:hover span,
.audio__track__selector li:hover span {
	color: var(--text-neutral);
} */

.video__quality__selector li:last-of-type,
.video__quality__selector1 li:last-of-type,
.audio__track__selector li:last-of-type {
	border-bottom: none;
}

.video__quality__selector span,
.video__quality__selector1 span,
.audio__track__selector span {
	display: block;
	color: var(--background-logo);
	height: 1.6em;
	line-height: 2.5em;
	margin: 0 1em;
}

.video__quality__selector span:first-of-type,
.video__quality__selector1 span:first-of-type,
.audio__track__selector span:first-of-type {
	font-weight: bold;
}

.video__quality__selector span:last-of-type,
.video__quality__selector1 span:last-of-type,
.audio__track__selector span:last-of-type {
	font-size: .9em;
}

.video.quality-auto .video__ui__quality {
	background-image: none;
}

.video.quality-auto .video__ui__quality:after {
	content: '';
	position: absolute;
	display: block;
	top: .7em;
	left: .7em;
	width: 2.6em;
	height: 2.6em;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-image: url('../images/player/quality.svg');
	background-size: 96%;
	animation: 2s spin linear infinite;
}

.video.quality-low .video__ui__quality {
	background-image: url('../images/player/quality-low.svg');
}

.video.quality-medium .video__ui__quality {
	background-image: url('../images/player/quality-medium.svg');
}

.video.quality-high .video__ui__quality {
	background-image: url('../images/player/quality-high.svg');
}

.video.quality-premium .video__ui__quality {
	background-image: url('../images/player/quality-premium.svg');
}

.video.quality-low .video__ui__quality span,
.video.quality-medium .video__ui__quality span,
.video.quality-high .video__ui__quality span,
.video.quality-premium .video__ui__quality span {
	display: none;
}

.video__ui__quality span {
	position: absolute;
	color: var(--background-logo);
	font-size: 1.15em;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 1.2em;
	font-weight: bold;
	padding: .6em 0;
}

/* -------------------------------------------------- */

.video__loading {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	animation: 1s spin linear infinite;
}

.video__loading:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 5em;
	height: 5em;
	border: .5em solid var(--background-logo);
	border-radius: 10em;
	transform: translateX(-2.5em) translateY(-2.5em);
}

.video__loading:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 2.5em;
	height: 2.5em;
	background: var(--background-video);
}

/* -------------------------------------------------- */

.video__channels {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--transparent-mid-90);
	text-align: center;
	display: none;
}

.video__channels__inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 5em;
	overflow-y: scroll;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
	cursor: default;
}

.video__channels__channel.not-entitled {
	opacity: .25;
}

.video.fullscreen .video__channels {
	display: block;
}

.video__channels img {
	width: 83px;
	height: 53px;
}

.video__channels__channel {
	display: block;
	min-width: 9em;
	max-width: 9em;
	min-height: 6.5em;
	max-height: 6.5em;
	background: var(--background-logo);
	margin: .2em;
	line-height: 6.3em;
	text-align: center;
	opacity: .92;
	cursor: pointer;
	border-radius: .15em;
	position: relative;
}

.video__channels__channel.not-entitled {
	cursor: not-allowed;
}

.video__channels__channel:not(.not-entitled):hover:after {
	position: absolute;
	top: -.1em;
	left: -.1em;
	right: -.1em;
	bottom: -.1em;
	border: .35em solid var(--highlight);
	content: '';
	display: block;
	border-radius: .15em;
	z-index: 100;
}

.video div.flex-grow-prevent {
	height: 0;
	flex-grow: 1;
	min-width: 9em;
	max-width: 9em;
	margin: 0 .2em;
}

/* -------------------------------------------------- */

.video__channels__close {
	position: absolute;
	top: .5em;
	left: .5em;
	border: none;
	background-image: url(../images/cross.svg);
	background-size: 50% 50%;
	z-index: 20;
	font-size: 115%;
}

.video__channels__close span,
.video__ui__channel-list-toggle span {
	display: none;
}

.video__ui__channel-list-toggle {
	position: absolute;
	top: .5em;
	left: .5em;
	border: none;
	background-image: url(../images/gridmenu.svg);
	background-size: 50% 50%;
	display: none;
}

.video.fullscreen .video__ui__channel-list-toggle {
	display: block;
}

/* -------------------------------------------------- */

.video__full-epg {
	opacity: 0;
	display: none;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 5em;
	background: linear-gradient(180deg, transparent 11%, var(--transparent-dark-60) 11%, var(--transparent-dark-60) 100%);
	color: var(--text-generic);
	overflow-x: scroll;
	overflow-y: visible;
	white-space: nowrap;
	-webkit-mask-image: linear-gradient(90deg, var(--mask-transparency) 0%, var(--mask-transparency) 90%, transparent 100%);
	mask-image: linear-gradient(90deg, var(--mask-transparency) 0%, var(--mask-transparency) 90%, transparent 100%);
	transition: opacity .4s, margin-right .4s;
	cursor: grab;
}

.video.fullscreen .video__full-epg {
	display: block;
}

.video.fullscreen.hover .video__full-epg {
	opacity: 1;
}

.video__full-epg > div {
	height: 6.4em;
	display: inline-block;
	padding: 1em;
	padding-right: 2em;
	position: relative;
	border-right: .2em solid var(--highlight);
	min-width: 12em;
	max-width: 80vw;
}

.video__full-epg__name,
.video__full-epg__description {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.video__full-epg__name {
	font-size: 1.2em;
	margin: .6em 0 .3em;
}

.video__full-epg__description {
	font-size: 1.1em;
	opacity: .8;
}

.video__full-epg__end {
	font-size: 1.1em;
	position: absolute;
	top: 0;
	right: 0;
	width: 3.2em;
	height: 1.5em;
	line-height: 1.5em;
	text-align: center;
	margin-right: -1.6em;
	background: var(--highlight);
	color: var(--background-video);
	font-weight: bold;
	border-radius: .1em;
}

body.watch-standalone .video.fullscreen .video__full-epg__name {
	padding-top: .3em;
}

body.watch-standalone .video.fullscreen .video__full-epg__description {
	font-size: 1em;
}

/* -------------------------------------------------- */

@-moz-keyframes spin {
	from { -moz-transform: rotate(0deg); }
	to { -moz-transform: rotate(360deg); }
}

@-webkit-keyframes spin {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
	body.video-visible .container-fluid {
		position: absolute;
		top: 20rem;
		bottom: 0;
		right: 0;
		left: 0;
		height: calc(100% - 20rem);
	}

	body.video-visible .epg__details__pane {
		z-index: 600 !important;
		top: 20rem !important;
		position: fixed;
	}

	body.video-visible .video,
	body.video-visible  .video.fullscreen {
		position: absolute !important;
		z-index: 500 !important;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		width: 100% !important;
		height: 20rem !important;
		border-radius: 0 !important;
		padding: 0 !important;
		box-shadow: none !important;
	}

	body.video-visible .video.fullscreen {
		height: 100% !important;
	}

	body.video-visible .video .video__button.drag-handle {
		display: none !important;
	}

	body.video-visible .video__ui__fullscreen {
		right: 0 !important;
		bottom: 0 !important;
	}

	body.video-visible .video__ui__chromecast {
		left: 0 !important;
		bottom: 0 !important;
	}

	body.video-visible .video__ui__close {
		top: 0 !important;
		right: 0 !important;
	}

	body.video-visible .video__ui__channel-list-toggle,
	body.video-visible .video__channels__close {
		top: 0 !important;
		left: 0 !important;
	}

	body.video-visible .video__ui__volume-bar {
		display: none !important;
	}

	body.video-visible .video__ui__quality {
		bottom: 4em !important;
		right: 0 !important;
		top: auto !important;
	}

	body.video-visible .video__ui__channel-name {
		top: 0 !important;
		left: 0 !important;
		right: 4em !important;
	}

	body.video-visible .video.fullscreen .video__ui__channel-name {
		top: 0 !important;
		left: 4em !important;
		right: 4em !important;
	}

	body.video-visible .video__quality__selector,
	body.video-visible .video__quality__selector1,
	body.video-visible .audio__track__selector {
		right: 4em !important;
		bottom: 0 !important;
		top: auto !important;
	}

	body.video-visible .video__ui__close:hover,
	body.video-visible .video__ui__fullscreen:hover,
	body.video-visible .video__ui__quality:hover,
	body.video-visible .video__quality__selector li:hover,
	body.video-visible .video__quality__selector1 li:hover,
	body.video-visible .video__quality__selector li:hover {
		background-color: var(--background-various-light) !important;
	}

	.video__full-epg__name {
		margin: .55em 0 .2em;
	}

	.video__full-epg.collision-hidden {
		opacity: 0 !important;
	}
}

@media (max-width: 768px) and (orientation: portrait) {
	body.video-visible .video.fullscreen .video__ui__volume-bar:nth-of-type(n) {
		bottom: 0 !important;
		top: auto !important;
		height: 21em !important;
	}
}