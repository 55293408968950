.ordercaptcha {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
	z-index: 20;
}

.ordercaptcha__wrapper__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	color: var(--background-text-region-dark);
}

.ordercaptcha__wrapper__buttons {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.ordercaptcha__wrapper__buttons button {
	padding: 0;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	text-align: center;
	text-transform: uppercase;
	font-size: 1.3em;
	height: 3.25em;
	line-height: 3.25em;
	color: var(--text-generic);
}

.ordercaptcha__wrapper__buttons button:not(:disabled):hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.ordercaptcha__wrapper__buttons button:disabled {
	opacity: .5;
}

.ordercaptcha__wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
	z-index: 102;
	padding: 0 !important;
}

.ordercaptcha__wrapper__scroll-wrapper {
	overflow: hidden;
	position: absolute;
	top: 3.84em;
	bottom: 4.2em;
	right: 0;
	left: 0;
}

.ordercaptcha__wrapper__summary .row {
	margin: 0 2em !important;
	padding: .4em 0 !important;
	font-size: 1.2em;
}

.ordercaptcha__wrapper__summary .row:not(:last-of-type) {
	border-bottom: .15em solid var(--background-body);
}

.ordercaptcha__wrapper__summary .row > div:first-of-type {
	text-align: right;
	padding: .3em 1em !important;
}

.ordercaptcha__wrapper__summary .row > div:last-of-type {
	text-align: left;
	padding: .3em 1em !important;
}

.ordercaptcha__wrapper__captcha-entry,
.ordercaptcha__wrapper__in-progress {
	border: .3em solid var(--highlight);
	margin: 1em 3em 3em;
	border-radius: .3em;
	padding: 1.5em 2em 2em;
}

.ordercaptcha__wrapper__captcha-entry__header {
	font-size: 1.4em;
	text-align: center;
}

.ordercaptcha__wrapper__captcha-entry input[type="password"] {
	font-size: 1.4em;
	color: var(--background-text-region-dark);
	border: none;
	padding: .6em 1em;
	width: 10em;
	margin: 1em auto;
	display: block;
	text-align: center;
	border-bottom: .3em solid var(--transparent-dark-20);
}

.ordercaptcha__wrapper__captcha-entry label {
	font-weight: normal;
	cursor: pointer;
	padding-left: 1em !important;
	font-size: 1em;
}

.ordercaptcha__wrapper__captcha-entry__checkbox {
	position: relative;
	height: 2.8em;
	top: .2em;
}

.ordercaptcha__wrapper__captcha-entry__checkbox input {
	z-index: 80;
	opacity: 0;
	margin: 0;
}

.ordercaptcha__wrapper__captcha-entry__checkbox__state-control,
.ordercaptcha__wrapper__captcha-entry__checkbox input {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 2.5em;
	height: 2.5em;
	background-size: contain;
	background-image: url('../images/checkbox-blank.svg');
	transform: translateX(-50%) translateY(-50%);
	cursor: pointer;
}

.ordercaptcha__wrapper__captcha-entry__checkbox__state-control.accepted {
	background-image: url('../images/checkbox-filled.svg');
}

.ordercaptcha__wrapper__captcha-entry > .row:last-of-type {
	max-width: 50em;
	margin: 0 auto !important;
}

.ordercaptcha__wrapper__in-progress {
	text-align: center;
	padding-bottom: 1.6em;
}

.ordercaptcha__wrapper__in-progress span {
	font-size: 1.6em;
	color: var(--text-generic);
	text-align: center;
}

@media (max-width: 991px) {
	.ordercaptcha__wrapper__summary span {
		display: none;
	}
}