.epg__grid {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background: var(--background-text-region-dark);
}

.epg__grid .ss-track {
	margin: 4em 0;
	height: calc(100% - 8em);
}

.epg__grid.has-sidebar .ss-track {
	right: 41.65%;
	margin-bottom: 0;
	height: calc(100% - 4em);
}

.epg__grid .row {
	height: 7em;
	position: relative;
	width: 3608em;
	max-width: 3608em;
}

.epg__grid .row:first-of-type {
	margin-top: 3.84em;
}

.epg__grid .row > div,
.epg__grid__row-programs > div {
	display: inline-block;
	height: 7em;
}

.epg__grid__row-programs.not-entitled .epg__grid__row-programs__inner {
	opacity: .4;
}

.epg__grid__row-programs__inner.brick-past {
	opacity: .2 !important;
}

.epg__grid__row-programs.no-data {
	background-image: repeating-linear-gradient(-45deg, transparent, transparent .5em, var(--transparent-mid-05) .5em, var(--transparent-mid-05) 1em);
}

.epg__grid-timer > div {
	display: inline-block;
	height: 100%;
}

.epg__grid__row-logo,
.epg__grid-timer__row-logo {
	float: left;
	position: -webkit-sticky !important;
	position: sticky !important;
	top: 0;
	color: var(--text-generic);
	background: var(--background-logo);
	z-index: 40;
}

.epg__grid__row-logo {
	border-bottom: .2em solid var(--border-light);
}

.epg__grid-timer__row-logo {
	background: var(--background-various-light);
	z-index: 50;
	line-height: 3.84em;
	text-align: center;
}

.epg__grid__row-logo img,
.epg__grid__row-logo span {
	transition: .3s transform;
}

.epg__grid__row-logo img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: block;
}

.epg__grid__row-logo span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 2.3em;
	height: 2.3em;
	font-size: 1.2em;
	color: var(--text-generic);
	background: var(--background-text-region-dark);
	font-weight: bold;
	text-align: center;
	line-height: 2.3em;
	display: block;
	border-radius: 2em;
}

.epg__grid-timer__row-logo span {
	font-size: 1.2em;
}

.epg__grid__row-logo__features {
	position: absolute;
	bottom: 0;
	right: 0;
}

.epg__grid__row-logo.feature-ott {
	cursor: pointer;
}

.epg__grid__row-logo.feature-ott:before,
.epg__grid__row-logo.feature-jpvr:after {
	display: block;
	position: absolute;
	bottom: .3em;
	content: '';
	width: .7em;
	height: .7em;
	background-repeat: no-repeat;
	background-size: 0;
	background-position: center;
	border-radius: 1em;
	transition: .3s background-size, .3s width, .3s height, .3s right;
	z-index: 10;
}

.epg__grid__row-logo.feature-jpvr:after {
	right: .3em;
	background-color: var(--background-reject);
	background-image: url('../images/feature-record.svg');
}

.epg__grid__row-logo.feature-ott:before,
.epg__grid__row-logo.feature-ott.feature-jpvr:before {
	background-color: var(--background-ott);
	background-image: url('../images/feature-ott.svg');
}

.epg__grid__row-logo.feature-ott:hover:before,
.epg__grid__row-logo.feature-ott.feature-jpvr:hover:before,
.epg__grid__row-logo.feature-jpvr:hover:after {
	background-size: 100%;
	width: 1.5em;
	height: 1.5em;
}

.epg__grid__row-logo.feature-ott:not(.feature-jpvr):before {
	right: .3em;
}

.epg__grid__row-logo.feature-ott:before {
	right: 1.2em;
	transition: .3s background-size, .3s width, .3s height, .3s right, .3s border-radius, .3s bottom;
}

.epg__grid__row-logo.feature-ott:hover:before {
	right: 1.5em;
	bottom: 1em;
	width: 5em !important;
	height: 5em !important;
	border-radius: 5em;
}

.epg__grid__row-logo.feature-ott img {
	transition: .3s opacity;
}

.epg__grid__row-logo.feature-ott:hover img {
	opacity: 0;
}

.epg__grid__row-logo.feature-ott .epg__grid__row-logo__play-button {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.epg__grid__row-logo.feature-ott:hover .epg__grid__row-logo__play-button {
	display: block !important;
	z-index: 20;
}

.epg__grid-timer__time-scale {
	position: relative;
}

.epg__grid-timer__time-scale__section {
	position: absolute;
	top: 0;
	line-height: 3.84em;
	overflow: hidden;
	white-space: nowrap;
}

.epg__grid-timer__time-scale__section span {
	font-size: 1.2em;
	margin-left: 1em;
}

.epg__grid-timer {
	background: var(--background-title-light);
	width: 3608em;
	height: 3.84em;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 60;
}

.epg__grid__row-programs {
	width: 3600em;
	max-width: 3600em;
	white-space: nowrap;
	border-bottom: .2em solid var(--border-neutral);
	background: var(--background-text-region-dark);
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}

.epg__grid__row-programs:active {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}

.epg__grid__row-programs > div {
	text-overflow: ellipsis;
	white-space: nowrap;
	z-index: 10;
	position: absolute;
	border-right: .2em solid var(--border-neutral);
	color: var(--text-generic);
}

.epg__grid__row-programs > div:first-of-type:after {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	left: -.15em;
	bottom: 0;
	border-left: .2em solid var(--border-neutral);
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.epg__grid__row-programs > div:first-of-type:after {
		left: -.22em;
		border-left-width: .24em;
	}
}

.epg__grid__row-programs > div:hover:before {
	position: absolute;
	top: -.2em;
	left: -.2em;
	right: -.2em;
	bottom: -.15em;
	border: .4em solid var(--highlight);
	content: '';
	display: block;
	border-radius: .15em;
	z-index: 100;
}

.epg__grid__row-programs > div:hover {
	z-index: 100;
}

.epg__grid__row-programs__current-marker {
	position: absolute;
	top: -.1em;
	left: 0;
	bottom: 0;
	background-color: var(--transparent-light-05);
}

.epg__grid__row-programs > div:hover .epg__grid__row-programs__current-marker {
	border-top: none;
}

.epg__grid__row-programs:hover {
	z-index: 20;
}

.epg__grid__row-programs__inner {
	position: relative;
	width: auto;
	display: inline-block;
	max-width: 100%;
	cursor: default;
}

.epg__grid__row-programs__name {
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
	font-size: 1.4em;
	margin: 0 1em .5em;
	line-height: 3.1em;
	z-index: 30;
}

.epg__grid__row-programs__details {
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
	font-size: 1.1em;
	margin: 0 1.2em .5em;
	z-index: 30;
	margin-top: -1em;
	color: var(--transparent-light-70);
}

.epg__grid__row-logo,
.epg__grid-timer__row-logo {
	width: 8em;
	left: 0;
}

.epg__grid-timer__current {
	position: absolute;
	top: 0;
	margin-left: 8em;
	width: 3600em;
	z-index: 25;
}

.epg__grid-timer__current div {
	background: var(--highlight);
	width: .3em;
	position: absolute;
	top: 3.84em;
}

.epg__grid-timer__current div:before {
	content: '';
	position: sticky;
	position: -webkit-sticky;
	top: 3.84em;
	margin-left: -.35em;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: .5em .5em 0 .5em;
	border-color: var(--highlight) transparent transparent transparent;
	z-index: 25;
	display: block;
}

@media (max-width: 1440px) {

	.epg__grid .row {
		width: 3008em;
		max-width: 3008em;
	}

	.epg__grid-timer {
		width: 3008em;
	}

	.epg__grid__row-programs {
		width: 3000em;
		max-width: 3000em;
	}

	.epg__grid-timer__current {
		width: 3000em;
	}
}

@media (max-width: 600px) {

	.epg__grid .row {
		width: 1508em;
		max-width: 1508em;
	}

	.epg__grid-timer {
		width: 1508em;
	}

	.epg__grid__row-programs {
		width: 1500em;
		max-width: 1500em;
	}

	.epg__grid-timer__current {
		width: 1500em;
	}
}

/* ----------------------------------------- */
/* ------------  sticky mode  -------------- */
/* ----------------------------------------- */

@media screen and (-webkit-min-device-pixel-ratio:0) {
	/* firefox sobie jeszcze nie radzi... */
	.epg__grid__row-programs__inner {
		width: 100%;
	}

	.epg__grid__row-programs__name {
		position: sticky;
		position: -webkit-sticky;
		left: 6.5em;
		display: block;
		max-width: calc(100% - 2em);
		float: left;
	}

	.epg__grid__row-programs__details {
		margin-top: -1.35em;
		position: sticky;
		left: 8.3em;
		display: block;
		margin-top: -1em;
		max-width: calc(100% - 2em);
		float: left;
		clear: both;
	}
}

/* ----------------------------------------- */

.epg__details__pane {
	position: absolute;
	top: 3.84em;
	right: 0;
	bottom: 0;
	background: var(--background-body);
	z-index: 110;
	color: var(--text-generic);
	padding: .2em 1.5em 2em !important;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: hidden;
}

.epg__details__pane__synopsis__meta {
	flex: 0 1 auto;
}

.epg__details__pane h1 {
	font-size: 2em;
	background: var(--background-body);
	padding-bottom: .4em;
	position: relative;
	z-index: 60;
	margin-bottom: 0;
}

.epg__details__pane h2 {
	font-size: 1.5em;
	margin-top: -.2em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	background: var(--background-body);
	padding-bottom: 1em;
	position: relative;
	z-index: 60;
}

.epg__details__pane__buttons,
.epg__details__pane__close,
.epg__details__pane__progress {
	margin: 1.5em 0;
	position: relative;
	margin-right: -1em;
	background: var(--background-body);
	z-index: 55;
	flex: 0 1 auto;
	white-space: nowrap;
}

.epg__details__pane__progress {
	margin: 0 0 .35em;
	height: 3.4em;
	min-height: 3.4em;
	font-size: 1.1em;
}

.epg__details__pane__progress:not(.disabled):hover div {
	color: var(--background-text-region-dark);
}

.epg__details__pane__progress:not(.disabled):hover .epg__details__pane__progress__track {
	background: var(--highlight);
}

.epg__details__pane__progress > div {
	position: absolute;
	top: 0;
	bottom: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	left: 0;
	text-align: center;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-10);
	line-height: 2.7em;
	font-size: 1.2em;
}

.epg__details__pane__progress.disabled {
	opacity: .5;
	cursor: not-allowed;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { /* chrome hack */
	.epg__details__pane__progress > div {
		line-height: 2.6em;
	}
}

.epg__details__pane__progress__track {
	right: 0;
	background: var(--background-resolve);
}

.epg__details__pane__progress__meter {
	border: none !important;
	background: repeating-linear-gradient(-45deg, var(--transparent-dark-10), var(--transparent-dark-10) .5em, transparent .5em, transparent .93em);
}

.epg__details__pane__progress__running-background {
	right: 0;
	color: var(--text-generic);
}

.epg__details__pane__progress__running-background span:first-of-type {
	text-transform: uppercase;
}

.epg__details__pane__progress__running-background span:last-of-type {
	font-size: 80%;
	position: relative;
	top: -.05em;
}

.epg__details__pane__buttons input:disabled,
.epg__details__pane__buttons button:disabled {
	opacity: .5;
	cursor: not-allowed;
}

.epg__details__pane__buttons input,
.epg__details__pane__buttons button,
.epg__details__pane__close button {
	text-transform: uppercase;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	padding: .6em 1em !important;
	display: block;
	width: 100%;
	margin-bottom: .3em;
	line-height: 1.4em;
	font-size: 1.3em;
}

.epg__details__pane__buttons {
	margin: -1em 0 1em;
	z-index: 75;
	flex: 0 1 auto;
}

.epg__details__pane__close button {
	background: var(--background-various-light);
	height: 100%;
	font-size: 1.3em;
}

.epg__details__pane__close button:hover,
.epg__details__pane__buttons button:not(:disabled):hover,
.epg__details__pane__buttons input:not(:disabled):hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.epg__details__pane__synopsis {
	right: 0;
	left: 1em;
	overflow-y: scroll;
	overflow-x: hidden;
	flex: 1 1 auto;
	margin-bottom: 3.2em;
}

.epg__details__pane__synopsis ul {
	list-style: none;
	padding-left: .8em;
}

.epg__details__pane__synopsis li:before {
	content: '- ';
	padding-right: .3em;
	position: relative;
	top: -.1em;
}

.epg__details__pane__synopsis h1 {
	opacity: 0;
	margin-right: -.6em;
}

.epg__details__pane p {
	font-size: 1.1em;
	line-height: 1.3em;
	display: block;
}

.epg__details__pane__close {
	position: absolute;
	bottom: -1.5em;
	left: 0;
	right: 1em;
	padding: 0;
	height: 4em;
}

.epg__grid__row-programs__schedule-remind,
.epg__grid__row-programs__schedule-autozap {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 2em 2em 0 0;
	z-index: 10;
}

.epg__grid__row-programs__schedule-remind {
	border-color: var(--background-title-light) transparent transparent transparent;
}

.epg__grid__row-programs__schedule-autozap {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 2em 2em 0 0;
	border-color: var(--autozap) transparent transparent transparent;
}

.epg__grid__row-programs__recording-marker {
	position: absolute;
	top: 0;
	height: 6.9em;
	background: var(--background-tooltip-recording);
}

.epg__grid__row-programs__name__award-star {
	background: url(../images/star.svg) no-repeat left;
	width: 2.6em;
	height: 1.1em;
	display: inline-block;
	line-height: 1.2em;
	top: -.05em;
	position: relative;
	color: var(--highlight);
	text-align: left;
	font-size: 0.9em;
	padding-left: 1.3em;
}

.epg__details .auth-required:after {
	content: '';
	display: inline-block;
	background: url(../images/login.svg) no-repeat;
	background-size: 1em 1em;
	width: 1em;
	height: 1em;
	opacity: .5;
	margin-left: .2em;
	position: relative;
	top: .1em;
}

.epg__details .epg__details__pane__buttons .auth-required:not(:disabled):hover:after {
	filter: invert(100%);
}

.epg__details .epg__details__pane__buttons div.disabled .auth-required:not(:disabled):hover:after {
	filter: none;
}

.epg__details__pane.is-past:after {
	content: 'Zakończony';
	position: absolute;
	top: 6.4em;
	right: -2.2em;
	width: 12em;
	height: 2em;
	line-height: 2em;
	background: var(--background-reject);
	transform: rotate(45deg);
	text-align: center;
	transform-origin: top right;
	z-index: 70;
}

/* ----------------------------------------- */

.epg__quicksearch,
.epg__quicksearch__open {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 105;
}

.epg__quicksearch {
	width: 20em;
}

.epg__quicksearch__open,
.epg__quicksearch__close {
	width: 3.85em;
	height: 3.85em;
	border: none;
	position: absolute;
}

.epg__quicksearch__open {
	color: var(--background-logo);
	background: var(--background-various-light) url(../images/search.svg) no-repeat center;
	background-size: 60% 60%;
}

.epg__quicksearch__open span {
	/* używamy labela jako dodatkowego pseudoelementu... ;) */
	/* brzydki hack no ale mogę mieć tylko jedno :before i :after... */
	width: .4em;
	height: .4em;
	color: transparent;
	display: block;
	position: absolute;
	background: var(--background-text-region-dark);
	bottom: 0;
	left: -.4em;
}

.epg__quicksearch input[type="text"] {
	flex-direction: row;
	height: 3.24em;
	padding: 0 4em 0 1em;
	font-size: 1.2em;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	width: 100%;
	outline: none;
}

.epg__quicksearch__open:hover:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 3.85em;
	height: 3.85em;
	background: transparent url(../images/search.svg) no-repeat center;
	background-size: 60% 60%;
	filter: invert(100%);
}

.epg__quicksearch__open .jambox-chevron:after {
	border-color: transparent var(--background-button-generic) transparent transparent;
}

.epg__quicksearch__open:hover .jambox-chevron:after {
	border-color: transparent var(--highligh-border) transparent transparent;
}

.epg__quicksearch__close {
	top: 0;
	right: 0;
	background: transparent url(../images/cross-black.svg) no-repeat center 44%;
	background-size: 45% 45%;
}

.epg__quicksearch__close span {
	display: none;
}

.epg__quicksearch__list {
	position: absolute;
	overflow: hidden;
	width: 100%;
	font-size: 1.15em;
}

.epg__quicksearch ul {
	position: absolute;
	color: var(--text-generic);
	background: var(--background-various-light);
	padding: 0;
	margin: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: scroll;
}

.epg__quicksearch li {
	position: relative;
	height: 3em;
	list-style: none;
	line-height: 3em;
	padding: 0 1em;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.epg__quicksearch li:not(:first-of-type) {
	margin-top: -.1em;
}

.epg__quicksearch li:hover,
.epg__quicksearch__open:hover {
	background-color: var(--highlight);
	color: var(--text-neutral);
	cursor: pointer;
}

.epg__quicksearch li:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-bottom: .14em solid var(--border-quicksearch-item);
}

.epg__quicksearch li:last-of-type {
	margin-bottom: -.05em;
}

.epg__quicksearch li:hover:after {
	border-bottom-color: var(--highlight) !important;
}

.epg__quicksearch__list__placeholder {
	display: none;
	position: absolute;
	top: 30%;
	width: 100%;
	text-align: center;
	font-size: 1.3em;
	color: var(--text-generic);
}

/* optymalizacja: nie renderuj logosów poza widokiem.
 * Trzeba dodać białą kolumnę w miejsce niewidocznych
 * logosów żeby nie migały przy scrollowanoiu.
 */

.epg__grid__fake-logo-background {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 25;
	height: 100%;
	float: left;
}

.epg__grid__fake-logo-background__strip {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 8em;
	background: var(--background-logo);
	z-index: 25;
}

@media (max-width: 1200px) {
	.epg__grid.has-sidebar .ss-track {
		right: 58.3%;
	}
}

@media (min-width: 561px) {
	.epg__quicksearch li:last-of-type:after {
		display: none;
	}

	.epg__quicksearch li:last-of-type {
		margin-bottom: -.1em;
	}
}

@media (max-width: 561px) {
	.epg__quicksearch {
		left: 0;
		top: 0;
		width: auto;
	}

	.epg__quicksearch ul {
		color: var(--text-generic);
		background: var(--background-category-list);
	}

	.epg__quicksearch:before {
		content: '';
		display: block;
		position: absolute;
		bottom: 3.8em;
		left: 0;
		width: 100%;
		height: 1em;
		background: linear-gradient(to top, var(--transparent-dark-20) 0%, transparent 100%);
	}

	.epg__quicksearch__list {
		top: 0 !important;
		bottom: 0;
		height: auto !important;
	}

	.epg__quicksearch__list ul {
		height: auto !important;
		bottom: 3.3em;
		top: 3.35em;
	}

	.epg__quicksearch input[type="text"] {
		position: absolute;
		bottom: 0;
		z-index: 50;
	}

	.epg__quicksearch li:after {
		border-bottom: .14em solid var(--border-nav-item);
	}

	.epg__quicksearch__close {
		z-index: 50;
		bottom: 0;
		top: auto;
	}

	.epg__quicksearch__list__placeholder {
		display: block;
	}

	.epg__grid-timer__current div:before {
		top: 3.75em;
	}
}


@media only screen and (-webkit-min-device-pixel-ratio: 2),
@media only screen and (-moz-min-device-pixel-ratio: 2),
@media only screen and (-o-min-device-pixel-ratio: 2),
@media only screen and (min-device-pixel-ratio: 2),
@media only screen and (min-resolution: 2dppx) {
	.epg__grid-timer__time-scale__section:before {
		bottom: .05em;
	}
}