.popup {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--transparent-dark-70);
	z-index: 10000;
}

.popup__body {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	transform: translateY(-50%);
	background: var(--text-generic);
	padding: 2em;
	font-size: 1.1em;
	color: var(--background-text-region-dark);
}

.popup__body__message,
.popup__body__buttons {
	width: 70%;
	text-align: center;
	margin: 0 auto;
}

.popup__body__message {
	font-size: 1.2em;
	margin-bottom: 2em;
}

.popup__body__buttons button {
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	height: 3em;
	font-size: 1.2em;
	text-transform: uppercase;
	margin: 0 .5em;
	padding: 0 1em !important;
	background: var(--background-various-light);
	color: var(--text-generic);
	min-width: 8em;
}

.popup__body__buttons button.yes {
	background: var(--background-resolve);
}

.popup__body__buttons button.no {
	background: var(--background-reject);
}

.popup__body__buttons button:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.toast {
	position: fixed;
	left: 50%;
	background: var(--background-tooltip);
	color: var(--text-dark);
	font-size: 1.15em;
	transform: translateX(-50%);
	z-index: 20000;
	box-shadow: .25em .25em 0 0 var(--shadow-popup);
	text-align: center;
	line-height: 1.4em;
	bottom: -100%;
	max-width: 30em;
	padding: 1em 2em;
}

.toast:after,
.toast:before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
}

.toast:after {
	top: 0;
	right: -.25em;
	border-width: .25em 0 0 .25em;
	border-color: transparent transparent transparent var(--shadow-popup);
}

.toast:before {
	bottom: -.25em;
	left: 0;
	border-width: 0 .25em .25em 0;
	border-color: transparent var(--shadow-popup) transparent transparent;
}

.toast.intro {
	bottom: 15%;
	transition: .5s bottom;
}

.toast.outro {
	bottom: 15%;
	opacity: 0;
	transition: .5s opacity;
}

@media (max-width: 991px) {
	.popup__body__buttons, .popup__body__message {
		width: 100%;
	}

	.popup__body__buttons button {
		margin: 0;
		margin-bottom: 1em;
		width: 100%;
	}

	.popup__body__buttons button:last-of-type {
		margin-bottom: 0;
	}

	.toast {
		width: 75%;
		max-width: 75%;
	}

}

@media (max-width: 600px) {
	.toast {
		width: 90%;
		max-width: 90%;
	}
}