.tvorder {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
}

.tvorder__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	color: var(--background-text-region-dark);
}

.tvorder__assets {
	height: 100%;
	overflow: hidden;
}

.tvorder__assets__inner {
	position: absolute;
	top: 3.84em;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	margin: 2em 2em 6.4em;
}

.tvorder__assets__inner .ss-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
	background: var(--background-logo);
	padding-right: 1.2em;
}

.tvorder__assets .ss-scroll {
	background: var(--background-various-light);
}

.tvorder__assets__inner img {
	width: 80px; /* fuj fuj fuj */
	height: 53px; /* fuj fuj fuj */
	display: block;
	margin: 0 auto;
	image-rendering: auto;
}

.tvorder__assets__inner__asset {
	background: var(--background-logo);
	display: inline-block;
	order: 0;
	flex: 1 1 80px; /* fuj fuj fuj */
	align-self: stretch;
	position: relative;
}

.tvorder__assets__inner__asset.flex-grow-prevent {
	height: 0;
	flex-grow: 1;
}

.tvorder__assets__inner__asset.available:after,
.tvorder__order-list .row:after,
.tvorder__assets__inner .selected:after {
	position: absolute;
	top: -.2em;
	left: -.2em;
	right: -.2em;
	bottom: -.2em;
	content: '';
	display: block;
	z-index: 20;
	border-radius: .15em;
	border: .3em solid transparent;
}

.tvorder__order-list .row:after {
	left: 0;
	right: 0;
}

.tvorder__assets__inner__asset.available:hover:after,
.tvorder__order-list .row:hover:after,
.tvorder__assets__inner .selected:after {
	border: .3em solid var(--highlight);
}

.tvorder__assets__inner__asset.available:hover:after,
.tvorder__assets__inner .selected:after {
	top: -.15em;
	left: -.15em;
	right: -.15em;
	bottom: -.15em;
}

.tvorder__order-list .row:hover:after {
	left: 0;
	right: 0;
}

.tvorder__order-list .row:first-of-type:hover:after {
	top: 0;
}

.tvorder__assets__inner img {
	opacity: .2;
}

.tvorder__assets__inner .available img {
	opacity: 1;
}

.tvorder__assets__inner .available {
	cursor: pointer;
}

.tvorder__footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.tvorder__footer__completion {
	background: var(--background-completion);
	padding: 0;
	height: 4.2em;
	white-space: nowrap;
	background-image: repeating-linear-gradient(-45deg, transparent, transparent .5em, var(--transparent-dark-05) .5em, var(--transparent-dark-05) 1em);
}

.tvorder__footer__completion div {
	display: inline-block;
	height: 100%;
	position: relative;
}

.tvorder__footer__completion__percentage {
	background: var(--background-resolve);
	overflow: hidden;
	background-image: repeating-linear-gradient(-45deg, transparent, transparent .5em, var(--transparent-light-05) .5em, var(--transparent-light-05) 1em);
}

.tvorder__footer__completion__percentage span {
	color: var(--background-body);
}

.tvorder__footer__completion span {
	font-size: 1.3em;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	text-transform: uppercase;
	font-weight: 600;
}

.tvorder__footer__completion__label {
	color: var(--background-resolve);
}

.tvorder__footer button {
	padding: 0;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	text-align: center;
	text-transform: uppercase;
	font-size: 1.3em;
	height: 3.25em;
	line-height: 3.25em;
	color: var(--text-generic);
}

.tvorder__footer button {
	width: 100%;
	background: var(--background-various-light);
}

.tvorder__footer button:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.tvorder__order-list .ss-content {
	overflow-x: hidden;
}

.tvorder__order-list > div {
	position: absolute;
	right: 0;
	top: 3.84em;
	background: var(--background-body);
	bottom: 4.2em;
	overflow: hidden;
	z-index: 15;
}

.tvorder__assets__inner .ss-scroll {
	z-index: 15;
}

.tvorder__order-list__inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	bottom: 0;
}

.tvorder__order-list .row {
	padding: .5em 1em !important;
	position: relative;
}

.tvorder__order-list .row:not(:last-of-type) {
	border-bottom: .15em solid var(--border-neutral);
}

.tvorder__order-list h1,
.tvorder__order-list h2 {
	padding: 0 !important;
	margin: .3em !important;
}

.tvorder__order-list h1 {
	font-size: 1.4em;
	position: relative;
	padding-right: 6em !important;
}

.tvorder__order-list h2 {
	font-size: 1.05em;
	padding: .5em 8em 0 0 !important;
	line-height: 1.3em;
}

.tvorder__order-list__button-row {
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
}

.tvorder__order-list h3 {
	position: absolute;
	top: 50%;
	right: 1.5em;
	transform: translateY(-50%);
	width: 5em;
	padding: .7em 0;
	background: var(--highlight);
	color: var(--background-text-region-dark);
	text-align: center;
	font-size: 1.1em;
	font-weight: 600;
	margin: 0;
}

.tvorder__order-list h3.free {
	background: var(--background-free-package);
}

.nophonenumber {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
	z-index: 20;
}