.devicelist {
	position: absolute;
	left: 22em;
	color: var(--text-generic);
	white-space: nowrap;
	width: 25em;
	top: 8em;
	background: var(--background-tooltip);
	max-height: 27em;
	overflow: hidden;
	z-index: 300;
}

@media (max-width: 768px) {
	body.video-visible .devicelist {
		top: 22.3em;
	}
}

.devicelist .device__tile {
	color: var(--background-text-region-dark);
	height: 6.5em;
	overflow: hidden;
	padding-right: 5em;
	cursor: pointer;
}

.devicelist .device__tile:not(:hover):after {
	border-bottom: .2em solid var(--border-list-item);
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.devicelist .device__tile:before {
	border-bottom: .2em solid var(--border-list-item);
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 7em;
	right: auto;
	z-index: 30;
}

.devicelist .device__tile:hover {
	background: var(--highlight);
}

.devicelist .device__tile__image {
	width: 7em;
	height: 6.5em;
}

.devicelist .device__tile__image > div {
	background-size: contain;
	background-position: 50% 40%;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.devicelist .device__tile__details__name {
	width: 14em;
}

.devicelist .device__tile:hover .device__tile__details div {
	color: var(--background-text-region-dark);
}

.devicelist__see-all {
	background: var(--background-various-light);
	text-transform: uppercase;
	height: 3.85em;
	line-height: 3.4em;
	text-align: center;
	display: block;
	border: none;
	border-bottom: .4em solid var(--transparent-dark-20);
	border-top: .2em solid var(--border-list-item);
	width: 100%;
	margin-top: -.2em;
	font-size: 1.15em;
}

.devicelist__see-all:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.device__scrolbar-mask {
	max-height: 23em;
	overflow: hidden;
}

.devicelist .ss-scroll {
	background: var(--background-various-light);
}

.devicelist .device__tile:first-of-type {
	border: none;
}

@media (max-width: 991px) {
	.devicelist {
		left: auto;
		right: 0;
	}
}