.tvservice {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
	color: var(--text-generic);
}

.tvservice__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	color: var(--background-text-region-dark);
}

.tvservice__content {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 0;
}

.tvservice__content__packages {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	padding: 0;
}

.tvservice__content__packages__inner {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 4.2em;
	right: 0;
	overflow: hidden;
}

.tvservice__row {
	height: 5em;
	border-bottom: .15em solid var(--border-neutral);
	margin-left: 2em;
	font-size: 1.2em;
	position: relative;
}

.tvservice__row > div {
	padding: 1em !important;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.tvservice__row__renew {
	text-align: center;
	height: 100%;
}

.tvservice__row__name span {
	padding-left: 4.5em;
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.tvservice__row__name__spot {
	position: absolute;
	top: 50%;
	left: 1.5em;
	transform: translateY(-50%);
	width: 3em;
	height: 3em;
	border-radius: 3em;
	background: var(--background-reject) url('../images/cross.svg') no-repeat center;
	background-size: 50%;
}

.tvservice__assets {
	background: var(--background-body);
	height: 100%;
	border-left: .2em solid var(--border-neutral);
	overflow: hidden;
	position: absolute;
	right: 0;
}

.tvservice__assets__inner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	margin: 2em 0 6.4em;
	padding: 0 2em;
}

.tvservice__assets .ss-scroll {
	margin-left: -4em;
	background: var(--background-various-light);
}

.tvservice__assets__inner .ss-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
	padding-right: 1.2em;
	background: var(--background-logo);
}

.tvservice__row.active .tvservice__row__name__spot {
	background-color: var(--background-resolve);
	background-image: url('../images/tick.svg');
	background-size: 60%;
}

.tvservice__assets__inner img {
	width: 80px; /* fuj fuj fuj */
	height: 53px; /* fuj fuj fuj */
	display: block;
	margin: 0 auto;
	image-rendering: auto;
}

.tvservice__assets__inner__asset {
	background: var(--background-logo);
	display: inline-block;
	order: 0;
	flex: 1 1 80px; /* fuj fuj fuj */
	align-self: stretch;
	position: relative;
}

.tvservice__assets__inner__asset.flex-grow-prevent {
	height: 0;
	flex-grow: 1;
}

.tvservice__assets__inner .unavailable img {
	opacity: .1;
}

.tvservice__assets__order-more,
.tvservice__row button {
	position: absolute;
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	text-align: center;
	padding: 0;
}

.tvservice__assets__order-more span {
	display: none;
}

.tvservice__row button {
	text-transform: uppercase;
}

.tvservice__assets__order-more {
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 3.5em;
	line-height: 3.3em;
	font-size: 1.2em;
	background: var(--background-button-generic);
}

.tvservice__row__renew__inner {
	position: absolute;
	top: 1em;
	left: 1em;
	bottom: 1em;
	right: 2em;
}

.tvservice__row__renew__inner button {
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
}

.tvservice__assets__order-more:hover,
.tvservice__row button:not(:disabled):hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.tvservice__row button:disabled {
	opacity: .5;
}

@media (min-width: 1151px) {
	.tvservice__assets__order-more span:nth-of-type(1) {
		display: block;
	}
}

@media (max-width: 1151px) and (min-width: 991px) {
	.tvservice__assets__order-more span:nth-of-type(2) {
		display: block;
	}
}

@media (max-width: 991px) {
	.tvservice__assets__order-more span:nth-of-type(3) {
		display: block;
	}
}