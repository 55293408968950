.sim-status {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
}

.sim-status .ss-scroll {
	z-index: 200;
}

.sim-status__items {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 4.3em;
	overflow: hidden;
}

.sim-status__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	margin: 0;
	color: var(--background-text-region-dark);
}

.sim-status__header.restricted {
	background: var(--background-reject);
}

.sim-status__header.restricted .jambox-chevron:after {
	border-color: transparent transparent transparent var(--shadow-header-status-reject);
}

.sim-status__items__item {
	min-height: 3.7em;
	padding: .6em 0 !important;
	color: var(--text-generic);
	position: relative;
	border-bottom: .15em solid var(--border-neutral);
	font-size: 1.2em;
	text-align: center;
	line-height: 3.7em;
	width: 100%;
	cursor: default;
}

.sim-status__item__fake-select__container {
	position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}

.sim-status__item__fake-select span {
	position: relative;
	z-index: 150;
	width: 100%;
    height: 100%;
	border: none;
	padding: 0 3em 0 2em;
	box-shadow: none;
	display: flex;
    justify-content: center;
	align-items: center;
	/* potrzebne dla :hover żeby nie chodziło */
	border-bottom: .3em solid transparent;
	font-size: 1.1em;
}

.sim-status__item__fake-select span:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 .6em .6em .6em;
	border-color: transparent transparent var(--text-generic) transparent;
	transition: .3s transform;
	position: absolute;
	top: 50%;
	margin-top: -.25em;
	right: 3em;
	transform: scaleY(-1);
}

.sim-status__item:not(.disabled) .sim-status__item__fake-select:hover span:after {
	border-bottom-color: var(--background-text-region-dark);
}

.sim-status__items__item.options-visible .sim-status__item__fake-select span:after {
	transform: scaleY(1);
	border-bottom-color: var(--background-text-region-dark);
}

.sim-status__item__fake-select {
	height: 100%;
	overflow: hidden;
	position: relative;
}

.sim-status__item:not(.disabled) .sim-status__item__fake-select:hover span,
.sim-status__items__item.options-visible .sim-status__item__fake-select span {
	background: var(--highlight) !important;
	border-bottom-color: var(--transparent-dark-20);
	color: var(--background-text-region-dark);
}

.sim-status__items__item.options-visible .sim-status__item__fake-select {
	overflow: visible;
	z-index: 200;
}

.sim-status__item__fake-select__options {
	position: relative;
	z-index: 50;
	background: var(--background-tooltip);
	color: var(--background-text-region-dark);
}

.sim-status__item__fake-select__options div {
	height: 3.2m;
	line-height: 3.2em;
	border-bottom: .1em solid var(--border-list-item);
	font-size: 1.1em;
}

.sim-status__item__fake-select__options div:hover {
	background: var(--highlight) !important;
	border-bottom: .1em solid transparent;
	cursor: pointer;

}

.sim-status__items__item input.ng-invalid:not(:focus) {
	border-bottom-color: var(--border-input-invalid);
	background: var(--background-input-invalid);
}

.sim-status__items__item input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.sim-status__items__item input[type=number] {
	-moz-appearance: textfield;
}

.sim-status__items__item__name,
.sim-status__items__item__description {
	text-overflow: ellipsis;
	padding: 0 1.5em !important;
	text-align: left;
	white-space: normal;
	overflow: hidden;
	line-height: 1.2em;
	align-items: center;
	display: flex;
	align-content: center;
}

.sim-status__items__item__description {
	font-size: .9em;
	margin-top: .5em;
	padding: 0 1.7em !important;
	opacity: .8;
	line-height: 1.3em;
}

.sim-status__items__item__slider {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	padding-right: 1em;
}

.sim-status__items__item__slider__enabled,
.sim-status__items__item__slider__disabled,
.sim-status__items__item__slider__unavailable {
	position: absolute;
	top: 0;
	right: 6em;
	bottom: 0;
	width: 100%;
	font-size: 1.1em;
	text-align: right;
	color: var(--text-generic);
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.sim-status__items__item__slider__enabled {
	display: none;
}

.sim-status .enabled .sim-status__items__item__slider__enabled {
	display: flex;
}

.sim-status .enabled .sim-status__items__item__slider__disabled {
	display: none;
}

.sim-status__items__item__slider__graphics {
	position: absolute;
	top: 50%;
	right: 2em;
	width: 3.5em;
	height: 2.1em;
	transform: translateY(-50%);
	border-radius: 1.1em;
	border: .2em solid var(--background-reject);
}

.sim-status__items__item__slider__graphics.unavailable {
	border: .2em solid var(--border-neutral);
}

.sim-status__items__item__slider__graphics:after {
	display: block;
	content: '';
	position: absolute;
	top: 50%;
	left: .25em;
	width: 1.4em;
	height: 1.4em;
	margin-top: -.73em;
	border-radius: 1.5em;
	background: var(--background-reject);
	transition: .3s left;
}

.sim-status__items__item__slider__graphics.unavailable:after {
	background: transparent;
	border: 0.15em solid var(--border-neutral);
	top: 0.9em;
	left: 0.2em;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.sim-status__items__item__slider__graphics:after {
		top: 52%;
		left: .2em;
	}
}

.sim-status .enabled .sim-status__items__item__slider__graphics {
	border-color: var(--background-resolve);
}

.sim-status .enabled .sim-status__items__item__slider__graphics:after {
	left: 1.6em;
	background: var(--background-resolve)
}

.sim-status__items__item:not(.no-hover-effect):not(.disabled),
.sim-status__items__item:not(.disabled) .sim-status__item__fake-select span {
	cursor: pointer;
}

.sim-status__items__item.disabled {
	cursor: not-allowed;
}

.sim-status__items__item:not(.no-hover-effect):not(.disabled):hover:before {
	position: absolute;
	top: -.2em;
	left: 0;
	right: 0;
	bottom: -.2em;
	border: .325em solid var(--highlight);
	content: '';
	display: block;
	border-radius: .15em;
	z-index: 150;
}

.sim-status__items > div:first-of-type .sim-status__items__item:hover:before {
	top: 0;
}

.sim-status__actions {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 4.3em;
	background: var(--background-body);
}

.sim-status__actions button {
	width: 50%;
	float: left;
	height: 3.45em;
	text-transform: uppercase;
	color: var(--text-generic);
	background: var(--background-resolve);
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	font-size: 1.25em;
	line-height: 3.4em;
}

.sim-status__actions button.button-no {
	background: var(--background-reject);
}

.sim-status__actions button:not(:disabled):hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.sim-status__actions button:disabled {
	opacity: .6;
}

.sim-status .viewport-small {
	display: none;
}

.sim-status__cooldown,
.sim-status__saving {
	font-size: 1.4em;
	display: block;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: var(--text-generic);
	text-align: center;
}

.sim-status__saving:before {
	content: '';
	width: 7em;
	height: 7em;
	background: url(../images/cogwheel.svg);
	display: block;
	position: relative;
	animation: spin 4s linear infinite;
	left: 50%;
	margin: 7em 0 2em -3.5em
}

.sim-status__cooldown span {
	display: block;
	padding: 0 2em;
}

.sim-status__placeholder {
	font-size: 1.6em;
	display: block;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	color: var(--text-generic);
	text-align: center;
}

.sim-status__items__item.disabled > div {
	opacity: .4;
}

@keyframes spin {
	100% { transform: rotate(360deg); }
}

@media (max-width: 991px) {
	.sim-status .viewport-small {
		display: block;
	}

	.sim-status .viewport-large {
		display: none;
	}

	.sim-status__header.restricted span {
		display: none;
	}
}

@media (max-width: 767px) {
	.sim-status__items__item__slider__enabled,
	.sim-status__items__item__slider__disabled,
	.sim-status__items__item__slider__unavailable {
		display: none !important;
	}

	.sim-status__item__fake-select span {
		padding: 0 2.5em 0 1em;
		line-height: 130%;
	}

	.sim-status__item__fake-select span:after {
		right: 1.2em;
	}

	.sim-status__items__item__name {
		padding: 0 .9em !important;
	}

	.sim-status__items__item__description {
		padding: 0 1em !important;
	}
}
