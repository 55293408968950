.main {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.main.busy * {
	cursor: progress;
}

.client-rect {
	position: absolute;
	top: -1000em;
	left: -1000em;
	z-index: 1000000;
}

.loading-bar {
	top: 0;
	left: 0;
	width: 100%;
	height: .3em;
	background: var(--transparent-mid-50);
	z-index: 100000;
	position: relative;
	overflow: hidden;
	opacity: 0;
	transition: .4s opacity;
}

.loading-bar.visible {
	opacity: 1;
}

.loading-bar:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--highlight);
}

.loading-bar.visible:before {
	left: -10em;
	width: 10em;
	animation: loading 1s linear infinite;
}

@media (max-width: 991px) {
	.out-of-home__small-screen {
		display: inline;
	}

	span.out-of-home__small-screen {
		margin-left: -1em;
	}

	.out-of-home__large-screen {
		display: none;
	}

	.out-of-home-warning button:hover {
		background-color: transparent;
	}
}

body::after {
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
	z-index: -1;
	content: url('../images/customlist-download-hover.svg') url('../images/customlist-upload-hover.svg') url('../images/checkbox-filled.svg');
}

@keyframes loading {
	from {left: -10em; width: 30%;}
	50% {width: 30%;}
	70% {width: 70%;}
	80% {left: 50%;}
	95% {left: 120%;}
	to {left: 100%;}
}