:root {
	--background-logo: #fff;
	--background-tooltip: #f6f6f6;
	--text-generic: #eee;
	--border-list-item: #e8e8e8;
	--border-filter-list: #d8d8d8;
	--border-light: #ccc;
	--text-label: #aaa;
	--shadow-popup: #a8a8a8;
	--shadow-search-flag-vod: #999;
	--text-disabled-no-transparency: #7f8272;
	--border-neutral: #666;
	--border-nav-item: #4f4f4f;
	--background-category-hover: #494949;
	--recording-failed: #484848;
	--background-body: #393939;
	--text-neutral: #333;
	--background-category-list: #303030;
	--background-completion: #2d2d2d;
	--background-video: #222;
	--text-dark: #000;
	--mask-transparency: #000;

	--text-button-disabled-reject-no-transpareny: #877281;
	--background-input-invalid: #ffb7b7;
	--background-reject: #e74646;
	--border-input-invalid: #b93838;
	--background-tooltip-recording: #B32D3A;
	--shadow-header-status-reject: #993939;
	--background-button-disabled-reject-no-transpareny: #853535;
	--shadow-tooltip: #86222b;
	--border-button-disabled-reject-no-transpareny: #6f2e2e;

	--background-category-selected: #ffb13d;
	--highlight: #fd9701;
	--highligh-border: #c37808;
	--highligh-shadow: #bf7202;

	--background-home-icon: #1ba245;
	--background-free-package: #2fa854;
	--background-resolve: #24a34b;
	--autozap: #2a8201;
	--background-today: #1d823c;
	--shadow-header-status-resolve: #25713c;
	--background-button-resolve-no-transparency: #246337;

	--text-intro: #88a7e1;
	--background-title-light: #44b3e5;
	--border-quicksearch-item: #5598b5;
	--background-ott: #337e9b;
	--background-various-light: #2a7ea3;
	--background-button-generic: #195c7a;
	--text-button-disabled-no-transparency: #6a737a;
	--background-button-generic-focused: #144a62;
	--background-button-disabled-no-transparency: #275163;
	--border-button-disabled-no-transparency: #234553;
	--background-text-region-dark: #252525;

	--transparent-light-05: rgba(255,255,255,.05);
	--transparent-light-30: rgba(255,255,255,.3);
	--transparent-light-40: rgba(255,255,255,.4);
	--transparent-light-60: rgba(255,255,255,.6);
	--transparent-light-70: rgba(255,255,255,.7);

	--transparent-mid-05: rgba(160,160,160,.05);
	--transparent-mid-50: rgba(57,57,57,.5);
	--transparent-mid-80: rgba(37,37,37,.8);
	--transparent-mid-90: rgba(34,34,34,.9);

	--transparent-dark-05: rgba(0,0,0,.05);
	--transparent-dark-10: rgba(0,0,0,.1);
	--transparent-dark-20: rgba(0,0,0,.2);
	--transparent-dark-25: rgba(0,0,0,.25);
	--transparent-dark-30: rgba(0,0,0,.3);
	--transparent-dark-40: rgba(0,0,0,.4);
	--transparent-dark-50: rgba(0,0,0,.5);
	--transparent-dark-60: rgba(0,0,0,.6);
	--transparent-dark-70: rgba(0,0,0,.7);

	--transparent-customlist-reject: rgba(231,70,70,.6);
	--transparent-intro-shine: rgba(49,126,163,.5);

}