.device {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
}

.device__items {
	position: absolute;
	top: 3.84em;
	left: 0;
	right: 0;
	bottom: 4.2em;
	overflow: hidden;
}

.device__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
	margin: 0;
	color: var(--background-text-region-dark);
}

.device__items__item {
	height: 3.7em;
	color: var(--text-generic);
	position: relative;
	border-bottom: .15em solid var(--border-neutral);
	font-size: 1.2em;
	text-align: center;
	line-height: 3.7em;
}

.device__items__item__name {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding: 0 1em !important;
}

.device__items__item.selected {
	background-image: repeating-linear-gradient(-45deg, transparent, transparent .5em, var(--transparent-light-05) .5em, var(--transparent-light-05) 1em);
}

.device__items__item__details {
	list-style: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 9em;
	padding: 0;
	font-size: 1.1em;
}

.device__items__item__buttons {
	height: 100%;
}

.device__items__item__buttons button,
.device__documents {
	position: absolute;
	border: none;
	border-bottom: .25em solid var(--transparent-dark-20);
	font-size: 1.1em;
	line-height: 2.9em;
	white-space: nowrap;
}

.device__items__item__buttons button {
	top: 0;
	bottom: 0;
	text-transform: uppercase;
	background: var(--background-various-light);
	color: var(--text-generic);
	padding: 0 1em;
	margin-bottom: -.12em;
	font-size: 1.2em;
	height: 3.08em;
}

.device__items__item__buttons button.no {
	background: var(--background-reject);
}

.device__items__item__buttons button:only-of-type {
	right: 0;
	left: 0;
	width: 100%;
}

.device__items__item__buttons button:not(:only-of-type):first-of-type {
	right: 50%;
	left: 0;
	width: 50%;
}

.device__items__item__buttons button:not(:only-of-type):last-of-type {
	right: 100%;
	left: 50%;
	width: 50%;
}

.device__documents {
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--background-button-generic);
	color: var(--text-generic) !important;
	text-decoration: none !important;
	text-align: center;
	font-size: 1.2em;
	line-height: 3.3em;
	overflow-x: hidden;
}

.device__items__item button:not(:disabled):hover,
.device__documents:hover {
	background: var(--highlight);
	color: var(--background-text-region-dark) !important;
}

.device__items__item button:first-of-type:disabled {
	background: var(--background-button-disabled-no-transparency);
	border-bottom-color: var(--border-button-disabled-no-transparency);
	color: var(--text-button-disabled-no-transparency);
}

.device__items__item button:last-of-type:not(:only-of-type):disabled {
	border-bottom-color: var(--border-button-disabled-reject-no-transpareny);
	background: var(--background-button-disabled-reject-no-transpareny);
	color: var(--text-button-disabled-reject-no-transpareny);
}

@media (max-width: 1279px) {
	.device__items__item__buttons button:not(:only-of-type) span,
	.device__documents span {
		display: none;
	}

	.device__items__item__buttons button:not(:only-of-type),
	.device__items__item__buttons button:not(:only-of-type):disabled,
	.device__items__item__buttons button:not(:only-of-type):hover {
		background-position: center !important;
		background-repeat: no-repeat !important;
	}

	.device__items__item__buttons button:not(:only-of-type):last-of-type,
	.device__items__item__buttons button:not(:only-of-type):last-of-type:disabled {
		background-image: url(../images/restore.svg);
		background-size: 72% 72%;
	}

	.device__items__item__buttons button:not(:only-of-type):first-of-type {
		background-image: url(../images/checkbox-blank.svg);
		background-size: 60% 60%;
	}

	.device__items__item__buttons button:not(:only-of-type):first-of-type:disabled {
		background-image: url(../images/checkbox-filled.svg);
		background-size: 60% 60%;
	}
}