.custom-list__header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3.24em;
	background: var(--background-title-light);
	font-size: 1.2em;
	line-height: 3.24em;
	text-align: center;
}

.custom-list__actions {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 4.3em;
	background: var(--background-body);
	z-index: 50;
}

.custom-list__actions button {
	width: 25%;
	float: left;
	height: 3.6em;
	text-transform: uppercase;
	color: var(--text-generic);
	background: var(--background-resolve);
	border: none;
	border-bottom: .3em solid var(--transparent-dark-20);
	font-size: 1.2em;
	line-height: 3.5em;
}

.custom-list__actions button.button-no {
	background: var(--background-reject);
}

.custom-list__actions button.button-generic {
	background: var(--background-button-generic);
}

.custom-list__actions button:not(:disabled):hover {
	background: var(--highlight);
	color: var(--background-text-region-dark);
}

.custom-list__actions button:disabled {
	opacity: .6;
}

.custom-list__contents {
	position: absolute;
	top: 3.84em;
	bottom: 4.3em;
	left: 0;
	right: 0;
	overflow: hidden;
}

.custom-list__contents__channels,
.custom-list__contents__list {
	position: absolute;
	top: 0;
	bottom: 0;
	background: var(--background-text-region-dark);
}

.custom-list__contents__channels__inner,
.custom-list__contents__list__inner {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.supress-hover .custom-list__contents__channels:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--transparent-customlist-reject);
	display: block;
	border: .3em dashed var(--transparent-dark-50);
	z-index: 50;
}

.supress-hover .custom-list__contents__channels:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	width: 7em;
	height: 7em;
	background: url('../images/garbage.svg') no-repeat;
	background-size: contain;
	opacity: .8;
	z-index: 50;
}

.custom-list__contents__list {
	border-left: .2em solid var(--border-neutral);
}

.custom-list__contents__channels__inner > div,
.custom-list__contents__list__inner > div,
.custom-list__drag {
	height: 4em;
	color: var(--text-generic);
	line-height: 3.9em;
	border-bottom: .15em solid var(--border-neutral);
	padding-left: 2em;
	font-size: 1.2em;
	position: relative;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	text-overflow: ellipsis;
	padding-right: 5em;
	white-space: nowrap;
	overflow: hidden;
}

.supress-hover .custom-list__contents__list__inner > div:hover {
	background: var(--background-resolve);
	color: var(--background-text-region-dark);
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.custom-list__contents__list__inner > div.webkit-mouseover {
		background: var(--background-resolve);
		color: var(--background-text-region-dark);
	}
}

.custom-list__contents__channels__inner > div:active,
.custom-list__contents__list__inner > div:active,
.custom-list__drag:active {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}

.custom-list__drag {
	top: -1000em;
	left: -1000em;
	background: var(--highlight);
	border: none;
	color: var(--background-text-region-dark);
	width: 50%;
	pointer-events: none;
	-webkit-pointer-events: none;
	transform :translateX(-50%) translateY(-50%);
	opacity: .8;
	z-index: 50;
}

.custom-list__contents:not(.supress-hover) .custom-list__contents__channels__inner > div:hover,
.custom-list__contents__list__inner > div:hover {
	color: var(--background-text-region-dark);
	background: var(--highlight);
}

.custom-list__contents__channels {
	left: 0;
	right: 50%;
}

.custom-list__contents__list {
	left: 50%;
	right: 0;
}

.custom-list__contents__channels .used {
	background-image: repeating-linear-gradient(-45deg, transparent, transparent .5em, var(--transparent-light-05) .5em, var(--transparent-light-05) 1em);
}

.custom-list .viewport-small {
	display: none;
}

.custom-list .tablet-override-visibility {
	display: block;
}

.custom-list .tablet-override-visibility button {
	width: 50% !important;
}

.custom-list__header__actions {
	position: absolute;
	top: 0;
	right: .8em;
	width: 7em;
	height: 3.2em;
}

.custom-list__header__actions button {
	height: 3.2em;
	width: 2.6em;
	border: none;
	float: right;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 1.7em;
	cursor: pointer;
}

.custom-list__header__actions input[type="file"] {
	height: 3.2em;
	width: 3.2em;
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0;
	cursor: pointer;
}

.custom-list ::-webkit-file-upload-button {
	cursor: pointer;
}

.custom-list__header__actions__export {
	background-image: url('../images/customlist-download.svg');
}

.custom-list__header__actions__import {
	background-image: url('../images/customlist-upload.svg');
}

.custom-list__header__actions__export:hover {
	background-image: url('../images/customlist-download-hover.svg');
}

.custom-list__header__actions input[type="file"]:hover + button {
	background-image: url('../images/customlist-upload-hover.svg');
}

@media (max-width: 991px) {
	.custom-list .viewport-small {
		display: block;
	}

	.custom-list .viewport-large {
		display: none;
	}

	.custom-list .viewport-small .custom-list__contents__list {
		left: 0;
		border: none;
	}

	.custom-list .viewport-small .custom-list__contents__list.offset {
		left: 75%;
	}

	.custom-list .viewport-small .custom-list__contents__list.offset .ss-scroll {
		margin-left: -75%;
	}

	.custom-list .viewport-small .custom-list__contents__channels {
		right: 75%;
		width: 75%;
		overflow: hidden;
		border-right: .15em solid var(--border-neutral);
	}

	.custom-list__right-overlay {
		content: '';
		right: 0;
		left: 75%;
		background: transparent !important;
		display: none;
	}

	.custom-list__right-overlay.visible {
		display: block;
	}

	.custom-list__contents__list__inner > div.chosen {
		background: var(--background-resolve);
		color: var(--background-text-region-dark);
	}

	.custom-list__contents__channels__inner > div,
	.custom-list__contents__list__inner > div,
	.custom-list__drag {
		cursor: pointer;
	}

	.custom-list__actions.viewport-small button {
		width: 50%;
	}

	.custom-list .tablet-override-visibility {
		display: none;
	}
}